/*!d
 * Theme Name: Genox -  Multipurpose HTML Template
 * Author: Softnio
 * Author URI: http://themeforest.net/user/softnio
 * Version: 1.4.1
 * Updated: 08.09.2019
**/
/*! WRITE YOUR OWN STYLE HERE **/

:root {
    --main-bg-color: #E54B4B;
}

.slick-next:before
{
    content: '' !important;
}
[dir='rtl'] .slick-next:before
{
    content: '' !important;
}


.slick-prev:before
{
    content: '' !important;
}
[dir='rtl'] .slick-prev:before
{
    content: '' !important;
}

/*.banner-heading {*/
/*    background: linear-gradient(45deg, rgba(74,28,96,1) 0%, rgba(205,24,105,1) 100%);*/
/*    -webkit-background-clip: text;*/
/*    -webkit-text-fill-color: transparent;*/
/*}*/

.preloader.preloader-dalas:before, .preloader.preloader-dalas:after {
    background: linear-gradient(to right, rgba(74,28,96,1) 0%, rgba(205,24,105,1) 100%);
}

/*.banner-btn-white {*/
/*    background: var(--main-bg-color) !important;*/
/*    !*border: 2px solid white;*!*/
/*}*/

/*.btn-arrow {*/
/*    color: var(--main-bg-color) !important;*/
/*}*/


/*.icon {*/
/*    color: var(--main-bg-color) !important;*/
/*}*/

/*.icon{*/
/*    color: var(--main-bg-color) !important;*/
/*}*/

/*.btn {*/
/*    background: #6B1B6C;*/
/*}*/

/*.btn:hover {*/
/*    background: #8A2196;*/
/*}*/

/*.menu-item > a.active {*/
/*    color: #E80A68 !important;*/
/*}*/


@media (min-width: 992px) {
    .banner-heading {
        font-size: 5.75rem;
        line-height: 1.2;
    }
}

/* LANDING PAGE WHILE IN PROGRESS - REMOVE THIS AFTER LAUNCH*/
.inprogress-wrapper {
}
