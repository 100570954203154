/*!
 * Theme Name: Genox -  Multipurpose HTML Template
 * Author: Softnio
 * Author URI: http://themeforest.net/user/softnio
 * Version: 1.4.1
 * Updated: 11.27.2019
**/
/**
----------------------
## TABLE CONTNETS ##
01. Fonts
02. Utilities
--  02.1 Reset
--  02.2 Spacing
--  02.3 Misc
--  02.4 Preset
--  02.5 Animate
03. layouts
--  03.1 Header
--  03.2 Menu
--  03.3 Sections
--  03.4 Footer
04. Override
05. Block
--  05.1 Banner
--  05.2 Feature
--  05.3 Services
--  05.4 Counter
--  05.5 Project
--  05.6 Testimonial
--  05.7 Team
--  05.8 Cta
--  05.9 Post
--  05.10 Career
--  05.11 Contact
--  05.12 form
--  05.13 price
---------------------- **/
/** IMPORTANT NOTE: DO NOT Edit this file. Best to write own code in css/theme.css file. It will helps you to easy update of the template. */
/** 01. Fonts */
@font-face { font-family: 'Poppins'; src: url("../fonts/Poppins-Black.eot"); src: url("../fonts/Poppins-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Black.woff2") format("woff2"), url("../fonts/Poppins-Black.woff") format("woff"), url("../fonts/Poppins-Black.ttf") format("truetype"); font-weight: 900; font-style: normal; }
@font-face { font-family: 'Poppins'; src: url("../fonts/Poppins-Bold.eot"); src: url("../fonts/Poppins-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Bold.woff2") format("woff2"), url("../fonts/Poppins-Bold.woff") format("woff"), url("../fonts/Poppins-Bold.ttf") format("truetype"); font-weight: 700; font-style: normal; }
@font-face { font-family: 'Poppins'; src: url("../fonts/Poppins-SemiBold.eot"); src: url("../fonts/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-SemiBold.woff2") format("woff2"), url("../fonts/Poppins-SemiBold.woff") format("woff"), url("../fonts/Poppins-SemiBold.ttf") format("truetype"); font-weight: 600; font-style: normal; }
@font-face { font-family: 'Poppins'; src: url("../fonts/Poppins-Medium.eot"); src: url("../fonts/Poppins-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Medium.woff2") format("woff2"), url("../fonts/Poppins-Medium.woff") format("woff"), url("../fonts/Poppins-Medium.ttf") format("truetype"); font-weight: 500; font-style: normal; }
@font-face { font-family: 'Poppins'; src: url("../fonts/Poppins-Regular.eot"); src: url("../fonts/Poppins-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Regular.woff2") format("woff2"), url("../fonts/Poppins-Regular.woff") format("woff"), url("../fonts/Poppins-Regular.ttf") format("truetype"); font-weight: 400; font-style: normal; }
@font-face { font-family: 'Poppins'; src: url("../fonts/Poppins-Light.eot"); src: url("../fonts/Poppins-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Light.woff2") format("woff2"), url("../fonts/Poppins-Light.woff") format("woff"), url("../fonts/Poppins-Light.ttf") format("truetype"); font-weight: 300; font-style: normal; }
@font-face { font-family: 'Roboto'; src: url("../fonts/Roboto-Light.eot"); src: local("Roboto Light"), local("Roboto-Light"), url("../fonts/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Light.woff2") format("woff2"), url("../fonts/Roboto-Light.woff") format("woff"), url("../fonts/Roboto-Light.ttf") format("truetype"); font-weight: 300; font-style: normal; }
@font-face { font-family: 'Roboto'; src: url("../fonts/Roboto-Regular.eot"); src: local("Roboto"), local("Roboto-Regular"), url("../fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Regular.woff2") format("woff2"), url("../fonts/Roboto-Regular.woff") format("woff"), url("../fonts/Roboto-Regular.ttf") format("truetype"); font-weight: normal; font-style: normal; }
@font-face { font-family: 'Roboto'; src: url("../fonts/Roboto-Medium.eot"); src: local("Roboto Medium"), local("Roboto-Medium"), url("../fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Medium.woff2") format("woff2"), url("../fonts/Roboto-Medium.woff") format("woff"), url("../fonts/Roboto-Medium.ttf") format("truetype"); font-weight: 500; font-style: normal; }
@font-face { font-family: 'Roboto'; src: url("../fonts/Roboto-Bold.eot"); src: local("Roboto Bold"), local("Roboto-Bold"), url("../fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Bold.woff2") format("woff2"), url("../fonts/Roboto-Bold.woff") format("woff"), url("../fonts/Roboto-Bold.ttf") format("truetype"); font-weight: bold; font-style: normal; }
@font-face { font-family: 'genox-icon'; src: url("../fonts/genox-icon.ttf?4rdqtr") format("truetype"), url("../fonts/genox-icon.woff?4rdqtr") format("woff"), url("../fonts/genox-icon.svg?4rdqtr#genox-icon") format("svg"); font-weight: normal; font-style: normal; }
.ikon { /* use !important to prevent issues with browser extensions that change fonts */ font-family: 'genox-icon' !important; speak: none; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; /* Better Font Rendering =========== */ -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.ikon-brain:before { content: "\e90c"; }

.ikon-chart:before { content: "\e90d"; }

.ikon-light:before { content: "\e90e"; }

.ikon-plane:before { content: "\e90f"; }

.ikon-quote-o:before { content: "\e90b"; }

.ikon-app:before { content: "\e907"; }

.ikon-startup:before { content: "\e908"; }

.ikon-strategy:before { content: "\e909"; }

.ikon-uiux:before { content: "\e90a"; }

.ikon-quote:before { content: "\e906"; }

.ikon-skill:before { content: "\e900"; }

.ikon-drive:before { content: "\e901"; }

.ikon-pie:before { content: "\e902"; }

.ikon-apply:before { content: "\e903"; }

.ikon-interview:before { content: "\e904"; }

.ikon-promise:before { content: "\e905"; }

/** 02. Utilities */
/** 02.01 Reset */
html, body { font-family: "Roboto", sans-serif; color: #131313; font-size: 16px; line-height: 1.6; font-weight: 400; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; position: relative; overflow-x: hidden; min-width: 320px; }

@media (min-width: 576px) { html, body { font-size: 16px; line-height: 1.6; font-weight: 400; } }
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section { display: block; }

*[hidden] { display: none; }

ol, ul { list-style: none; }

blockquote, q { quotes: none; }
blockquote::after, blockquote::before, q::after, q::before { content: none; }

table { border-collapse: collapse; border-spacing: 0; }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 { color: #131313; font-weight: 700; font-family: "Poppins", sans-serif; line-height: 1.3; margin-bottom: 0.5rem; }
h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child, .h1:last-child, .h2:last-child, .h3:last-child, .h4:last-child, .h5:last-child, .h6:last-child { margin-bottom: 0; }
.tc-light h1, .tc-light h2, .tc-light h3, .tc-light h4, .tc-light h5, .tc-light h6, .tc-light .h1, .tc-light .h2, .tc-light .h3, .tc-light .h4, .tc-light .h5, .tc-light .h6 { color: #fff; }
.tc-bunker h1, .tc-bunker h2, .tc-bunker h3, .tc-bunker h4, .tc-bunker h5, .tc-bunker h6, .tc-bunker .h1, .tc-bunker .h2, .tc-bunker .h3, .tc-bunker .h4, .tc-bunker .h5, .tc-bunker .h6 { color: #ffffff !important; }
.tc-bunker h1:not(.tc-primary), .tc-bunker h2:not(.tc-primary), .tc-bunker h3:not(.tc-primary), .tc-bunker h4:not(.tc-primary), .tc-bunker h5:not(.tc-primary), .tc-bunker h6:not(.tc-primary), .tc-bunker .h1:not(.tc-primary), .tc-bunker .h2:not(.tc-primary), .tc-bunker .h3:not(.tc-primary), .tc-bunker .h4:not(.tc-primary), .tc-bunker .h5:not(.tc-primary), .tc-bunker .h6:not(.tc-primary) { color: #ffffff !important; }

p { margin-bottom: 1rem; }
p:last-child { margin-bottom: 0; }

ul, ol, table { margin: 0; padding: 0; }

strong, b { font-weight: 700; }

a, button { outline: 0 none; transition: all 0.5s; color: #ff4157; }
a:link, a:visited, button:link, button:visited { text-decoration: none; }
a:hover, a:focus, a:active, button:hover, button:focus, button:active { outline: 0; color: #ff4157; }
.bg-dark-alt a:hover, .bg-dark a:hover, .bg-dark-alt button:hover, .bg-dark button:hover { color: #ffffff; }

img { max-width: 100%; height: auto; }

h1, .h1, .fz-1 { font-size: 1.375rem; }

h2, .h2, .fz-2 { font-size: 1.375rem; }

h3, .h3, .fz-3 { font-size: 1.125rem; }

h4, .h4, .fz-4 { font-size: 1.125rem; line-height: 1.667; }

h5, .h5, .fz-5 { font-size: 1rem; }

h6, .h6, .fz-6 { font-size: 0.875rem; }

.fz-7 { font-size: 0.75rem; }

.fz-8 { font-size: 0.75rem; }

@media (min-width: 479px) { h1, .h1, .fz-1 { font-size: 1.75rem; }
  h2, .h2, .fz-2 { font-size: 1.375rem; }
  h3, .h3, .fz-3 { font-size: 1.25rem; }
  h4, .h4, .fz-4 { font-size: 1.125rem; line-height: 1.667; }
  h5, .h5, .fz-5 { font-size: 1rem; }
  h6, .h6, .fz-6 { font-size: 0.875rem; }
  .fz-7 { font-size: 0.75rem; }
  .fz-8 { font-size: 0.75rem; } }
.lead { font-weight: 400; }

.l-font { font-size: 4.5rem; }

@media (min-width: 576px) { h2, .h2, .fz-2 { font-size: 1.875rem; line-height: 1.533; }
  h3, .h3, .fz-3 { font-size: 1.5rem; }
  h4, .h4, .fz-4 { font-size: 1.125rem; line-height: 1.333; }
  h5, .h5, .fz-5 { font-size: 0.875rem; }
  h6, .h6, .fz-6 { font-size: 0.75rem; }
  .fz-7 { font-size: 0.6875rem; }
  .fz-8 { font-size: 0.625rem; }
  .l-font { font-size: 4.5rem; } }
@media (min-width: 992px) { h1, .h1, .fz-1 { font-size: 2.25rem; } }
.t-u { text-transform: uppercase; }
.t-c { text-transform: capitalize; }
.t-n { text-transform: initial; }

.nk-df-content { content: ""; }
.nk-df-rel, .has-ovm, .has-bg, [class*='bg-'], .has-bg-image, .has-bg-video { position: relative; }
.nk-df-over-up, .has-ovm > [class*=container], .has-ovm > :not(.nk-ovm):not(.header-main):not(.nk-header), .has-bg-image > [class*=container], .has-bg-video > [class*=container] { position: relative; z-index: 5; }
.nk-df-t, .nk-df-y { top: 0; }
.nk-df-b, .nk-df-y { bottom: 0; }
.nk-df-l, .nk-df-x { left: 0; }
.nk-df-r, .nk-df-x { right: 0; }
.nk-df-around, .bg-image , .bg-video, .bg-video-cover, .overlay:after { position: absolute; bottom: 0; top: 0; left: 0; right: 0; }
.nk-df-center { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
.nk-df-bg, .has-bg, [class*='bg-'], .bg-image, .bg-video, .bg-video-cover { background-repeat: no-repeat; background-size: cover; background-position: 50% 50%; }
.nk-df-bg-100 { background-repeat: no-repeat; background-size: 100%; background-position: 50% 50%; }

/** 02.02 Spacing */
.gap { height: 30px; }

.gap-s { height: 10px; }

.gap-m { height: 20px; }

.gap-l { height: 60px; }

.gap-x { height: 90px; }

.gap-1x { height: 10px; }

.gap-2x { height: 20px; }

.gap-3x { height: 30px; }

.gap-4x { height: 40px; }

.gap-5x { height: 50px; }

.gap-6x { height: 60px; }

.gap-7x { height: 70px; }

.gap-8x { height: 80px; }

.gap-9x { height: 90px; }

.gap-10x { height: 100px; }

.pdt, .pd, .pdy { padding-top: 30px; }

.pdb, .pd, .pdy { padding-bottom: 30px; }

.pdl, .pd, .pdx { padding-left: 30px; }

.pdr, .pd, .pdx { padding-right: 30px; }

.pdt-s, .pd-s, .pdy-s { padding-top: 10px !important; }

.pdt-m, .pd-m, .pdy-m { padding-top: 20px !important; }

.pdt-r, .pd-r, .pdy-r { padding-top: 30px !important; }

.pdt-l, .pd-l, .pdy-l { padding-top: 60px !important; }

.pdt-x, .pd-x, .pdy-x { padding-top: 90px !important; }

.pdb-s, .pd-s, .pdy-s { padding-bottom: 10px !important; }

.pdb-m, .pd-m, .pdy-m { padding-bottom: 20px !important; }

.pdb-r, .pd-r, .pdy-r { padding-bottom: 30px !important; }

.pdb-l, .pd-l, .pdy-l { padding-bottom: 60px !important; }

.pdb-x, .pd-x, .pdy-x { padding-bottom: 90px !important; }

.pdl-s, .pd-s, .pdx-s { padding-left: 10px !important; }

.pdl-m, .pd-m, .pdx-m { padding-left: 20px !important; }

.pdl-r, .pd-r, .pdx-r { padding-left: 30px !important; }

.pdl-l, .pd-l, .pdx-l { padding-left: 60px !important; }

.pdl-x, .pd-x, .pdx-x { padding-left: 90px !important; }

.pdr-s, .pd-s, .pdx-s { padding-right: 10px !important; }

.pdr-m, .pd-m, .pdx-m { padding-right: 20px !important; }

.pdr-r, .pd-r, .pdx-r { padding-right: 30px !important; }

.pdr-l, .pd-l, .pdx-l { padding-right: 60px !important; }

.pdr-x, .pd-x, .pdx-x { padding-right: 90px !important; }

.mgt, .mg, .mgy { margin-top: 30px; }

.mgb, .mg, .mgy { margin-bottom: 30px; }

.mgl, .mg, .mgx { margin-left: 30px; }

.mgr, .mg, .mgx { margin-right: 30px; }

.mg-fix { margin-bottom: -20px; }
.mg-fix-lg { margin-bottom: -45px; }
.mg-fix-md { margin-bottom: -30px; }
.mg-fix-sm { margin-bottom: -15px; }
.mg-fix-xs { margin-bottom: -10px; }

.mgt-s, .mg-s, .mgy-s { margin-top: 10px !important; }

.mgt-m, .mg-m, .mgy-m { margin-top: 20px !important; }

.mgt-r, .mg-r, .mgy-r { margin-top: 30px !important; }

.mgt-l, .mg-l, .mgy-l { margin-top: 60px !important; }

.mgt-x, .mg-x, .mgy-x { margin-top: 90px !important; }

.mgb-s, .mg-s, .mgy-s { margin-bottom: 10px !important; }

.mgb-m, .mg-m, .mgy-m { margin-bottom: 20px !important; }

.mgb-r, .mg-r, .mgy-r { margin-bottom: 30px !important; }

.mgb-l, .mg-l, .mgy-l { margin-bottom: 60px !important; }

.mgb-x, .mg-x, .mgy-x { margin-bottom: 90px !important; }

.mgl-s, .mg-s, .mgx-s { margin-left: 10px !important; }

.mgl-m, .mg-m, .mgx-m { margin-left: 20px !important; }

.mgl-r, .mg-r, .mgx-r { margin-left: 30px !important; }

.mgl-l, .mg-l, .mgx-l { margin-left: 60px !important; }

.mgl-x, .mg-x, .mgx-x { margin-left: 90px !important; }

.mgr-s, .mg-s, .mgx-s { margin-right: 10px !important; }

.mgr-m, .mg-m, .mgx-m { margin-right: 20px !important; }

.mgr-r, .mg-r, .mgx-r { margin-right: 30px !important; }

.mgr-l, .mg-l, .mgx-l { margin-right: 60px !important; }

.mgr-x, .mg-x, .mgx-x { margin-right: 90px !important; }

.mgb-m10 { margin-bottom: -10px; }

.mgb-m20 { margin-bottom: -20px; }

.mgb-m30 { margin-bottom: -30px; }

.mgb-m40 { margin-bottom: -40px; }

.mgb-m50 { margin-bottom: -50px; }

.mgt-m10 { margin-top: -10px; }

.mgt-m20 { margin-top: -20px; }

.mgt-m30 { margin-top: -30px; }

.mgt-m40 { margin-top: -40px; }

.mgt-m50 { margin-top: -50px; }

.gutter-5px { margin-left: -2.5px !important; margin-right: -2.5px !important; }
.gutter-5px > li, .gutter-5px > div { padding-left: 2.5px !important; padding-right: 2.5px !important; }

.gutter-10px { margin-left: -5px !important; margin-right: -5px !important; }
.gutter-10px > li, .gutter-10px > div { padding-left: 5px !important; padding-right: 5px !important; }

.gutter-20px { margin-left: -10px !important; margin-right: -10px !important; }
.gutter-20px > li, .gutter-20px > div { padding-left: 10px !important; padding-right: 10px !important; }

.gutter-30px { margin-left: -15px !important; margin-right: -15px !important; }
.gutter-30px > li, .gutter-30px > div { padding-left: 15px !important; padding-right: 15px !important; }

.gutter-40px { margin-left: -20px !important; margin-right: -20px !important; }
.gutter-40px > li, .gutter-40px > div { padding-left: 20px !important; padding-right: 20px !important; }

.gutter-50px { margin-left: -25px !important; margin-right: -25px !important; }
.gutter-50px > li, .gutter-50px > div { padding-left: 25px !important; padding-right: 25px !important; }

.gutter-60px { margin-left: -30px !important; margin-right: -30px !important; }
.gutter-60px > li, .gutter-60px > div { padding-left: 30px !important; padding-right: 30px !important; }

.gutter-70px { margin-left: -35px !important; margin-right: -35px !important; }
.gutter-70px > li, .gutter-70px > div { padding-left: 35px !important; padding-right: 35px !important; }

.gutter-80px { margin-left: -40px !important; margin-right: -40px !important; }
.gutter-80px > li, .gutter-80px > div { padding-left: 40px !important; padding-right: 40px !important; }

.gutter-90px { margin-left: -45px !important; margin-right: -45px !important; }
.gutter-90px > li, .gutter-90px > div { padding-left: 45px !important; padding-right: 45px !important; }

.gutter-100px { margin-left: -50px !important; margin-right: -50px !important; }
.gutter-100px > li, .gutter-100px > div { padding-left: 50px !important; padding-right: 50px !important; }

.gutter-vr-5px { margin-top: -2.5px !important; margin-bottom: -2.5px !important; }
.gutter-vr-5px > li, .gutter-vr-5px > div { padding-top: 2.5px !important; padding-bottom: 2.5px !important; }

.gutter-vr-10px { margin-top: -5px !important; margin-bottom: -5px !important; }
.gutter-vr-10px > li, .gutter-vr-10px > div { padding-top: 5px !important; padding-bottom: 5px !important; }

.gutter-vr-20px { margin-top: -10px !important; margin-bottom: -10px !important; }
.gutter-vr-20px > li, .gutter-vr-20px > div { padding-top: 10px !important; padding-bottom: 10px !important; }

.gutter-vr-30px { margin-top: -15px !important; margin-bottom: -15px !important; }
.gutter-vr-30px > li, .gutter-vr-30px > div { padding-top: 15px !important; padding-bottom: 15px !important; }

.gutter-vr-40px { margin-top: -20px !important; margin-bottom: -20px !important; }
.gutter-vr-40px > li, .gutter-vr-40px > div { padding-top: 20px !important; padding-bottom: 20px !important; }

.gutter-vr-50px { margin-top: -25px !important; margin-bottom: -25px !important; }
.gutter-vr-50px > li, .gutter-vr-50px > div { padding-top: 25px !important; padding-bottom: 25px !important; }

.gutter-vr-60px { margin-top: -30px !important; margin-bottom: -30px !important; }
.gutter-vr-60px > li, .gutter-vr-60px > div { padding-top: 30px !important; padding-bottom: 30px !important; }

.gutter-vr-70px { margin-top: -35px !important; margin-bottom: -35px !important; }
.gutter-vr-70px > li, .gutter-vr-70px > div { padding-top: 35px !important; padding-bottom: 35px !important; }

.gutter-vr-80px { margin-top: -40px !important; margin-bottom: -40px !important; }
.gutter-vr-80px > li, .gutter-vr-80px > div { padding-top: 40px !important; padding-bottom: 40px !important; }

.gutter-vr-90px { margin-top: -45px !important; margin-bottom: -45px !important; }
.gutter-vr-90px > li, .gutter-vr-90px > div { padding-top: 45px !important; padding-bottom: 45px !important; }

.gutter-vr-100px { margin-top: -50px !important; margin-bottom: -50px !important; }
.gutter-vr-100px > li, .gutter-vr-100px > div { padding-top: 50px !important; padding-bottom: 50px !important; }

.block-pad { padding: 50px 30px; }
.block-pad-sm { padding: 30px; }
.block-pad-md { padding: 35px; }
.block-pad-xl { padding: 50px 30px; }
.block-pad-80 { padding: 40px; }
.block-pad-b-100 { padding-bottom: 50px; }

@media (min-width: 768px) { .block-pad { padding: 60px 35px; }
  .block-pad-xl { padding: 30px; }
  .block-pad-b-100 { padding-bottom: 100px; } }
@media (min-width: 992px) { .block-pad-xl { padding: 50px; } }
@media (min-width: 1280px) { .block-pad-sm { padding: 60px 50px; }
  .block-pad-md { padding: 80px 50px; }
  .block-pad-lg { padding: 100px 50px; }
  .block-pad-xl { padding: 100px 50px; }
  .block-pad-80 { padding: 70px 80px 80px; } }
@media (min-width: 1600px) { .block-pad { padding: 80px 100px; }
  .block-pad-lg { padding: 100px; } }
.res-gutter-30px { margin-top: -15px !important; margin-bottom: -15px !important; }
.res-gutter-30px > div { padding-top: 15px !important; padding-bottom: 15px !important; }

.gutter-vr-10px-res { margin-top: -5px !important; margin-bottom: -5px !important; }
.gutter-vr-10px-res > li, .gutter-vr-10px-res > div { padding-top: 5px !important; padding-bottom: 5px !important; }

.gutter-vr-20px-res { margin-top: -10px !important; margin-bottom: -10px !important; }
.gutter-vr-20px-res > li, .gutter-vr-20px-res > div { padding-top: 10px !important; padding-bottom: 10px !important; }

.gutter-vr-30px-res { margin-top: -15px !important; margin-bottom: -15px !important; }
.gutter-vr-30px-res > li, .gutter-vr-30px-res > div { padding-top: 15px !important; padding-bottom: 15px !important; }

.gutter-vr-40px-res { margin-top: -20px !important; margin-bottom: -20px !important; }
.gutter-vr-40px-res > li, .gutter-vr-40px-res > div { padding-top: 20px !important; padding-bottom: 20px !important; }

.gutter-vr-50px-res { margin-top: -25px !important; margin-bottom: -25px !important; }
.gutter-vr-50px-res > li, .gutter-vr-50px-res > div { padding-top: 25px !important; padding-bottom: 25px !important; }

.gutter-vr-60px-res { margin-top: -30px !important; margin-bottom: -30px !important; }
.gutter-vr-60px-res > li, .gutter-vr-60px-res > div { padding-top: 30px !important; padding-bottom: 30px !important; }

.gutter-vr-70px-res { margin-top: -35px !important; margin-bottom: -35px !important; }
.gutter-vr-70px-res > li, .gutter-vr-70px-res > div { padding-top: 35px !important; padding-bottom: 35px !important; }

@media (min-width: 1200px) { .gutter-vr-10px { margin-top: -5px !important; margin-bottom: -5px !important; }
  .gutter-vr-10px > li, .gutter-vr-10px > div { padding-top: 5px !important; padding-bottom: 5px !important; }
  .gutter-vr-20px { margin-top: -10px !important; margin-bottom: -10px !important; }
  .gutter-vr-20px > li, .gutter-vr-20px > div { padding-top: 10px !important; padding-bottom: 10px !important; }
  .gutter-vr-30px { margin-top: -15px !important; margin-bottom: -15px !important; }
  .gutter-vr-30px > li, .gutter-vr-30px > div { padding-top: 15px !important; padding-bottom: 15px !important; }
  .gutter-vr-40px { margin-top: -20px !important; margin-bottom: -20px !important; }
  .gutter-vr-40px > li, .gutter-vr-40px > div { padding-top: 20px !important; padding-bottom: 20px !important; }
  .gutter-vr-50px { margin-top: -25px !important; margin-bottom: -25px !important; }
  .gutter-vr-50px > li, .gutter-vr-50px > div { padding-top: 25px !important; padding-bottom: 25px !important; }
  .gutter-vr-60px { margin-top: -30px !important; margin-bottom: -30px !important; }
  .gutter-vr-60px > li, .gutter-vr-60px > div { padding-top: 30px !important; padding-bottom: 30px !important; }
  .gutter-vr-70px { margin-top: -35px !important; margin-bottom: -35px !important; }
  .gutter-vr-70px > li, .gutter-vr-70px > div { padding-top: 35px !important; padding-bottom: 35px !important; } }
.l5 { margin-left: 5%; }

.r5 { margin-left: -5%; }

.t5 { margin-top: 5%; }

.b5 { margin-top: -5%; }

.l10 { margin-left: 10%; }

.r10 { margin-left: -10%; }

.t10 { margin-top: 10%; }

.b10 { margin-top: -10%; }

.l15 { margin-left: 15%; }

.r15 { margin-left: -15%; }

.t15 { margin-top: 15%; }

.b15 { margin-top: -15%; }

.l20 { margin-left: 20%; }

.r20 { margin-left: -20%; }

.t20 { margin-top: 20%; }

.b20 { margin-top: -20%; }

.l25 { margin-left: 25%; }

.r25 { margin-left: -25%; }

.t25 { margin-top: 25%; }

.b25 { margin-top: -25%; }

.l30 { margin-left: 30%; }

.r30 { margin-left: -30%; }

.t30 { margin-top: 30%; }

.b30 { margin-top: -30%; }

.l35 { margin-left: 35%; }

.r35 { margin-left: -35%; }

.t35 { margin-top: 35%; }

.b35 { margin-top: -35%; }

.l40 { margin-left: 40%; }

.r40 { margin-left: -40%; }

.t40 { margin-top: 40%; }

.b40 { margin-top: -40%; }

.l45 { margin-left: 45%; }

.r45 { margin-left: -45%; }

.t45 { margin-top: 45%; }

.b45 { margin-top: -45%; }

.l50 { margin-left: 50%; }

.r50 { margin-left: -50%; }

.t50 { margin-top: 50%; }

.b50 { margin-top: -50%; }

.l55 { margin-left: 55%; }

.r55 { margin-left: -55%; }

.t55 { margin-top: 55%; }

.b55 { margin-top: -55%; }

.l60 { margin-left: 60%; }

.r60 { margin-left: -60%; }

.t60 { margin-top: 60%; }

.b60 { margin-top: -60%; }

.l65 { margin-left: 65%; }

.r65 { margin-left: -65%; }

.t65 { margin-top: 65%; }

.b65 { margin-top: -65%; }

.l70 { margin-left: 70%; }

.r70 { margin-left: -70%; }

.t70 { margin-top: 70%; }

.b70 { margin-top: -70%; }

.l75 { margin-left: 75%; }

.r75 { margin-left: -75%; }

.t75 { margin-top: 75%; }

.b75 { margin-top: -75%; }

.l80 { margin-left: 80%; }

.r80 { margin-left: -80%; }

.t80 { margin-top: 80%; }

.b80 { margin-top: -80%; }

.t4 { margin-top: 4%; }

.container-custom { max-width: 100%; margin-left: auto; margin-right: auto; padding: 0 15px; }
.container-custom-full { max-width: calc(100% - 30px); padding: 30px; }

.bg-image.bg-image-full { height: 350px; position: relative !important; margin-top: 55px; }

@media (max-width: 991px) { .bg-image.bg-image-quarter { max-width: calc(100% - 30px); height: 350px; position: relative !important; margin: 0 auto 30px; }
  .sign-up .bg-image.bg-image-quarter { display: none; } }
@media (min-width: 576px) { .container-custom { max-width: 470px; margin-left: auto; margin-right: auto; }
  .container-custom-full { max-width: 440px; padding: 30px; } }
@media (min-width: 576px) and (max-width: 767px) { .bg-image.bg-image-quarter { max-width: 470px; margin-left: auto; margin-right: auto; } }
@media (min-width: 768px) { .container-custom { max-width: 630px; margin-left: 30px; margin-right: auto; }
  .bg-offset-0 { margin-left: 45px; }
  .bg-offset-5 { margin-left: 302.5px; }
  .bg-offset-6 { margin-left: 355px; }
  .bg-offset-7 { margin-left: 407.5px; }
  .bg-offset-8 { margin-left: 460px; }
  .bg-offset-9 { margin-left: 512.5px; }
  .bg-offset-10 { margin-left: 565px; }
  .bg-offset-11 { margin-left: 617.5px; }
  .bg-offset-12 { margin-left: 670px; }
  .container-custom-full { max-width: 100%; margin-left: 45px; padding: 0; } }
@media (min-width: 768px) and (max-width: 849px) { .bg-image.bg-image-quarter { max-width: 600px; margin-left: 45px; } }
@media (min-width: 850px) and (max-width: 991px) { .bg-image.bg-image-quarter { max-width: 600px; margin-left: 75px; } }
@media (min-width: 850px) { .container-custom { max-width: 630px; margin-left: 60px; margin-right: auto; }
  .bg-offset-0 { margin-left: 75px; }
  .bg-offset-5 { margin-left: 332.5px; }
  .bg-offset-6 { margin-left: 385px; }
  .bg-offset-7 { margin-left: 437.5px; }
  .bg-offset-8 { margin-left: 490px; }
  .bg-offset-9 { margin-left: 542.5px; }
  .bg-offset-10 { margin-left: 595px; }
  .bg-offset-11 { margin-left: 647.5px; }
  .bg-offset-12 { margin-left: 700px; }
  .container-custom-full { max-width: 100%; margin-left: 75px; padding: 0; } }
@media (min-width: 992px) { .container-custom { max-width: 805px; margin-left: 45px; }
  .bg-offset-0 { margin-left: 60px; }
  .bg-offset-5 { margin-left: 390.4166666667px; }
  .bg-offset-6 { margin-left: 457.5px; }
  .bg-offset-7 { margin-left: 524.5833333333px; }
  .bg-offset-8 { margin-left: 591.6666666667px; }
  .bg-offset-9 { margin-left: 658.75px; }
  .bg-offset-10 { margin-left: 725.8333333333px; }
  .bg-offset-11 { margin-left: 792.9166666667px; }
  .bg-offset-12 { margin-left: 860px; }
  .bg-image.bg-image-quarter { max-width: none; }
  .bg-image.bg-image-full { height: 550px; }
  .container-custom-full { max-width: 100%; margin-left: 60px; } }
@media (min-width: 1100px) { .container-custom { max-width: 850px; margin-left: 75px; }
  .bg-offset-0 { margin-left: 90px; }
  .bg-offset-5 { margin-left: 439.1666666667px; }
  .bg-offset-6 { margin-left: 510px; }
  .bg-offset-7 { margin-left: 580.8333333333px; }
  .bg-offset-8 { margin-left: 651.6666666667px; }
  .bg-offset-9 { margin-left: 722.5px; }
  .bg-offset-10 { margin-left: 793.3333333333px; }
  .bg-offset-11 { margin-left: 864.1666666667px; }
  .bg-offset-12 { margin-left: 935px; }
  .bg-image.bg-image-quarter { max-width: none; }
  .bg-image.bg-image-full { height: 550px; }
  .container-custom-full { max-width: 100%; margin-left: 90px; } }
@media (min-width: 1200px) { .container-custom { max-width: 985px; margin-left: 60px; }
  .bg-offset-0 { margin-left: 75px; }
  .bg-offset-5 { margin-left: 490.4166666667px; }
  .bg-offset-6 { margin-left: 572.5px; }
  .bg-offset-7 { margin-left: 654.5833333333px; }
  .bg-offset-8 { margin-left: 736.6666666667px; }
  .bg-offset-9 { margin-left: 818.75px; }
  .bg-offset-10 { margin-left: 900.8333333333px; }
  .bg-offset-11 { margin-left: 982.9166666667px; }
  .bg-offset-12 { margin-left: 1065px; }
  .container-custom-full { max-width: 100%; margin-left: 75px; } }
@media (min-width: 1340px) { .container-custom { max-width: 1040px; margin-left: 100px; }
  .bg-offset-0 { margin-left: 115px; }
  .bg-offset-5 { margin-left: 553.3333333333px; }
  .bg-offset-6 { margin-left: 640px; }
  .bg-offset-7 { margin-left: 726.6666666667px; }
  .bg-offset-8 { margin-left: 813.3333333333px; }
  .bg-offset-9 { margin-left: 900px; }
  .bg-offset-10 { margin-left: 986.6666666667px; }
  .bg-offset-11 { margin-left: 1073.3333333333px; }
  .bg-offset-12 { margin-left: 1160px; }
  .container-custom-full { max-width: 100%; margin-left: 115px; } }
@media (min-width: 1600px) { .container-custom { max-width: 1140px; margin-left: 180px; }
  .bg-offset-0 { margin-left: 195px; }
  .bg-offset-5 { margin-left: 725px; }
  .bg-offset-6 { margin-left: 820px; }
  .bg-offset-7 { margin-left: 915px; }
  .bg-offset-8 { margin-left: 1010px; }
  .bg-offset-9 { margin-left: 1105px; }
  .bg-offset-10 { margin-left: 1200px; }
  .bg-offset-11 { margin-left: 1295px; }
  .bg-offset-12 { margin-left: 1390px; }
  .container-custom-full { max-width: 100%; margin-left: 195px; } }
@media (min-width: 1800px) { .container-custom { margin-left: 255px; }
  .bg-offset-0 { margin-left: 270px; }
  .bg-offset-5 { margin-left: 800px; }
  .bg-offset-6 { margin-left: 895px; }
  .bg-offset-7 { margin-left: 990px; }
  .bg-offset-8 { margin-left: 1085px; }
  .bg-offset-9 { margin-left: 1180px; }
  .bg-offset-10 { margin-left: 1275px; }
  .bg-offset-11 { margin-left: 1370px; }
  .bg-offset-12 { margin-left: 1465px; }
  .container-custom-full { max-width: 100%; margin-left: 270px; } }
@media (min-width: 1921px) { .container-custom { margin-left: 300px; }
  .bg-offset-0 { margin-left: 315px; }
  .bg-offset-5 { margin-left: 845px; }
  .bg-offset-6 { margin-left: 940px; }
  .bg-offset-7 { margin-left: 1035px; }
  .bg-offset-8 { margin-left: 1130px; }
  .bg-offset-9 { margin-left: 1225px; }
  .bg-offset-10 { margin-left: 1320px; }
  .bg-offset-11 { margin-left: 1415px; }
  .bg-offset-12 { margin-left: 1510px; }
  .container-custom-full { max-width: 100%; margin-left: 315px; } }
@media (min-width: 2050px) { .container-custom { margin-left: 450px; }
  .bg-offset-0 { margin-left: 465px; }
  .bg-offset-5 { margin-left: 995px; }
  .bg-offset-6 { margin-left: 1090px; }
  .bg-offset-7 { margin-left: 1185px; }
  .bg-offset-8 { margin-left: 1280px; }
  .bg-offset-9 { margin-left: 1375px; }
  .bg-offset-10 { margin-left: 1470px; }
  .bg-offset-11 { margin-left: 1565px; }
  .bg-offset-12 { margin-left: 1660px; }
  .container-custom-full { max-width: 100%; margin-left: 465px; } }
/** 02.03 Misc */
.ttc { text-transform: capitalize; }
.ttu { text-transform: uppercase; }
.ttl { text-transform: lowercase; }
.ttn { text-transform: none; }

.fw-7 { font-weight: 700 !important; }
.fw-6 { font-weight: 600 !important; }
.fw-5 { font-weight: 500 !important; }
.fw-4 { font-weight: 400 !important; }
.fw-3 { font-weight: 300 !important; }
.fw-rg { font-weight: 400 !important; }
.fw-bold { font-weight: 700 !important; }
.fw-md { font-weight: 500 !important; }
.fw-lt { font-weight: 300 !important; }

.ov-h { overflow: hidden; }
.ov-xh { overflow-x: hidden; }
.ov-yh { overflow-y: hidden; }
.ov-s { overflow: scroll; }
.ov-xs { overflow-x: scroll; }
.ov-ys { overflow-y: scroll; }

.bd { border: 1px solid #e2e8eb; }
.bd-2 { border-width: 2px; }
.bd-3 { border-width: 3px; }
.bdt { border-top: 1px solid #e2e8eb; }
.bdt-2 { border-top-width: 2px; }
.bdt-3 { border-top-width: 3px; }
.bdb { border-bottom: 1px solid #e2e8eb; }
.bdb-2 { border-bottom-width: 2px; }
.bdb-3 { border-bottom-width: 3px; }
.bdl { border-left: 1px solid #e2e8eb; }
.bdl-2 { border-left-width: 2px; }
.bdl-3 { border-left-width: 3px; }
.bdr { border-right: 1px solid #e2e8eb; }
.bdr-2 { border-right-width: 2px; }
.bdr-3 { border-right-width: 3px; }
.bd-dashed { border-style: dashed; }
.bd-dotted { border-style: dotted; }
.bd-double { border-style: double; }
.bd-light { border-color: #dbe4f7; }
.bd-lighter { border-color: #dbe4f7; }
.bd-lightest { border-color: #f4f7fd; }
.bd-dark { border-color: #131313; }
.bd-darker { border-color: #131313; }
.bd-darkest { border-color: #131313; }
.bd-primary { border-color: #ff4157; }
.bd-alternet { border-color: #a2b3cd; }
.bd-secondary { border-color: #ecf6fa; }
.bd-info { border-color: #9ac4fd; }
.bd-warning { border-color: #fbe5a8; }
.bd-success { border-color: #b0efce; }
.bd-danger { border-color: #f5bfb7; }

.round { border-radius: 6px !important; }
.round-sm { border-radius: 4px !important; }
.round-md { border-radius: 10px !important; }
.round-lg { border-radius: 15px !important; }
.round-full, .circle { border-radius: 50% !important; }

.bdrs-1 { border-radius: 1px; }

.bdrs-2 { border-radius: 2px; }

.bdrs-3 { border-radius: 3px; }

.bdrs-4 { border-radius: 4px; }

.bdrs-5 { border-radius: 5px; }

.bdrs-6 { border-radius: 6px; }

.bdrs-7 { border-radius: 7px; }

.bdrs-8 { border-radius: 8px; }

.bdrs-9 { border-radius: 9px; }

.bdrs-1x { border-radius: 10px; }

.bdrs-2x { border-radius: 20px; }

.bdrs-3x { border-radius: 30px; }

.bdrs-4x { border-radius: 40px; }

.bdrs-5x { border-radius: 50px; }

.bdrs-15x { border-radius: 15px; }

.bdrs-25x { border-radius: 25px; }

.bdrs-35x { border-radius: 35px; }

.bdrs-45x { border-radius: 45px; }

.shadow { box-shadow: 0px 3px 12px 0px rgba(10, 16, 21, 0.08) !important; }
.shadow-soft { box-shadow: 0px 5px 45px 0px rgba(10, 16, 21, 0.08); }
.shadow-soft-sm { box-shadow: 0px 2px 12px 0px rgba(10, 16, 21, 0.08); }
.shadow-dark { box-shadow: 0 5px 40px rgba(10, 16, 21, 0.15); }
.shadow-dark-sm { box-shadow: 0 3px 12px 0px rgba(10, 16, 21, 0.15); }
.shadow-heavy { box-shadow: 0 5px 50px rgba(10, 16, 21, 0.25); }
.shadow-heavy-sm { box-shadow: 0 3px 12px 0px rgba(10, 16, 21, 0.25); }

.ovm-top { bottom: auto; }
.ovm-bottom { top: auto; }
.ovm-left { right: auto; }
.ovm-right { left: auto; }
.ovm-h-10 { height: 10%; }
.ovm-h-20 { height: 20%; }
.ovm-h-25 { height: 25%; }
.ovm-h-30 { height: 30%; }
.ovm-h-35 { height: 35%; }
.ovm-h-40 { height: 40%; }
.ovm-h-45 { height: 45%; }
.ovm-h-50 { height: 50%; }
.ovm-h-55 { height: 55%; }
.ovm-h-60 { height: 60%; }
.ovm-h-65 { height: 65%; }
.ovm-h-70 { height: 70%; }
.ovm-h-75 { height: 75%; }
.ovm-h-80 { height: 80%; }
.ovm-h-90 { height: 90%; }
.ovm-h-100 { height: 100%; }
.ovm-w-10 { width: 10%; }
.ovm-w-20 { width: 20%; }
.ovm-w-25 { width: 25%; }
.ovm-w-30 { width: 30%; }
.ovm-w-40 { width: 40%; }
.ovm-w-50 { width: 50%; }
.ovm-w-60 { width: 60%; }
.ovm-w-70 { width: 70%; }
.ovm-w-75 { width: 75%; }
.ovm-w-80 { width: 80%; }
.ovm-w-90 { width: 90%; }
.ovm-w-100 { width: 100%; }

.has-bg-image > div, .has-bg-video > div { position: relative; z-index: 1; }

.bg-fixed { background-attachment: fixed; }
.bg-image { position: absolute !important; z-index: 0 !important; opacity: 0; transition: opacity .4s; }
.bg-image.bg-image-loaded { opacity: 1; }
.bg-image img { display: none !important; }
/*.bg-pattern-dots-color { background-image: url(../images/dot-a.png); background-size: contain; }*/
/*.bg-pattern-dots { background-image: url(../images/dot-b.png); background-size: contain; background-repeat: repeat-y; }*/
.bg-contain { background-size: contain; }
.bg-bottom-center { background-position: 50% 100%; }
.bg-video { position: absolute !important; z-index: 0 !important; overflow: hidden; }
.bg-video-cover { opacity: 0; }
.cover-enabled .bg-video-cover { opacity: 1; }
.bg-video .ytplayer-container { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
.bg-video .ytplayer-container.cover-enabled .bg-video .ytplayer-container { display: none; }

.cover-enabled .ytplayer-container { display: none; }

.bg-image.bp-l0, .has-bg.bp-l0 { background-position: 0%; }

.bg-image.bp-r0, .has-bg.bp-r0 { background-position: 0%; }

.bg-image.bp-l10, .has-bg.bp-l10 { background-position: 10%; }

.bg-image.bp-r10, .has-bg.bp-r10 { background-position: 40%; }

.bg-image.bp-l20, .has-bg.bp-l20 { background-position: 20%; }

.bg-image.bp-r20, .has-bg.bp-r20 { background-position: 30%; }

.bg-image.bp-l25, .has-bg.bp-l25 { background-position: 25%; }

.bg-image.bp-r25, .has-bg.bp-r25 { background-position: 25%; }

.bg-image.bp-l30, .has-bg.bp-l30 { background-position: 30%; }

.bg-image.bp-r30, .has-bg.bp-r30 { background-position: 20%; }

.bg-image.bp-l40, .has-bg.bp-l40 { background-position: 40%; }

.bg-image.bp-r40, .has-bg.bp-r40 { background-position: 10%; }

.overlay:after { content: ''; }
.overlay-light:after { background: #fff; }
.overlay-dark:after { background: #000; }
.overlay-theme:after { background: #ff4157; }
.overlay-theme-dark:after { background: #0c195a; }
.overlay-opacity-10:after { opacity: 0.1; }
.overlay-opacity-15:after { opacity: 0.15; }
.overlay-opacity-20:after { opacity: 0.2; }
.overlay-opacity-25:after { opacity: 0.25; }
.overlay-opacity-30:after { opacity: 0.3; }
.overlay-opacity-35:after { opacity: 0.35; }
.overlay-opacity-40:after { opacity: 0.4; }
.overlay-opacity-45:after { opacity: 0.45; }
.overlay-opacity-50:after { opacity: 0.5; }
.overlay-opacity-60:after { opacity: 0.6; }
.overlay-opacity-65:after { opacity: 0.65; }
.overlay-opacity-70:after { opacity: 0.7; }
.overlay-opacity-75:after { opacity: 0.75; }
.overlay-opacity-80:after { opacity: 0.8; }
.overlay-opacity-85:after { opacity: 0.85; }
.overlay-opacity-90:after { opacity: 0.9; }
.overlay-opacity-95:after { opacity: 0.95; }
.overlay-opacity-100:after { opacity: 1; }

.alignright, .alignleft, .aligncenter, .alignnone { display: block; padding: 0; margin: 15px auto; }

@media (min-width: 576px) { .alignright, .alignleft, .aligncenter, .alignnone { margin: 0 0 15px; display: inline; }
  .alignright { margin-left: 20px; float: right; }
  .alignleft { margin-right: 20px; float: left; }
  .alignnone { margin-right: 20px; }
  .aligncenter { display: block; margin: 15px auto; } }
.wide, .wide-rg, .wide-auto, .wide-auto-rg { max-width: 750px; }
.wide-auto, .wide-auto-rg, .wide-auto-xl, .wide-auto-lg, .wide-auto-md, .wide-auto-sm, .wide-auto-xs { margin: 0 auto; }
.wide-xl, .wide-auto-xl { max-width: 1560px; }
.wide-lg, .wide-auto-lg { max-width: 1140px; }
.wide-md, .wide-auto-md { max-width: 945px; }
.wide-sm, .wide-auto-sm { max-width: 555px; }
.wide-xs, .wide-auto-xs { max-width: 360px; }
.wide-none, .wide-auto-none { max-width: inherit; }

.over-up, [class*="over-up-"] { z-index: 9; }

.over-up { margin-top: -125px; }
.over-up-sm { margin-top: -45px; }
.over-up-md { margin-top: -75px; }
.over-up-lg { margin-top: -165px; }
.over-up-xl { margin-top: -275px; }

@media (min-width: 768px) { .over-up { margin-top: -190px; }
  .over-up-sm { margin-top: -75px; }
  .over-up-md { margin-top: -125px; }
  .over-up-lg { margin-top: -275px; }
  .over-up-xl { margin-top: -350px; } }
.hr, hr { border-top: 1px solid #dbe4f7; margin-top: 2.5rem; margin-bottom: 2.5rem; }

.section-full { display: flex; align-items: center; min-height: 100vh; }
.section-content { width: 100%; }

.h-110 { height: 110% !important; }
.h-115 { height: 115% !important; }
.h-120 { height: 120% !important; }
.h-125 { height: 125% !important; }
.h-130 { height: 130% !important; }

.shape-dot-a { background-image: url(../images/shape-dot-a.png); }

[class*="mask-"]:before { position: absolute; left: 0; right: 0; bottom: 0; top: 0; content: ""; background-repeat: no-repeat; }

.mask-a:before { background-image: url(../images/mask-a.svg); }
.ie .mask-a:before { background-image: url(../images/mask-a.png); }

.mask-b:before { background-image: url(../images/mask-b.svg); }
.ie .mask-b:before { background-image: url(../images/mask-b.png); }

.mask-c:before { background-image: url(../images/mask-a.svg); }
.ie .mask-c:before { background-image: url(../images/mask-a.png); }

.mask-a:before { background-position: -23% 0; background-size: 90%; }
.mask-b:before { background-size: contain; left: 4%; opacity: 0.3; }

@media (min-width: 992px) { .banner-inner .mask-a:before { background-size: contain; }
  .banner-inner-sm .mask-a:before { background-position: -5% 0; } }
@media (min-width: 1200px) { .mask-a:before { background-size: 83%; background-position: -45% 0; } }
@media (min-width: 1600px) { .mask-a:before { background-size: 80%; background-position: -45% -25%; } }
/** 02.04 Preset */
.bg-half { margin-bottom: 50px; position: static !important; }
.bg-half img { display: block !important; }
.bg-half::after { display: none; }
.bg-img { position: relative; height: 100%; min-height: 350px; }

.in-top { margin-top: -52px; }

.in-bottom { margin-bottom: -52px; }

@media (min-width: 768px) { .bg-half { background-position: center; margin: 0; position: absolute !important; }
  .bg-half img { display: none !important; }
  .style-left { right: 50%; }
  .style-right { left: 50%; }
  .style-right-custom { left: calc(50% + 15px); } }
@media (min-width: 992px) { .style-right-custom { left: calc(50% + 37.5px); } }
@media (min-width: 1200px) { .style-right-custom { left: calc(50% + 50px); } }
[class*="overlay"], [class*="overlay-"] { position: relative; }
[class*="overlay"]::after, [class*="overlay-"]::after { width: 100%; height: 100%; position: absolute; content: ''; left: 0; top: 0; }

.overlay-gradient::after { background-image: linear-gradient(to top, #121b22 0%, rgba(18, 27, 34, 0) 100%); opacity: 1; z-index: 0; }

.overlay-gradient-full::after { z-index: 0; background-image: linear-gradient(to bottom, #121b22 0%, rgba(18, 27, 34, 0.5) 50%, #121b22 100%); opacity: 1; }

.overlay:before { background-color: rgba(39, 17, 73, 0.5); content: ''; display: block; width: 100%; height: 100%; mix-blend-mode: darken; position: absolute; top: 0; left: 0; }
.overlay:after { background-color: rgba(74, 12, 199, 0.6); display: block; width: 100%; height: 100%; mix-blend-mode: hard-light; }

.overlay-blue:before { background-color: rgba(39, 17, 73, 0.5); content: ''; display: block; width: 100%; height: 100%; mix-blend-mode: darken; position: absolute; top: 0; left: 0; }
.overlay-blue:after { background-color: #5b51ec; display: block; mix-blend-mode: hard-light; }

.overlay-blue::after { background: rgba(74, 12, 199, 0.9); }

.overlay-dark { position: relative; }
.overlay-dark::after { background: rgba(30, 32, 47, 0.95); display: block; }
.overlay-dark-light::after { background: rgba(30, 32, 47, 0.7); display: block; }
.overlay-dark-s2:after { background: rgba(38, 38, 38, 0.97); display: block; }

.overlay-darker:before { background-color: rgba(23, 21, 39, 0.6); content: ''; display: block; mix-blend-mode: darken; position: absolute; top: 0; left: 0; }
.overlay-darker:after { background-color: rgba(20, 20, 53, 0.8); display: block; mix-blend-mode: darken; }

.tc-light p { color: #ffffff !important; }
.tc-light h1, .tc-light h2, .tc-light h3, .tc-light h4, .tc-light h5, .tc-light h6 { color: #ffffff !important; }
.tc-light .color-primary { color: #ff4157 !important; }
.tc-light .color-secondary { color: #ecf6fa !important; }
.tc-primary { color: #ff4157 !important; }
.bg-ocean-pattern .tc-primary { color: #ff4157 !important; }
.tc-alt { color: #4b4b4b !important; }
.tc-grey { color: #4b4b4b !important; }
.tc-grey-alt { color: #4a4a4a !important; }
.tc-alt { color: #4b4b4b; }
.tc-light-dark { color: rgba(255, 255, 255, 0.7) !important; }
.tc-light-dark h1, .tc-light-dark h2, .tc-light-dark h3, .tc-light-dark h4, .tc-light-dark h5 { color: #ffffff; }
.tc-bunker { color: #a2a9bf; }
.tc-bunker p:not(.lead) { color: #a2a9bf; }

.is-shadow { box-shadow: 0 0 30px rgba(0, 0, 0, 0.05); }

.shadow-alt { box-shadow: 0 0 10px rgba(57, 59, 102, 0.07); }

.bg-primary { background-color: #ff4157 !important; }
.bg-primary .heading-xs::before, .bg-primary .heading-xs::after { background: #ffffff; }
.bg-lighter { background-color: #ffffff; }
.bg-darker { background-color: rgba(28, 30, 41, 0.9); }
.bg-secondary { background-color: #ecf6fa !important; }
.bg-dark-alt .bg-secondary { background: #121d26 !important; }
.bg-dark .bg-secondary { background: #121d26 !important; }
.bg-secondary-alt { background: #ecf3f6; }
.bg-dark { background-color: #121b22 !important; }
.bg-dark-s2 { background: #1c1e29; }
.bg-dark-alt { background: #0a1015; }
.bg-dark-light { background: #0c1319; }
.bg-light { background: #ffffff !important; }
.bg-light-s2 { background: #fff4f5; }
.bg-light-s3 { background: #fde7e9; }
.bg-light-alt { background: #eff3f5; }
.bg-light-gradient { background-image: linear-gradient(to bottom, #eff3f5 0%, rgba(239, 243, 245, 0) 100%); }
.bg-light-gradient::after { opacity: 1; }
.bg-bunker { background: #0E161D !important; }
.bg-bunker-alt { background: #19232a !important; }
.bg-dark-ftr { background: #0c1319; }

.lead { font-size: 1.25rem; font-weight: 300; line-height: 1.6; }
.lead-bold { font-weight: 700; }
.lead-semibold { font-weight: 600; }
.lead-medium { font-weight: 500; }
.lead-regular { font-weight: 400; }

.tc-light { color: #ffffff; }

.color-accent { color: #ff4157; }
.tc-light .color-accent { color: #ff4157 !important; }
.color-accent-alt { color: #1a5ff8; }

@media (min-width: 576px) { .lead { font-size: 1.25rem; line-height: 1.5; } }
@media (min-width: 992px) { .lead { font-size: 1.5rem; } }
@media (min-width: 1400px) { .lead { font-size: 1.5rem; }
  .lead-lg { font-size: 1.875rem; }
  .lead-md { font-size: 1.334rem; } }
.h-full { height: 100%; }

.pb-0 { padding-bottom: 0px; }

.pb-0rem { padding-bottom: calc(0rem - 30px); }

.pb-1 { padding-bottom: 1px; }

.pb-1rem { padding-bottom: calc(1rem - 30px); }

.pb-2 { padding-bottom: 2px; }

.pb-2rem { padding-bottom: calc(2rem - 30px); }

.pb-3 { padding-bottom: 3px; }

.pb-3rem { padding-bottom: calc(3rem - 30px); }

.pb-4 { padding-bottom: 4px; }

.pb-4rem { padding-bottom: calc(4rem - 30px); }

.pb-5 { padding-bottom: 5px; }

.pb-5rem { padding-bottom: calc(5rem - 30px); }

.pb-6 { padding-bottom: 6px; }

.pb-6rem { padding-bottom: calc(6rem - 30px); }

.pb-7 { padding-bottom: 7px; }

.pb-7rem { padding-bottom: calc(7rem - 30px); }

.pb-8 { padding-bottom: 8px; }

.pb-8rem { padding-bottom: calc(8rem - 30px); }

.pb-9 { padding-bottom: 9px; }

.pb-9rem { padding-bottom: calc(9rem - 30px); }

.pb-10 { padding-bottom: 10px; }

.pb-10rem { padding-bottom: calc(10rem - 30px); }

.pb-11 { padding-bottom: 11px; }

.pb-11rem { padding-bottom: calc(11rem - 30px); }

.pb-12 { padding-bottom: 12px; }

.pb-12rem { padding-bottom: calc(12rem - 30px); }

.pb-13 { padding-bottom: 13px; }

.pb-13rem { padding-bottom: calc(13rem - 30px); }

.pb-14 { padding-bottom: 14px; }

.pb-14rem { padding-bottom: calc(14rem - 30px); }

.pb-15 { padding-bottom: 15px; }

.pb-15rem { padding-bottom: calc(15rem - 30px); }

.pb-16 { padding-bottom: 16px; }

.pb-16rem { padding-bottom: calc(16rem - 30px); }

.pb-17 { padding-bottom: 17px; }

.pb-17rem { padding-bottom: calc(17rem - 30px); }

.pb-18 { padding-bottom: 18px; }

.pb-18rem { padding-bottom: calc(18rem - 30px); }

.pb-19 { padding-bottom: 19px; }

.pb-19rem { padding-bottom: calc(19rem - 30px); }

.pb-20 { padding-bottom: 20px; }

.pb-20rem { padding-bottom: calc(20rem - 30px); }

@media (min-width: 576px) { .pb-0rem { padding-bottom: 0rem; }
  .pb-1rem { padding-bottom: 1rem; }
  .pb-2rem { padding-bottom: 2rem; }
  .pb-3rem { padding-bottom: 3rem; }
  .pb-4rem { padding-bottom: 4rem; }
  .pb-5rem { padding-bottom: 5rem; }
  .pb-6rem { padding-bottom: 6rem; }
  .pb-7rem { padding-bottom: 7rem; }
  .pb-8rem { padding-bottom: 8rem; }
  .pb-9rem { padding-bottom: 9rem; }
  .pb-10rem { padding-bottom: 10rem; }
  .pb-11rem { padding-bottom: 11rem; }
  .pb-12rem { padding-bottom: 12rem; }
  .pb-13rem { padding-bottom: 13rem; }
  .pb-14rem { padding-bottom: 14rem; }
  .pb-15rem { padding-bottom: 15rem; }
  .pb-16rem { padding-bottom: 16rem; }
  .pb-17rem { padding-bottom: 17rem; }
  .pb-18rem { padding-bottom: 18rem; }
  .pb-19rem { padding-bottom: 19rem; }
  .pb-20rem { padding-bottom: 20rem; }
  .pl-0rem { padding-left: 0rem; }
  .pl-1rem { padding-left: 1rem; }
  .pl-2rem { padding-left: 2rem; }
  .pl-3rem { padding-left: 3rem; }
  .pl-4rem { padding-left: 4rem; }
  .pl-5rem { padding-left: 5rem; }
  .pl-6rem { padding-left: 6rem; }
  .pl-7rem { padding-left: 7rem; }
  .pl-8rem { padding-left: 8rem; }
  .pl-9rem { padding-left: 9rem; }
  .pl-10rem { padding-left: 10rem; }
  .pl-11rem { padding-left: 11rem; }
  .pl-12rem { padding-left: 12rem; }
  .pl-13rem { padding-left: 13rem; }
  .pl-14rem { padding-left: 14rem; }
  .pl-15rem { padding-left: 15rem; }
  .pl-16rem { padding-left: 16rem; }
  .pl-17rem { padding-left: 17rem; }
  .pl-18rem { padding-left: 18rem; }
  .pl-19rem { padding-left: 19rem; }
  .pl-20rem { padding-left: 20rem; }
  .pr-0rem { padding-right: 0rem; }
  .pr-1rem { padding-right: 1rem; }
  .pr-2rem { padding-right: 2rem; }
  .pr-3rem { padding-right: 3rem; }
  .pr-4rem { padding-right: 4rem; }
  .pr-5rem { padding-right: 5rem; }
  .pr-6rem { padding-right: 6rem; }
  .pr-7rem { padding-right: 7rem; }
  .pr-8rem { padding-right: 8rem; }
  .pr-9rem { padding-right: 9rem; }
  .pr-10rem { padding-right: 10rem; }
  .pr-11rem { padding-right: 11rem; }
  .pr-12rem { padding-right: 12rem; }
  .pr-13rem { padding-right: 13rem; }
  .pr-14rem { padding-right: 14rem; }
  .pr-15rem { padding-right: 15rem; }
  .pr-16rem { padding-right: 16rem; }
  .pr-17rem { padding-right: 17rem; }
  .pr-18rem { padding-right: 18rem; }
  .pr-19rem { padding-right: 19rem; }
  .pr-20rem { padding-right: 20rem; } }
@media (max-width: 992px) { .res-pr-0rem { padding-right: 0rem; }
  .res-pr-1rem { padding-right: 1rem; }
  .res-pr-2rem { padding-right: 2rem; }
  .res-pr-3rem { padding-right: 3rem; }
  .res-pr-4rem { padding-right: 4rem; }
  .res-pr-5rem { padding-right: 5rem; }
  .res-pr-6rem { padding-right: 6rem; }
  .res-pr-7rem { padding-right: 7rem; }
  .res-pr-8rem { padding-right: 8rem; }
  .res-pr-9rem { padding-right: 9rem; }
  .res-pr-10rem { padding-right: 10rem; }
  .res-pr-11rem { padding-right: 11rem; }
  .res-pr-12rem { padding-right: 12rem; }
  .res-pr-13rem { padding-right: 13rem; }
  .res-pr-14rem { padding-right: 14rem; }
  .res-pr-15rem { padding-right: 15rem; }
  .res-pr-16rem { padding-right: 16rem; }
  .res-pr-17rem { padding-right: 17rem; }
  .res-pr-18rem { padding-right: 18rem; }
  .res-pr-19rem { padding-right: 19rem; }
  .res-pr-20rem { padding-right: 20rem; } }
/** margin setting **/
.f-0 { margin-top: 0px; }

.f-1 { margin-top: 1px; }

.f-2 { margin-top: 2px; }

.f-3 { margin-top: 3px; }

.f-4 { margin-top: 4px; }

.f-5 { margin-top: 5px; }

.f-6 { margin-top: 6px; }

.f-7 { margin-top: 7px; }

.f-8 { margin-top: 8px; }

.f-9 { margin-top: 9px; }

.f-10 { margin-top: 10px; }

.f-11 { margin-top: 11px; }

.f-12 { margin-top: 12px; }

.f-13 { margin-top: 13px; }

.f-14 { margin-top: 14px; }

.f-15 { margin-top: 15px; }

.f-16 { margin-top: 16px; }

.f-17 { margin-top: 17px; }

.f-18 { margin-top: 18px; }

.f-19 { margin-top: 19px; }

.f-20 { margin-top: 20px; }

.mr-0 { margin-right: 0px; }

.mr-1 { margin-right: 1px; }

.mr-2 { margin-right: 2px; }

.mr-3 { margin-right: 3px; }

.mr-4 { margin-right: 4px; }

.mr-5 { margin-right: 5px; }

.mr-6 { margin-right: 6px; }

.mr-7 { margin-right: 7px; }

.mr-8 { margin-right: 8px; }

.mr-9 { margin-right: 9px; }

.mr-10 { margin-right: 10px; }

.mr-11 { margin-right: 11px; }

.mr-12 { margin-right: 12px; }

.mr-13 { margin-right: 13px; }

.mr-14 { margin-right: 14px; }

.mr-15 { margin-right: 15px; }

.mr-16 { margin-right: 16px; }

.mr-17 { margin-right: 17px; }

.mr-18 { margin-right: 18px; }

.mr-19 { margin-right: 19px; }

.mr-20 { margin-right: 20px; }

.ml-0 { margin-left: 0px; }

.ml-1 { margin-left: 1px; }

.ml-2 { margin-left: 2px; }

.ml-3 { margin-left: 3px; }

.ml-4 { margin-left: 4px; }

.ml-5 { margin-left: 5px; }

.ml-6 { margin-left: 6px; }

.ml-7 { margin-left: 7px; }

.ml-8 { margin-left: 8px; }

.ml-9 { margin-left: 9px; }

.ml-10 { margin-left: 10px; }

.ml-11 { margin-left: 11px; }

.ml-12 { margin-left: 12px; }

.ml-13 { margin-left: 13px; }

.ml-14 { margin-left: 14px; }

.ml-15 { margin-left: 15px; }

.ml-16 { margin-left: 16px; }

.ml-17 { margin-left: 17px; }

.ml-18 { margin-left: 18px; }

.ml-19 { margin-left: 19px; }

.ml-20 { margin-left: 20px; }

.mt-0 { margin-top: 0px !important; }

.mt-1 { margin-top: 1px !important; }

.mt-2 { margin-top: 2px !important; }

.mt-3 { margin-top: 3px !important; }

.mt-4 { margin-top: 4px !important; }

.mt-5 { margin-top: 5px !important; }

.mt-6 { margin-top: 6px !important; }

.mt-7 { margin-top: 7px !important; }

.mt-8 { margin-top: 8px !important; }

.mt-9 { margin-top: 9px !important; }

.mt-10 { margin-top: 10px !important; }

.mt-11 { margin-top: 11px !important; }

.mt-12 { margin-top: 12px !important; }

.mt-13 { margin-top: 13px !important; }

.mt-14 { margin-top: 14px !important; }

.mt-15 { margin-top: 15px !important; }

.mt-16 { margin-top: 16px !important; }

.mt-17 { margin-top: 17px !important; }

.mt-18 { margin-top: 18px !important; }

.mt-19 { margin-top: 19px !important; }

.mt-20 { margin-top: 20px !important; }

.mt-21 { margin-top: 21px !important; }

.mt-22 { margin-top: 22px !important; }

.mt-23 { margin-top: 23px !important; }

.mt-24 { margin-top: 24px !important; }

.mt-25 { margin-top: 25px !important; }

.mt-26 { margin-top: 26px !important; }

.mt-27 { margin-top: 27px !important; }

.mt-28 { margin-top: 28px !important; }

.mt-29 { margin-top: 29px !important; }

.mt-30 { margin-top: 30px !important; }

.mt-31 { margin-top: 31px !important; }

.mt-32 { margin-top: 32px !important; }

.mt-33 { margin-top: 33px !important; }

.mt-34 { margin-top: 34px !important; }

.mt-35 { margin-top: 35px !important; }

.mt-36 { margin-top: 36px !important; }

.mt-37 { margin-top: 37px !important; }

.mt-38 { margin-top: 38px !important; }

.mt-39 { margin-top: 39px !important; }

.mt-40 { margin-top: 40px !important; }

.mb-0 { margin-bottom: 0px !important; }

.mb-1 { margin-bottom: 1px !important; }

.mb-2 { margin-bottom: 2px !important; }

.mb-3 { margin-bottom: 3px !important; }

.mb-4 { margin-bottom: 4px !important; }

.mb-5 { margin-bottom: 5px !important; }

.mb-6 { margin-bottom: 6px !important; }

.mb-7 { margin-bottom: 7px !important; }

.mb-8 { margin-bottom: 8px !important; }

.mb-9 { margin-bottom: 9px !important; }

.mb-10 { margin-bottom: 10px !important; }

.mb-11 { margin-bottom: 11px !important; }

.mb-12 { margin-bottom: 12px !important; }

.mb-13 { margin-bottom: 13px !important; }

.mb-14 { margin-bottom: 14px !important; }

.mb-15 { margin-bottom: 15px !important; }

.mb-16 { margin-bottom: 16px !important; }

.mb-17 { margin-bottom: 17px !important; }

.mb-18 { margin-bottom: 18px !important; }

.mb-19 { margin-bottom: 19px !important; }

.mb-20 { margin-bottom: 20px !important; }

.mb-21 { margin-bottom: 21px !important; }

.mb-22 { margin-bottom: 22px !important; }

.mb-23 { margin-bottom: 23px !important; }

.mb-24 { margin-bottom: 24px !important; }

.mb-25 { margin-bottom: 25px !important; }

.mb-26 { margin-bottom: 26px !important; }

.mb-27 { margin-bottom: 27px !important; }

.mb-28 { margin-bottom: 28px !important; }

.mb-29 { margin-bottom: 29px !important; }

.mb-30 { margin-bottom: 30px !important; }

.mb-31 { margin-bottom: 31px !important; }

.mb-32 { margin-bottom: 32px !important; }

.mb-33 { margin-bottom: 33px !important; }

.mb-34 { margin-bottom: 34px !important; }

.mb-35 { margin-bottom: 35px !important; }

.mb-36 { margin-bottom: 36px !important; }

.mb-37 { margin-bottom: 37px !important; }

.mb-38 { margin-bottom: 38px !important; }

.mb-39 { margin-bottom: 39px !important; }

.mb-40 { margin-bottom: 40px !important; }

@media (min-width: 768px) { .mrm-30 { margin-right: -30px; } }
@media (min-width: 992px) { .mrm-1 { margin-right: -1px; }
  .mrm-2 { margin-right: -2px; }
  .mrm-3 { margin-right: -3px; }
  .mrm-4 { margin-right: -4px; }
  .mrm-5 { margin-right: -5px; }
  .mrm-6 { margin-right: -6px; }
  .mrm-7 { margin-right: -7px; }
  .mrm-8 { margin-right: -8px; }
  .mrm-9 { margin-right: -9px; }
  .mrm-10 { margin-right: -10px; }
  .mrm-11 { margin-right: -11px; }
  .mrm-12 { margin-right: -12px; }
  .mrm-13 { margin-right: -13px; }
  .mrm-14 { margin-right: -14px; }
  .mrm-15 { margin-right: -15px; }
  .mrm-16 { margin-right: -16px; }
  .mrm-17 { margin-right: -17px; }
  .mrm-18 { margin-right: -18px; }
  .mrm-19 { margin-right: -19px; }
  .mrm-20 { margin-right: -20px; }
  .mrm-21 { margin-right: -21px; }
  .mrm-22 { margin-right: -22px; }
  .mrm-23 { margin-right: -23px; }
  .mrm-24 { margin-right: -24px; }
  .mrm-25 { margin-right: -25px; }
  .mrm-26 { margin-right: -26px; }
  .mrm-27 { margin-right: -27px; }
  .mrm-28 { margin-right: -28px; }
  .mrm-29 { margin-right: -29px; }
  .mrm-30 { margin-right: -30px; }
  .mrm-31 { margin-right: -31px; }
  .mrm-32 { margin-right: -32px; }
  .mrm-33 { margin-right: -33px; }
  .mrm-34 { margin-right: -34px; }
  .mrm-35 { margin-right: -35px; }
  .mrm-36 { margin-right: -36px; }
  .mrm-37 { margin-right: -37px; }
  .mrm-38 { margin-right: -38px; }
  .mrm-39 { margin-right: -39px; }
  .mrm-40 { margin-right: -40px; }
  .mlm-1 { margin-left: -1px; }
  .mlm-2 { margin-left: -2px; }
  .mlm-3 { margin-left: -3px; }
  .mlm-4 { margin-left: -4px; }
  .mlm-5 { margin-left: -5px; }
  .mlm-6 { margin-left: -6px; }
  .mlm-7 { margin-left: -7px; }
  .mlm-8 { margin-left: -8px; }
  .mlm-9 { margin-left: -9px; }
  .mlm-10 { margin-left: -10px; }
  .mlm-11 { margin-left: -11px; }
  .mlm-12 { margin-left: -12px; }
  .mlm-13 { margin-left: -13px; }
  .mlm-14 { margin-left: -14px; }
  .mlm-15 { margin-left: -15px; }
  .mlm-16 { margin-left: -16px; }
  .mlm-17 { margin-left: -17px; }
  .mlm-18 { margin-left: -18px; }
  .mlm-19 { margin-left: -19px; }
  .mlm-20 { margin-left: -20px; }
  .mlm-21 { margin-left: -21px; }
  .mlm-22 { margin-left: -22px; }
  .mlm-23 { margin-left: -23px; }
  .mlm-24 { margin-left: -24px; }
  .mlm-25 { margin-left: -25px; }
  .mlm-26 { margin-left: -26px; }
  .mlm-27 { margin-left: -27px; }
  .mlm-28 { margin-left: -28px; }
  .mlm-29 { margin-left: -29px; }
  .mlm-30 { margin-left: -30px; }
  .mlm-31 { margin-left: -31px; }
  .mlm-32 { margin-left: -32px; }
  .mlm-33 { margin-left: -33px; }
  .mlm-34 { margin-left: -34px; }
  .mlm-35 { margin-left: -35px; }
  .mlm-36 { margin-left: -36px; }
  .mlm-37 { margin-left: -37px; }
  .mlm-38 { margin-left: -38px; }
  .mlm-39 { margin-left: -39px; }
  .mlm-40 { margin-left: -40px; }
  .mtm-0 { margin-top: -0px !important; }
  .mtm-1 { margin-top: -1px !important; }
  .mtm-2 { margin-top: -2px !important; }
  .mtm-3 { margin-top: -3px !important; }
  .mtm-4 { margin-top: -4px !important; }
  .mtm-5 { margin-top: -5px !important; }
  .mtm-6 { margin-top: -6px !important; }
  .mtm-7 { margin-top: -7px !important; }
  .mtm-8 { margin-top: -8px !important; }
  .mtm-9 { margin-top: -9px !important; }
  .mtm-10 { margin-top: -10px !important; }
  .mtm-11 { margin-top: -11px !important; }
  .mtm-12 { margin-top: -12px !important; }
  .mtm-13 { margin-top: -13px !important; }
  .mtm-14 { margin-top: -14px !important; }
  .mtm-15 { margin-top: -15px !important; }
  .mtm-16 { margin-top: -16px !important; }
  .mtm-17 { margin-top: -17px !important; }
  .mtm-18 { margin-top: -18px !important; }
  .mtm-19 { margin-top: -19px !important; }
  .mtm-20 { margin-top: -20px !important; }
  .mtm-21 { margin-top: -21px !important; }
  .mtm-22 { margin-top: -22px !important; }
  .mtm-23 { margin-top: -23px !important; }
  .mtm-24 { margin-top: -24px !important; }
  .mtm-25 { margin-top: -25px !important; }
  .mtm-26 { margin-top: -26px !important; }
  .mtm-27 { margin-top: -27px !important; }
  .mtm-28 { margin-top: -28px !important; }
  .mtm-29 { margin-top: -29px !important; }
  .mtm-30 { margin-top: -30px !important; }
  .mtm-31 { margin-top: -31px !important; }
  .mtm-32 { margin-top: -32px !important; }
  .mtm-33 { margin-top: -33px !important; }
  .mtm-34 { margin-top: -34px !important; }
  .mtm-35 { margin-top: -35px !important; }
  .mtm-36 { margin-top: -36px !important; }
  .mtm-37 { margin-top: -37px !important; }
  .mtm-38 { margin-top: -38px !important; }
  .mtm-39 { margin-top: -39px !important; }
  .mtm-40 { margin-top: -40px !important; } }
.bdr { border: 1px solid #e2e8eb; }
.bg-dark-alt .bdr { border-color: rgba(226, 232, 235, 0.18); }
.bdr-t { border-width: 1px 0 0 0 !important; border-color: #e2e8eb; }
.bdr-b { border-width: 0 0 1px 0 !important; border-color: #e2e8eb; }
.bdr-l { border-width: 0 0 0 1px !important; border-color: #e2e8eb; }
.bdr-r { border-right: 1px solid #525269; }
.bdr-light-dark { border: 1px solid rgba(255, 255, 255, 0.2); }
.bdr-dark { border: 1px solid #525269; }
.bdr-counter { border: 1px solid #78778c; }
.bdr-dark-v1 { border-color: #525269; }

.btn { position: relative; font-size: 14px; font-weight: 700; font-family: "Poppins", sans-serif; color: #ffffff; background: #ff4157; text-transform: uppercase; border-radius: 0px; display: inline-block; padding: 10px 20px; transition: all ease .5s; outline-style: none; min-width: 120px; }
.bg-ocean-pattern .btn { background: #ff4157; }
.btn:hover, .btn:focus { background: #ff1d37; color: #ffffff; }
.btn:focus { box-shadow: none; outline-style: none; }
.bg-primary .btn { background: #ffffff; color: #ff4157; }
.bg-primary .btn:hover { background: transparent; color: #ffffff; border-color: #ffffff; }
.btn-lg { padding: 10px 30px; }
.btn-md { padding: 10px 24px; }
.btn-sm { padding: 10px 20px; }
.btn-xs { padding: 5px 18px; font-size: 13px; font-weight: 400; text-transform: capitalize; }
.btn-arrow { font-size: 14px; color: #ff4157; font-weight: 600; background: transparent; border-color: #e5e5e5; text-transform: capitalize; border: none; position: relative; min-width: auto; padding: 0; }
.btn-arrow::after { position: static; content: "\e661"; font-family: "themify"; font-size: 11px; transition: all ease-in .3s; margin-left: 6px; display: inline-block; transform: translateX(0); }
.btn-arrow:hover { background: transparent; color: #f4001c; }
.btn-arrow:hover::after { transform: translateX(3px); }
.btn-arrow:focus { background: transparent; color: #f4001c; }
.btn-arrow-light { color: #9399c7; }
.btn-arrow-light:hover::after { transform: translateX(0px); transition: 0s; }
.bg-dark-alt .btn-arrow:hover { color: #ffffff; }
.btn-outline { background: transparent; border-color: #ffffff; border: 1px solid; }
.btn-outline:hover { border-color: #ff4157; }
.bg-primary .btn-outline { color: #ffffff; background: transparent; }
.bg-primary .btn-outline:hover { background: #ffffff; color: #ff4157; }
.btn-underline { padding: 0; background: transparent; color: #131313; font-weight: 700; padding-bottom: 9px; position: relative; }
.btn-underline::after { position: absolute; content: ""; width: 100%; background: #ff4157; height: 1px; bottom: 0; right: 0; transition: all ease .5s; }
.btn-underline:hover { color: #ff4157; }
.btn-underline:hover::after { width: 50%; }
.btn-arrow-style { font-size: 1rem; display: flex; padding: 0; align-items: center; background: #ff4157; padding: 0; border: none; }
.btn-arrow-style span { padding: 20px 15px; background: #ff5b6e; text-align: left; transition: all ease .5s; }
.btn-arrow-style em { padding: 15px; height: 100%; }
.btn-arrow-style:hover span { background: #ff2840; }
.btn-scroll { width: 30px; height: 30px; border-radius: 50%; font-size: 14px; padding: 6px; background: #ff4157; color: #ffffff; display: inline-block; text-align: center; }
.btn-scroll:hover { background: #f4001c; color: #ffffff; }
.btn-scroll:active, .btn-scroll:focus { color: #ffffff; }
.btn-secondary { background: #ecf6fa; color: #131313; border-color: transparent; }
.btn-secondary:hover { background: #c4e3ef; border-color: #c4e3ef; color: #131313; }
.btn-info { background: #026bfb; border-color: #026bfb; }
.btn-info:hover { background: #0255c8; border-color: #0255c8; }
.btn-warring { background: #f6be26; border-color: #f6be26; }
.btn-warring:hover { background: #e0a609; border-color: #e0a609; }
.btn-success { background: #39d884; border-color: #39d884; }
.btn-success:hover { background: #25b96b; border-color: #25b96b; }
.btn-danger { background: #e6604a; border-color: #e6604a; }
.btn-danger:hover { background: #de3a1f; border-color: #de3a1f; }
.btn-light { background: #ffffff; border-color: #ffffff; color: #131313; }
.btn-light:hover { background: #e6e6e6; color: #131313; }
.btn-dark { background: #000000; border-color: #000000; }
.btn-dark:hover { background: #4d4d4d; }
.btn-social { background: #000000; border-color: #000000; display: inline-block; }
.btn-social .icon { display: inline-block; }
.btn-social .icon + span { margin-left: 10px; }
.btn-social span { position: relative; z-index: 999999; display: inline-block; }
.btn-social:hover { background: #4d4d4d; }
.btn-facebook { background: #3b5998; border-color: #3b5998; }
.btn-facebook:hover { background: #2d4373; }
.btn-google { background: #d85040; border-color: #d85040; }
.btn-google:hover { background: #be3727; }
.btn-uppercase { text-transform: uppercase; }
.btn-capitalize { text-transform: capitalize; }
.btn-initial { text-transform: initial; }
.btn-creative { height: 60px; padding: 0 40px; color: #000000; font-weight: 700; font-size: 0.9rem; line-height: 60px; text-transform: uppercase; display: inline-flex; align-items: center; position: relative; z-index: 1; transition: all 0.5s; border: none; background: transparent; }
.tc-light .btn-creative { color: #ffffff; }
.btn-creative:after { position: absolute; left: 0; top: 0; width: 60px; height: 100%; background: #ff4157; content: ""; z-index: -1; transition: all 0.5s; }
.btn-creative:hover { color: #ffffff; }
.btn-creative:hover:after { width: 100%; }
.btn-creative:before { width: 40px; height: 2px; background: currentColor; margin-right: 10px; content: ""; }

@media (min-width: 576px) { .btn-md { padding: 14px 15px; }
  .btn-post { margin-top: 10px; } }
@media (min-width: 992px) { .btn { padding: 13px 30px 14px; }
  .btn-sm { padding: 12px 25px; }
  .btn-md { padding: 14px 40px; }
  .btn-lg { padding: 14px 46px; }
  .btn-arrow { padding: 0; }
  .btn-xs { padding: 5px 18px; }
  .btn-arrow-style { font-size: 1.125rem; padding: 0; }
  .btn-arrow-style span { padding: 30px 40px; }
  .btn-arrow-style em { padding: 34px; }
  .btn-scroll { width: 50px; height: 50px; font-size: 18px; padding: 11px; } }
@media (min-width: 1200px) { .btn-s2 { padding: 16px 46px; font-size: 18px; }
  .w-72 { width: 72% !important; } }
.button-area { margin-top: 30px; }

@media (min-width: 992px) { .button-area-sm { margin-top: 30px; } }
@media (min-width: 992px) { .button-area-sm { margin-top: 50px; }
  .button-area-md { margin-top: 100px; } }
.video-btn { position: absolute; z-index: 10; top: 50%; left: 50%; margin: auto; color: #ff4157; border-radius: 50%; text-align: center; font-size: 32px; padding: 13px; z-index: 9; transform: translate(-50%, -50%); }
.video-btn::after { content: ""; position: absolute; z-index: 0; left: 50%; top: 50%; transform: translateX(-50%) translateY(-50%); display: block; width: 80px; height: 80px; background: rgba(255, 255, 255, 0.5); border-radius: 50%; animation: fade-border 1.2s ease-out infinite; z-index: -9; }
.video-btn:hover::after { background-color: #e6e6e6; }
.video-btn-s2 { width: 60px; height: 60px; display: inline-block; position: relative; background: #ff4157; padding: 0; line-height: 63px; transform: translate(-50%, 0); }
.video-image-s2 .video-btn-s2 { position: absolute; }
.video-btn-s2 .icon { color: #ffffff !important; padding-left: 3px; transition: all 0.5s; }
.video-btn-s2:after { display: none; }
.video-btn-s2:hover { background: #ffffff; }
.video-btn-s2:hover .icon { color: #ff4157 !important; }
.video-btn-s3 { padding: 0; }
.video-btn-s3 .icon { color: #ffffff !important; width: 110px; height: 110px; background: #ff4157; line-height: 110px; border-radius: 50%; }
.video-btn-s3:after { width: 115px; height: 115px; }
.video-blocks iframe { min-height: 300px; width: 100%; }

@keyframes fade-border { 0% { transform: translateX(-50%) translateY(-50%) scale(1); opacity: 1; }
  100% { transform: translateX(-50%) translateY(-50%) scale(1.5); opacity: 0; } }
.text-box { line-height: .8; }
.text-box h2 { color: #131313; margin-bottom: 10px; }
.text-box h3 { color: #131313; }
.text-box h4 + p { margin-top: 13px; }
.text-box h5 { margin-bottom: 13px; }
.text-box p:not(.lead) { line-height: 1.625; }
.text-box p:last-child { margin-bottom: 0; }
.text-box-lg h2 { font-size: 1.5rem; color: #4a4a4a; font-family: "Roboto", sans-serif; }
.text-block h2 { margin-bottom: 13px; }
.text-block h2 + .lead { margin-top: 13px; margin-bottom: 0; }
.text-block h2 + p { margin-top: -7px; }
.text-block h2 + .btn { margin-top: 5px; }
.text-block p + p { margin-top: 10px; margin-bottom: 0; }
.text-block p { margin-bottom: 0; }
.text-block p + .btn { margin-top: 23px; }
.text-block-s3 h2 { font-size: 1.6rem; margin-bottom: 35px; }
.text-block-s3 p + p { margin-top: 30px !important; }
.text-block-s3 .about-btn-creative { padding-top: 30px; }
.text-block-head { margin: 30px 0 20px; }
.text-block-head h2 { margin-bottom: 10px !important; color: #000000; }
.text-bg-primary { background: #ff4157; }

@media (min-width: 576px) { .text-block-s3 h2 { font-size: 1.8rem; } }
@media (min-width: 768px) { .video-btn-s2 { width: 80px; height: 80px; line-height: 83px; } }
.text-block-head + h2 { color: #4a4a4a; }

@media (min-width: 992px) { .text-box-ml { margin-left: 15px; }
  .text-box-ml-2x { margin-left: 30px; }
  .text-block-s3 h2 { font-size: 2rem; margin-bottom: 35px; }
  .text-block-s3 p + p { margin-top: 30px !important; }
  .text-block-s3 .about-btn-creative { padding-top: 30px; }
  .text-block-head { margin: 60px 0 30px; }
  .text-block-head h2 { margin-bottom: 10px !important; color: #000000; }
  .text-block-head + h2 { color: #4a4a4a; } }
@media (min-width: 1200px) { .text-block h2 + .lead { margin-top: 13px; margin-bottom: 0; }
  .text-block p + p { margin-top: 10px; margin-bottom: 0; }
  .text-block p + .btn { margin-top: 23px; }
  .text-box-lg h2 { font-size: 2.28rem; }
  .text-block-s3 h2 { font-size: 2.33rem; margin-bottom: 35px; }
  .text-block-s3 p + p { margin-top: 30px !important; }
  .text-block-s3 .about-btn-creative { padding-top: 30px; } }
.box-pad { padding: 30px; }
.box-pad-sm { padding: 20px; }
.box-image { max-width: 100%; }

.career-box { height: 100%; }
.section-career .career-box { max-width: 400px; margin: 0 auto; }

@media (min-width: 576px) { .section-career .career-box { max-width: 100%; } }
@media (min-width: 768px) { .box-pad { padding: 30px; }
  .pad-md { padding: 30px; } }
@media (min-width: 992px) { .box-pad { padding: 40px; }
  .box-pad-md { padding: 30px; } }
@media (min-width: 1200px) { .box-pad { padding: 50px 40px 50px; }
  .box-pad-s2 { padding: 40px 50px; } }
@media (min-width: 1600px) { .box-pad-md { padding: 38px 40px; } }
.heading-xs { font-size: 14px; font-weight: 500; font-family: "Poppins", sans-serif; color: #131313; text-transform: uppercase; margin-bottom: 13px; letter-spacing: 1.4px; line-height: .8; }
.tc-light-dark .heading-xs, .tc-grey-alt .heading-xs { color: inherit; }
.tc-grey .heading-xs { color: #4a4a4a; }
.tc-grey-alt .heading-xs { color: #4b4b4b; }
.bg-darker .heading-xs, .tc-light .heading-xs { color: rgba(255, 255, 255, 0.7) !important; }
.tc-bunker .heading-xs:not(.tc-primary) { color: rgba(255, 255, 255, 0.7) !important; }
.heading-xs.tc-primary { color: #ff4157 !important; }
.bg-ocean-pattern .heading-xs.tc-primary { color: #ff4157 !important; }
.heading-theme { color: #ff4157 !important; }
.tc-bunker .heading-theme:not(.tc-primary) { color: #ff4157 !important; }
.bg-ocean-pattern .tc-bunker .heading-theme:not(.tc-primary) { color: #ff4157 !important; }

.dash { padding-left: 35px; padding-right: 35px; position: relative; display: inline-block; }
.dash::before, .dash::after { position: absolute; content: ''; width: 20px; height: 2px; background: #ff4157; left: 0; top: 0; bottom: 0; margin: auto 0; }
.dash::after { left: auto; right: 0; }

.no-dash { padding: 0; }
.no-dash::before, .no-dash::after { display: none; }

.dash-r { padding-left: 0; padding-right: 35px; }
.dash-r::before { right: 0; left: auto; }
.dash-r::after { display: none; }

.dash-both { padding-right: 35px; padding-left: 35px; }
.dash-both::after { left: auto; right: 0; }

@media (min-width: 768px) { .dash::after { left: 0; right: auto; }
  .dash-both::after { left: auto; right: 0; } }
.section-head { margin-bottom: 38px; line-height: .8; }
.section-head .heading-xs { margin-bottom: 13px; }
.section-head h2 { margin-bottom: 15px; }
.section-head p + a { margin-top: 10px; }
.section-head h2 + a { margin-top: 10px; }
.section-head p { line-height: 1.6; }
.section-head-col { padding-bottom: 0; margin-bottom: 15px; }
.section-head-col h2 { margin-bottom: 0; }
.section-head-col h2 + p { padding-top: 11px; }
.section-head-col p + .btn { margin-top: 4px; }
.section-head-s2 h2 { font-size: 1.5rem; line-height: 1.25; font-weight: 900; margin-bottom: 30px; }
.section-head-s3 h2 { font-size: 1.3rem; }
.section-head-s5 h2 { font-size: 1.6rem; }

.text-block-s2 h2, .section-head-s4 h2 { font-size: 1.5rem; }

@media (min-width: 576px) { .section-head-s2 h2 { font-size: 2rem; }
  .section-head-s5 h2 { font-size: 1.8rem; } }
@media (min-width: 768px) { .section-head-s2 h2 { font-size: 1.8rem; }
  .section-head-s3 h2 { font-size: 1.875rem; }
  .text-block-s2 h2, .section-head-s4 h2 { font-size: 1.875rem; } }
@media (min-width: 992px) { .section-head { margin-bottom: 30px; }
  .section-head p + a { margin-top: 10px; }
  .section-head-col { margin-bottom: 0; }
  .section-head-col { margin-bottom: 33px; }
  .section-head-s2 h2 { font-size: 2.8rem; }
  .section-head-s3 h2 { font-size: 2.25rem; line-height: 1.667; }
  .section-head-s4 h2 { font-size: 2.25rem; line-height: 1.35; }
  .section-head-s5 h2 { font-size: 2rem; }
  .section-sm { margin-bottom: 50px; }
  .section-md { margin-bottom: 68px; }
  .section-lg { margin-bottom: 90px; } }
@media (min-width: 1200px) { .section-head-s2 h2 { font-size: 4rem; }
  .section-head-s5 h2 { font-size: 2.33rem; } }
.social li { display: inline-block; }
.social li:not(:last-child) { margin-right: 15px; }
.social li a { font-size: 18px; color: #ffffff; border: 1px solid #ffffff; border-radius: 50%; text-align: center; padding: 14px 0; background: transparent; }
.social li a:hover { background: #1a5ff8; color: #ff4157; border-color: #1a5ff8; }
.bg-primary .social li a:hover { color: #ffffff; }
.social li[class*='fa-'] { width: 50px; height: 50px; }
.social-alt { max-width: 100%; padding: 20px 0; }
.social-alt li:not(:last-child) { margin-bottom: 20px; margin-right: 20px; }
.social-alt li a { width: auto; height: auto; padding: 0; border: transparent; font-size: 14px; }
.social-alt li a:hover { background: transparent; }
.social-style-icon li a { width: auto; height: auto; border: none; background: transparent; }
.social-style-icon li a:hover { background: transparent; }
.social-s3 li:not(:last-child) { margin-right: 10px; }
.social-s3 li a { font-size: 18px; }
.tc-bunker .social-s3 li a { color: #a2a9bf; }
.tc-bunker .social-s3 li a:hover { color: #ff4157; }
.social-s2 li { margin-right: 0; }
.social-s2 li a { border: none; color: #4a4a4a; }
.tc-bunker .social-s2 li a { color: #a2a9bf; }
.social-s2 li a:hover { background: transparent; }
.social-s2 li:last-child a { padding-right: 0; }
.social-s4 { position: relative; padding-left: 20px; margin-left: 0; }
.menu-mobile .social-s4 { margin-bottom: 20px; }
.social-s4 li:not(:last-child) { margin-right: 0; }
.social-s4 li a { border: none; width: 40px; height: 40px; line-height: 40px; font-size: 1rem; padding: 0; }
.menu-mobile .social-s4 li a { color: #131313; }
.social-s4 li a:hover { background: transparent; }
.social-s4:before { position: absolute; content: ''; left: 0; bottom: 0; width: 1px; height: 18px; background: #51507e; margin: auto 0; top: 0; }
.social-s5 { margin: 0 -15px; }
.social-s5 li { display: block; padding: 0 15px; }
.social-s5 li:not(:last-child) { text-align: left !important; margin-right: 0; }
.social-s5 li a { border: none; font-size: 14px; padding: 0; display: block; text-align: left; padding: 5px 0; }
.social-s5 li a .icon { width: 30px; height: 30px; border: none; background: #3f20b0; line-height: 30px; padding: 0; display: inline-block; border-radius: 50%; color: #ffffff; font-size: 16px; transition: all 0.5s; text-align: center; }
.social-s5 li a span { padding-left: 13px; }
.social-s5 li a:hover { background: transparent; color: #ff4157 !important; }
.bg-ocean-pattern .social-s5 li a:hover { color: #ff4157 !important; }
.social-s5 li a:hover .icon { background: #ff4157 !important; }
.social-s5-alt { margin-left: -6px; }
.social-s5-alt li { display: inline-block; margin: 0 6px; }
.social-s5-alt li:not(:last-child) { margin-right: 0; }
.social-s5-alt li a .icon { width: 40px; height: 40px; line-height: 40px; }

.fac { background-color: #5678bf; border-color: #5678bf; }
.fac:hover { background: #7b96cd; }

.twi { background: #73c2fd; border-color: #73c2fd; }
.twi:hover { background: #7b96cd; }

.pin { background: #c32026; border-color: #c32026; }
.pin:hover { background: #7b96cd; }

.goo { background: #dd4b39; border-color: #dd4b39; }
.goo:hover { background: #7b96cd; }

@media (min-width: 576px) { .social-alt { max-width: 20px; }
  .social-alt li { display: block; }
  .social-alt li:not(:last-child) { margin-bottom: 30px; }
  .social-alt li a { font-size: 18px; }
  .social-s2 li:not(:last-child) { margin-right: 9px; }
  .social-s2 li a { font-size: 14px; } }
@media (min-width: 768px) { .social-s3 li:not(:last-child) { margin-right: 20px; }
  .social-s3 li a { font-size: 30px; }
  .social-s2 li:not(:last-child) { margin-right: 15px; }
  .social-s2 li a { font-size: 18px; } }
@media (min-width: 992px) { .social li:not(:last-child) { margin-right: 10px; text-align: center; }
  .social-s2 li:not(:last-child) { margin-right: 18px; }
  .social-s4 { margin-left: 20px; }
  .social-s4 li:not(:last-child) { margin-right: 0; }
  .social-s4 li a { width: 26px; }
  .social-style-icon li:not(:last-child) { margin-right: 30px; }
  .social-alt li { text-align: center; }
  .social-alt li:not(:last-child) { margin-right: 0; }
  .social-s5 li:not(:last-child) { margin-right: 4px; } }
@media (max-width: 575px) { .social-s5 { display: flex; flex-wrap: wrap; }
  .social-s5 li { width: 50%; } }
.slick-arrow { position: absolute; transform: translateY(-50%); top: 50%; width: 40px; height: 40px; border-radius: 50%; font-size: 18px; text-align: center; padding-top: 7px; cursor: pointer; background: #0a1015; z-index: 1; opacity: .4; display: none !important; }
.slick-arrow i { color: #ffffff; }
.tes-arrow-s1 .slick-arrow { display: block !important; }

.slick-prev { left: 30px; }

.slick-next { right: 30px; }

.slick-dots { margin-top: 24px; text-align: center; line-height: .8; }
.slick-dots li { display: inline-block; width: 8px; height: 8px; border-radius: 50%; background: #e4eaed; cursor: pointer; margin: 0 6px; }
.slick-dots li button { display: none; }
.slick-dots li.slick-active { background: #ff4157; }
.bg-ocean-pattern .slick-dots li.slick-active { background: #ff4157 !important; }

@media (min-width: 768px) { .slick-arrow { display: inline-block !important; }
  .tes-s5 .slick-dots { text-align: left; margin-left: -4px; margin-top: 34px; }
  .tes-s5 .slide-dot-center .slick-dots { text-align: center; } }
@media (min-width: 992px) { .has-slider:hover .slick-arrow { opacity: 0.4; display: block !important; }
  .has-slider:hover .slick-arrow.slick-next:hover { opacity: 0.8; }
  .has-slider:hover .slick-arrow.slick-prev:hover { opacity: 0.8; } }
@media (min-width: 1200px) { .slick-arrow { width: 50px; height: 50px; font-size: 24px; }
  .slick-next { right: 20px; }
  .slick-prev { left: 20px; } }
.section-about .box-pad { padding: 30px 0; }
.section-about .feature-icon { font-size: 40px; }
.section-about .feature-content { margin-left: 15px; }

@media (min-width: 768px) { .section-about .box-pad { padding: 30px 15px; }
  .section-about .feature-icon { font-size: 49px; }
  .section-about .feature-content { margin-left: 20px; } }
@media (min-width: 992px) { .section-about .box-pad { padding: 40px; } }
.scroll h6 { font-size: 1rem; margin-bottom: 25px; }

.list { padding-top: 5px; }
.list li { padding-left: 20px; position: relative; color: #4b4b4b; padding-bottom: 4px; line-height: 1.5; }
.list li:last-child { padding-bottom: 0; }
.tc-light .list li { color: #ffffff; }
.tc-light .list li::before { background: #ffffff; }
.tc-bunker .list li { color: #a2a9bf; }
.list li::before { width: 6px; height: 6px; border-radius: 50%; background: #ff4157; content: ""; position: absolute; left: 0; top: 9px; margin: auto 0; }
.bg-ocean-pattern .list li::before { background: #ff4157; }
.list-s2 li { padding-bottom: 10px; }

.style-boxed li { padding-left: 24px; }
.style-boxed li::before { width: 8px; height: 8px; border-radius: 0; left: 3px; top: 8px; }
.style-boxed li::after { width: 14px; height: 14px; content: ""; background: rgba(255, 65, 87, 0.3); position: absolute; left: 0; top: 5px; margin: auto; }
.style-boxed li:not(:last-child) { margin-bottom: 0px; }

@media (max-width: 768px) { .res-m-btm { margin-bottom: 20px; } }
.preloader { position: fixed !important; top: 0; left: 0; right: 0; bottom: 0; z-index: 10000; }
.preloader:before, .preloader:after { position: absolute; left: 0; height: 51%; width: 100%; transition: all .6s ease .3s; background: #fff; content: ''; }
.preloader:before { top: 0; }
.page-loaded .preloader:before { transform: translate(0, -100%); }
.preloader:after { bottom: 0; }
.page-loaded .preloader:after { transform: translate(0, 100%); }
.preloader.preloader-dalas:before, .preloader.preloader-dalas:after { background: #6838ca; }
.preloader.preloader-florida:before, .preloader.preloader-florida:after { background: #6838ca; }
.preloader.preloader-texas:before, .preloader.preloader-texas:after { background: #303150; }
.preloader.preloader-jackson:before, .preloader.preloader-jackson:after { background: #010103; }
.preloader.preloader-alaska-light:before, .preloader.preloader-alaska-light:after { background: #f9f4f1; }
.preloader.preloader-alaska-dark:before, .preloader.preloader-alaska-dark:after { background: #0E161D; }
.preloader.preloader-helena:before, .preloader.preloader-helena:after { background: #230985; }
.page-loaded .preloader.no-split:before, .page-loaded .preloader.no-split:after { transform: translate(0, 0); }

.spinner { display: block; position: relative; left: 50%; top: 50%; width: 70px; height: 70px; margin: -35px 0 0 -35px; z-index: 1001; }
.spinner-round { border-radius: 50%; border: 2px solid transparent; border-top-color: #ff4157; animation: spin 2s linear infinite; }
.spinner-round:before, .spinner-round:after { content: ""; position: absolute; border-radius: 50%; border: 3px solid transparent; }
.spinner-round:before { top: 5px; left: 5px; right: 5px; bottom: 5px; border-top-color: #1a5ff8; animation: spin 3s linear infinite; }
.spinner-round:after { top: 15px; left: 15px; right: 15px; bottom: 15px; border-top-color: #ff4157; animation: spin 1.5s linear infinite; }
.spinner-alt { border-radius: 50%; width: 150px; height: 150px; margin: -75px 0 0 -75px; }
.spinner-alt:before, .spinner-alt:after { content: ""; position: absolute; border-radius: 50%; top: 0; left: 0; right: 0; bottom: 0; }
.spinner-alt:before { border: 1px solid rgba(255, 255, 255, 0.1); }
.spinner-alt:after { border: 1px solid; border-top-color: #fff; border-right-color: transparent; border-left-width: 0; border-bottom-width: 0; animation: spin 1.5s linear infinite; }
.spinner-alt.load-done { transition: all .4s; transform: scale(3); opacity: 0; border-color: transparent; }
.spinner-alt.load-done:before, .spinner-alt.load-done:after { opacity: 0; }
.spinner-alt.spinner-light { border: 1px solid rgba(10, 16, 21, 0.1); }
.spinner-alt.spinner-light::after { border-top-color: #0a1015; }
.spinner-brand { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: 100px; }

@keyframes spin { 0% { -webkit-transform: rotate(0deg); -ms-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); -ms-transform: rotate(360deg); transform: rotate(360deg); } }
.bg-ocean-pattern { background: #230985 url(../images/body-pattern.png); background-size: 960px; background-repeat: repeat-y; background-position: 50% 0; }
.bg-ocean-dark { background-color: #230985 !important; }
.bg-ocean-dark-alt { background-color: #0f0052 !important; }
.bg-ocean-light { background-color: #3315a4 !important; }

@media (min-width: 768px) { .bg-ocean-pattern { background-size: 1080px; } }
@media (min-width: 1200px) { .bg-ocean-pattern { background-size: 1280px; } }
@media (min-width: 1600px) { .bg-ocean-pattern { background-size: 1920px; } }
.title-sm { font-size: 1rem; }

.gshape { background: url(../images/shape-gradient.png); background-size: contain; background-repeat: no-repeat; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); opacity: 0.15; }
.gshape-sm { width: 50px; height: 65px; transform: translate(-50%, -50%) rotate(-55deg); transform-origin: left; }
.gshape-sm-alt { transform: translate(-50%, -50%) scaleX(-1); }
.gshape-md { width: 112px; height: 147px; }
.gshape-md-alt { transform: translate(-50%, -50%) scaleY(-1) rotate(-55deg); }
.gshape-md-alt-y { transform: translate(-50%, -50%) scale(-1) rotate(-57deg); }
.gshape-md-alt-x { transform: translate(-50%, -50%) scaleX(-1); }
.gshape-md-s1 { width: 90px; height: 120px; }
.gshape-md-s2 { width: 100px; height: 120px; }
.gshape-lg { width: 144px; height: 180px; }
.gshape-lg-alt { transform: translate(-50%, -50%) scaleY(-1) rotate(-55deg); }
.gshape-lg-alt-y { transform: translate(-50%, -50%) scale(-1) rotate(-57deg); }
.gshape-lg-alt-x { transform: translate(-50%, -50%) scaleX(-1); }
.gshape-xl { width: 55%; height: 70%; opacity: 1; }
.image-block-s3 .gshape-xl { width: 62%; height: 88%; }
.image-block-sm .gshape-xl { width: 100%; height: 88%; }
.gshape-xl-alt { transform: translate(-50%, -50%) scaleX(-1) rotate(0); z-index: -1; }
.gshape-wrapper { max-width: 1200px; margin: auto; }
.gshape-block { bottom: -27px; transform: translate(0, 0); top: auto; left: auto; right: -10%; }
.gshape-block-alt { right: auto; left: -18%; transform: translate(0, 0) scaleX(-1) rotate(0); }

.nshape { background: url(../images/shape-net.png); background-size: contain; background-repeat: no-repeat; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); opacity: 1; z-index: -1; }
.nshape-lg { width: 499px; height: 279px; transform: translate(-50%, -50%) scale(0.5); }
.nshape-lg-alt { width: 570px; height: 370px; transform: translate(-50%, -50%) rotate(-60deg) scale(0.5); z-index: 3; }
.nshape-lg-alt-s2 { width: 570px; height: 370px; transform: translate(-50%, -50%) rotate(-30deg) scale(0.5); }
.nshape-lg-alt-s3 { width: 600px; height: 400px; background: url(../images/shape-net-light.png); background-size: contain; background-repeat: no-repeat; transform: translate(-50%, -50%) rotate(-135deg) scale(0); z-index: 3; }
.nshape-lg-alt-s4 { transform: translate(-50%, -50%) rotate(18deg) scale(0); }

@media (min-width: 576px) { .gshape-lg { opacity: 0.15; }
  .gshape-xl-alt { transform: translate(-50%, -50%) scaleX(-1) rotate(0); z-index: -1; } }
@media (min-width: 992px) { .gshape-block { bottom: -10%; transform: translate(0, 0); top: auto; left: 72%; }
  .banner-inner-lg .gshape-block { bottom: -13%; }
  .title-sm { font-size: 1.125rem; }
  .nshape-lg { transform: translate(-50%, -50%) scale(0.8); }
  .nshape-lg-alt { transform: translate(-50%, -50%) rotate(-60deg) scale(0.8); }
  .nshape-lg-alt-s2 { transform: translate(-50%, -50%) rotate(-30deg) scale(0.8); }
  .nshape-lg-alt-s3 { transform: translate(-50%, -50%) rotate(-135deg) scale(0.6); }
  .nshape-lg-alt-s4 { transform: translate(-50%, -50%) rotate(18deg) scale(0.6); } }
@media (min-width: 1200px) { .body-boxed { padding: 50px; }
  .gshape { opacity: 1; }
  .gshape-wrapper { opacity: 1; }
  .nshape-lg { transform: translate(-50%, -50%) scale(0.8); }
  .nshape-lg-alt { transform: translate(-50%, -50%) rotate(-60deg) scale(0.8); }
  .nshape-lg-alt-s2 { transform: translate(-50%, -50%) rotate(-30deg) scale(0.8); }
  .nshape-lg-alt-s3 { transform: translate(-50%, -50%) rotate(-135deg) scale(0.8); }
  .nshape-lg-alt-s4 { transform: translate(-50%, -50%) rotate(18deg) scale(0.8); } }
@media (min-width: 1400px) { .nshape-lg { transform: translate(-50%, -50%) scale(1); }
  .nshape-lg-alt { transform: translate(-50%, -50%) rotate(-60deg) scale(1); }
  .nshape-lg-alt-s2 { transform: translate(-50%, -50%) rotate(-30deg) scale(1); }
  .nshape-lg-alt-s3 { transform: translate(-50%, -50%) rotate(-135deg) scale(1); }
  .nshape-lg-alt-s4 { transform: translate(-50%, -50%) rotate(18deg) scale(1); } }
.shadow-light { box-shadow: 0px 5px 45px 0px rgba(10, 16, 21, 0.08); }

.icon { color: #ff4157; }
.bg-ocean-pattern .icon { color: #ff4157; }
.icon-alt { color: #1a5ff8; }
.icon-lg { font-size: 70px; }
.icon-md { font-size: 60px; }
.icon-xl { font-size: 90px; }
.icon-shape { background: url(../images/icon-shape.png) 0 0 no-repeat; background-size: contain; }
.icon-shape-alt { background: url(../images/icon-shape-alt.png) 0 0 no-repeat; }

.font-italic { font-style: italic !important; }
.font-normal { font-style: normal !important; }

.nk-block + .nk-block { margin-top: 30px; }
.nk-block + .nk-block-xl { margin-top: 40px; }
.nk-block-feature { padding: 1px; }

.body-grad { background-image: linear-gradient(to left, #ebf1fc 0%, #faf4f0 100%); }

.bdr-theme { border-color: #ffdade; }
.bdr-theme-alt { border-color: rgba(226, 232, 235, 0.08); }

.pdtl-90 { padding-top: 0px; padding-left: 0px; }
.pdtl-90 div:not(:last-child) { padding-bottom: 0; }

@media (max-width: 767px) { .pdtl-90 div:not(:last-child) { padding-bottom: 30px; } }
@media (min-width: 320px) and (max-width: 479px) { .col-full { max-width: 100%; flex: 0 0 100%; } }
@media (min-width: 1200px) { .ml-reverse { margin-left: -200px; } }
@media (min-width: 768px) { .pdtl-90 { padding-top: 30px; padding-left: 30px; } }
@media (min-width: 992px) { .ml-reverse { margin-left: -170px; }
  .mt-top { margin-top: -115px; }
  .nk-block + .nk-block { margin-top: 100px; }
  .nk-block + .nk-block-xl { margin-top: 120px; }
  .pdtl-90 { padding-top: 90px; padding-left: 90px; } }
@media (min-width: 1200px) { .container-xl-full { max-width: 100%; padding-left: 0px; padding-right: 0px; }
  .social-s4 li a { width: 40px; } }
@media (min-width: 1200px) and (max-width: 1360px) { .body-boxed { padding: 30px; }
  .container-lg-custom { max-width: 960px; }
  .container-xl-full { max-width: 100%; }
  .section-head-s2 h2 { font-size: 3.2rem; }
  .quote-block { padding: 40px 40px 45px 40px !important; }
  .box-pad { padding: 30px; } }
@media (min-width: 1200px) and (max-width: 1400px) { .gshape-block { left: 62%; } }
@media (min-width: 1400px) { .container-xl-full-pd { max-width: 100%; padding-left: 80px; padding-right: 80px; } }
.title-light { font-weight: 300; }
.title-regular { font-weight: 400; }
.title-bold { font-weight: 700; }
.title-black { font-weight: 800; }
.title-semibold { font-weight: 600; }

.text-box-s2 { padding-top: 30px; }
.text-box-s2 p strong { width: 140px; display: inline-block; padding-right: 40px; color: #ffffff; }

.bg-light-blue { background: #71ffdf; }

.bg-orange { background: #fec08f; }

.bg-blue { background: #8571ff; }

.bg-purple { background: #ac4de2; }

.bg-green { background: #71ff71; }

.bg-dark-blue { background: #2746e5; }

.bg-dark-s3 { background: #262626; }

.border-full { margin: 0; border-top: 2px solid #333; }
.container .border-full { border-top: 1px solid #333; }

.b12 { margin-top: -12%; }

.shapes { position: relative; width: 100%; height: 100%; }

.shape { background: #ffdbde; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%) scale(0.5); }
.shape:before { position: absolute; content: ""; left: -10px; top: -10px; width: 100%; height: 100%; border: 3px solid #000; }
.shape-circle { width: 143px; height: 143px; border-radius: 50%; }
.shape-circle:before { border-radius: 50%; }
.shape-square { width: 96px; height: 96px; }
.shape-rectangle { width: 280px; height: 90px; background: rgba(172, 77, 226, 0.2); }
.shape-triangle { width: 137px; height: 137px; background: transparent; }
.shape-triangle:before { display: none; }
.shape-triangle .fill { fill: rgba(172, 77, 226, 0.2); }
.shape-triangle .stroke { stroke: #000000; stroke-width: 3px; fill: transparent; }
.shape-down { position: relative; }
.shape-up { position: absolute; left: -6px; top: -4px; }

@media (min-width: 1200px) { .shape { transform: translate(-50%, -50%) scale(0.7); } }
@media (min-width: 1400px) { .shape { transform: translate(-50%, -50%) scale(1); } }
.pdb-60 { padding-bottom: 60px !important; }

/** 02.05 Animate */
.animate { position: relative; animation-fill-mode: both; }

@keyframes fade-in-up { from { top: 50px; opacity: 0; }
  to { top: 0; opacity: 1; } }
.slick-active .fade-in-up { animation: fade-in-up .8s; }

@keyframes fade-in-bottom { from { bottom: 50px; opacity: 0; }
  to { bottom: 0; opacity: 1; } }
.slick-active .fade-in-bottom { animation: fade-in-bottom .8s; }

@keyframes fade-in-left { from { right: 50px; opacity: 0; }
  to { right: 0; opacity: 1; } }
.slick-active .fade-in-left { animation: fade-in-left .8s; }

@keyframes fade-in-right { from { left: 50px; opacity: 0; }
  to { left: 0; opacity: 1; } }
.slick-active .fade-in-right { animation: fade-in-right .8s; }

/** 03. Layouts */
/** 03.01 Header */
.nk-header { position: relative; z-index: 10; }

.header-box { position: relative; }
.header-main { padding: 12px 0px; transition: all .4s; position: absolute; width: 100%; z-index: 9; left: 0; }
.is-transparent .header-main, .has-fixed .header-main { width: 100%; z-index: 49; }
.is-transparent .header-main { position: absolute !important; }
.header-s1 .header-main { background: #ffffff; }
.bg-dark-alt .header-s1 .header-main { background: #0a1015; }
.bg-dark-alt .has-fixed .header-main { background: #121d26; }
.bg-dark .header-s2.has-fixed .header-main { background: #111b23; }
.has-fixed .header-main { position: fixed !important; box-shadow: 0 0 20px 0 rgba(10, 16, 21, 0.08); background: #ffffff; top: 0; }
.bg-ocean-pattern .has-fixed .header-main { background: #1b0768; }
.has-fixed.is-shrink .header-main { padding-top: 8px; padding-bottom: 8px; }
.is-boxed .header-main { padding: 12px 15px; z-index: 9; }
.is-boxed.has-fixed.is-shrink .header-main { padding: 12px 15px; }
.is-boxed-md .header-main { padding-right: 15px; padding-left: 15px; }
.header-main-creative { padding: 0; }
.has-fixed.is-shrink .header-main-creative { padding: 0; }
.header-container:not(.container) { width: 100%; padding: 0 15px; }
.header-wrap { position: relative; width: 100%; display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap; }
.header-logo { margin: 6px 0; flex-shrink: 0; }
.header-navbar { position: fixed; left: -280px; top: 0; width: 260px; height: 100vh; }
.header-navbar.menu-mobile { transition: all 0.4s; display: block; }
.header-navbar::before, .header-navbar-overlay { position: absolute; height: 100%; width: 100%; content: ''; top: 0; }
.header-navbar::before { background: #fff; left: 50%; transform: translateX(-50%); transition: all .4s; }
.is-dark .header-navbar::before { background: #3370f9; }
.bg-dark-alt .header-navbar::before { background: #0c1318; }
.bg-dark .header-navbar::before { background: #0c1318; }
.bg-ocean-pattern .header-navbar::before { background: #1b0768; }
.header-navbar-overlay { background: rgba(18, 27, 34, 0.7); left: 100%; width: 0; z-index: -1; opacity: 0; visibility: hidden; transition: opacity .3s; transition-delay: 0.2s; }
.header-navbar.menu-shown { left: 0 !important; }
.header-navbar.menu-shown .header-navbar-overlay { width: 100vw; opacity: 1; visibility: visible; }
.header-navbar-classic { position: absolute; top: 99%; right: 0; left: 0; width: 100%; height: auto; background: transparent; margin-top: 12px; z-index: auto; flex-grow: 1; transition: all 0.4s; padding: 0 20px; }
.has-fixed .header-navbar-classic { margin-top: -6px; padding: 0; }
.has-fixed .header-navbar-classic:before { width: 100vw; border-radius: 0; }
.header-menu { justify-content: flex-end; padding: 30px 25px; }
.header-navbar-classic .header-menu { padding: 0; }

.banner { min-height: 72px; }
.is-boxed .banner { padding-left: 15px; padding-right: 15px; }

@media (min-width: 375px) { .header-navbar:not(.header-navbar-classic) { left: -310px; width: 290px; } }
@media (min-width: 576px) { .header-navbar:not(.header-navbar-classic) { left: -360px; width: 340px; }
  .is-boxed .header-main { padding: 12px 40px; }
  .is-boxed.has-fixed.is-shrink .header-main { padding: 8px 40px; }
  .is-boxed .banner { padding-left: 40px; padding-right: 40px; } }
.logo-dark, .is-transparent:not(.has-fixed) .logo-light, .is-dark .logo-light, .bg-dark-alt .logo-light { display: block; }

.logo-light, .is-transparent:not(.has-fixed) .logo-dark, .is-dark .logo-dark, .bg-dark-alt .logo-dark { display: none; }

.header-s2 .logo-light { display: block; }
.header-s2 .logo-dark { display: none; }

.logo img { height: 36px; transition: all .4s; }
.logo-md img { height: 44px; }
.logo-lg img { height: 52px; }

@media (min-width: 992px) { .header-navbar:not(.header-navbar-classic) { height: auto; width: auto; left: 0; }
  .header-navbar-s2 { flex-grow: 1; }
  .header-navbar-s2 .menu { margin-left: auto; margin-right: auto; }
  .header-navbar-s2 .menu-btns { padding: 0; margin-left: 0; }
  .header-navbar-s2 .menu-btns::before { display: none; }
  .header-navbar-s3 { flex-grow: 1; }
  .header-main { padding: 20px 0; }
  .is-boxed .header-main { padding: 12px 40px; }
  .is-boxed.has-fixed.is-shrink .header-main { padding: 8px 40px; }
  .header-main-creative { padding: 0; }
  .header-nav-toggle { display: none; }
  .header-container:not(.container) { padding: 0 25px; }
  .header-banner { min-height: 98px; }
  .is-boxed .header-box .banner { padding: 0 40px; }
  .logo img { height: 40px; }
  .logo-md img { height: 50px; }
  .logo-lg img { height: 60px; } }
@media (min-width: 1200px) { .logo img { height: auto; } }
.header-menu { max-height: 100vh; overflow: hidden; overflow-y: auto; }
.header-navbar-classic .header-menu { display: none; max-height: 65vh; }
.header-navbar-classic .header-menu.menu-shown { display: block; }
.header-nav-toggle { height: 44px; }

@media (min-width: 1280px) { .header-main { padding: 20px 0; }
  .is-boxed .header-main { padding: 20px 40px; }
  .header-s1.has-fixed.is-shrink .header-main { padding: 12px 40px; top: 0; }
  .is-boxed .header-box .banner { padding: 0 40px; } }
@media (min-width: 1600px) { .is-boxed .header-main { padding: 30px 140px; }
  .is-boxed.has-fixed.is-shrink .header-main { padding: 20px 140px; }
  .is-boxed .header-main-s2, .is-boxed.has-fixed.is-shrink .header-main-s2 { padding: 25px 80px; }
  .is-boxed-md .header-main { padding-right: 80px; padding-left: 80px; }
  .is-boxed .header-box .banner { padding: 0 140px; } }
.header-100vh { height: 100vh; }

.header-sidebar { display: flex; justify-content: space-between; padding: 20px 15px; background: #000000; align-items: center; }
.header-sidebar:focus { background: #ffffff; }
.header-sidebar .header-logo { margin: 0; }
.header-contact { color: #ffd0d5; }
.header-contact ul { padding-bottom: 15px; }
.header-contact ul li { line-height: 2; }
.header-contact ul li a { font-size: 24px; display: inline-block; color: #ffffff; font-weight: 700; position: relative; }
.header-contact ul li a:after { position: absolute; left: 0; bottom: 5px; height: 2px; width: 100%; background: currentColor; content: ""; }

.header-navbar-creative::before { background: #ff4157; }
.header-social li { display: inline-block; }
.header-social li a { padding: 0 2.5px; }

.header-main-creative .header-nav-toggle { height: 36px; }
.header-main-creative .toggle-line-s2 { width: 36px; }

@media (min-width: 576px) { .body-mgl { margin-left: 75px !important; }
  .header-main-creative { padding: 0 !important; }
  .header-navbar-creative { background: transparent; height: 100vh !important; width: 100% !important; position: fixed !important; align-items: center; left: 75px !important; opacity: 0; visibility: hidden; z-index: 1; transition: all .5s ease 0.5s; display: flex; }
  .header-navbar-creative:after { content: ""; left: 0; top: 0; width: 0; height: 100%; position: absolute; background: #ff4157; z-index: -1; transition: all 0.5s; }
  .header-navbar-creative::before { display: none; }
  .header-navbar-creative.menu-shown { transition: all 0s ease 0s; opacity: 1; visibility: visible; left: 75px !important; }
  .header-navbar-creative.menu-shown:after { width: 100%; }
  .header-navbar-creative .header-menu { width: 100% !important; }
  .header-sidebar { width: 75px; background: #000000; height: 100%; position: fixed; color: #fff; left: 0; top: 0; display: flex; flex-direction: column; justify-content: center; text-align: center; padding: 50px 0; transition: all 0.3s; }
  .header-sidebar .toggle-line-s2::after, .header-sidebar .toggle-line-s2::before, .header-sidebar .toggle-line-s2 > span { background: rgba(255, 255, 255, 0.6); }
  .header-navbar-creative { padding-left: 0 !important; }
  .header-social li { display: block; }
  .header-social li a { font-size: 14px; color: rgba(255, 255, 255, 0.6); padding: 5px 9px; transition: 0.3s; }
  .header-social li a:hover { color: #ff4157; }
  .header-sidebar.shown .header-social li a { color: #ff4157; }
  .header-sidebar.shown .header-social li a:hover { color: rgba(0, 0, 0, 0.6); }
  .header-nav-creative { display: block; }
  .header-navbar-overlay { display: none; }
  .header-main-creative { padding: 0; }
  .has-fixed .header-main-creative { left: 75px; }
  .header-contact { opacity: 0; transform: translateY(40px); }
  .menu-shown .header-contact { transform: translateY(0); opacity: 1; transition: all 0.7s ease 0.8s; } }
@media (min-width: 992px) { .body-mgl { margin-left: 100px !important; }
  .header-navbar-creative { left: 75px !important; }
  .header-navbar-creative.menu-shown { left: 100px !important; }
  .header-sidebar { width: 100px; }
  .has-fixed .header-main-creative { left: 100px; } }
@media (min-width: 1360px) { .header-sidebar { width: 120px; }
  .header-main-creative { padding: 0; }
  .has-fixed .header-main-creative { left: 120px; }
  .body-mgl { margin-left: 120px !important; }
  .header-navbar-creative { left: 120px !important; }
  .header-navbar-creative.menu-shown { left: 120px !important; } }
/** 03.02 Menu */
.menu { padding: 12px 0; }
.menu-btns { margin-bottom: 26px; margin-top: -10px; padding-top: 10px; display: flex; position: relative; }
.menu-btns::before { position: absolute; content: ''; left: 0; bottom: 0; width: 1px; height: 18px; background: #e5e5e5; margin: auto 0; top: 0; display: none; }
.bg-dark-alt .menu-btns::before, .bg-dark .menu-btns::before { background: #78778c; }
.is-transparent .menu-btns::before { background: #78778c; }
.is-transparent.has-fixed .menu-btns::before { background: #e5e5e5; }
.menu-btns > li { padding: 0px; }
.menu-btns > li a { padding: 12px 15px; }
.menu-btns > li .search { background: transparent; padding: 12px 0; font-size: 18px; margin-right: 10px; color: inherit; min-width: auto; border: none; display: none; }
.bg-dark-alt .menu-btns > li .search { color: #ffffff; }
.menu-btns-s2:before { display: none; }
.menu-item { position: relative; border-bottom: 1px solid rgba(219, 228, 247, 0.75); }
.is-dark .menu-item { border-color: rgba(219, 228, 247, 0.1); }
.bg-ocean-pattern .menu-item, .bg-dark-alt .menu-item, .bg-dark .menu-item { border-color: rgba(219, 228, 247, 0.08) !important; }
.bg-ocean-pattern .menu-item:first-child, .bg-dark-alt .menu-item:first-child, .bg-dark .menu-item:first-child { border-color: rgba(219, 228, 247, 0.08) !important; }
.menu-item:last-child { border-bottom: 0; }
.menu-item > ul, .menu-item .menu-sub { display: none; }
.menu-item a { font-family: "Poppins", sans-serif; font-size: 14px; line-height: 16.8px; color: #131313; padding: 12px 0; display: block; position: relative; font-weight: 600; }
.bg-dark-alt .menu-item a { color: #ffffff; }
.bg-dark .menu-item a { color: #ffffff; }
.menu-item a .badge { margin-left: 3px; padding: 3px 7px 2px; font-size: 8px; background: #ff4157; letter-spacing: 0.05em; top: -2px; font-weight: 500; }
.menu-item a .badge-version { background: #e3e3ea; }
.bg-ocean-pattern .menu-item a .badge-version { background: rgba(0, 0, 0, 0.5); }
.menu-drop .menu-item a { text-transform: capitalize; }
.menu-item a.active, .menu-item a:hover { color: #ff4157; }
.bg-ocean-pattern .menu-item a.active, .bg-ocean-pattern .menu-item a:hover { color: #ff4157; }
.menu-sub { padding-left: 12px; }
.menu-sub .menu-item:first-child { border-top: 1px solid rgba(219, 228, 247, 0.75); }
.is-dark .menu-sub .menu-item:first-child { border-color: rgba(219, 228, 247, 0.1); }
.menu-sub .menu-sub { margin-top: -5px; margin-bottom: 10px; }
.menu-sub .menu-sub .menu-item { border-bottom: none; }
.menu-sub .menu-sub .menu-item:first-child { border-top: none; }
.menu-sub .menu-sub .menu-item a { padding-top: 6px; padding-bottom: 6px; }
.menu-sub a { font-size: 13px !important; color: #131313; }
.menu-mega a { font-size: 13px; color: #131313; }
.is-dark .menu-item a { color: #fff; }
.menu-item > a.active { color: #ff4157; }

.bg-dark .is-transparent.has-fixed .menu-btns::before { background: #78778c; }

.has-sub > a { position: relative; }
.has-sub > a:after { position: absolute; right: 0; top: 50%; transform: translateY(-50%); content: '\e64b'; font-family: 'themify'; font-size: 8px; font-weight: 700; transition: transform .4s; }
.has-sub.open-nav > a:after { transform: translateY(-50%) rotate(-180deg); }

.navbar-toggle { width: 44px; height: 44px; text-align: center; display: inline-block; border-radius: 50%; padding: 5px; display: inline-flex; align-items: center; justify-content: center; transition: all 0.3s; }
.navbar-toggle.active { color: #ff4157; position: relative; z-index: 49; }
.navbar-toggle:hover, .navbar-toggle.active { background: rgba(255, 255, 255, 0.2); }
.bg-dark-alt .navbar-toggle:hover, .bg-dark .navbar-toggle:hover, .bg-dark-alt .navbar-toggle.active, .bg-dark .navbar-toggle.active { background: transparent; }
.is-transparent:not(.has-fixed) .navbar-toggle { color: #fff; }
.is-transparent:not(.has-fixed) .navbar-toggle:hover, .is-transparent:not(.has-fixed) .navbar-toggle.active { background: rgba(255, 255, 255, 0.2); }
.navbar-toggle-s2 { width: auto; height: auto; background: transparent; }
.navbar-toggle-s2:hover { background: transparent; }
.navbar-toggle-s2.active { background: transparent; }

.toggle-line { position: relative; width: 32px; height: 32px; display: block; color: #131313; }
.toggle-line:hover { color: #131313; }
.is-transparent:not(.has-fixed) .toggle-line, .is-dark.has-fixed .toggle-line { color: #fff; }
.toggle-line::after, .toggle-line::before, .toggle-line > span { content: ""; position: absolute; background: currentColor; transition: all 0.2s; width: 24px; height: 2px; left: 4px; }
.toggle-line::before { top: 8px; }
.toggle-line::after { bottom: 8px; }
.toggle-line > span { top: 50%; margin-top: -1px; width: 20px; }
.active .toggle-line { color: #131313; }
.bg-dark-alt .active .toggle-line { color: #ffffff; }
.active .toggle-line::before, .active .toggle-line::after { width: 28px; left: 2px; }
.active .toggle-line::before { transform: rotate(45deg); top: 15px; }
.active .toggle-line::after { transform: rotate(-45deg); bottom: 15px; }
.active .toggle-line > span { opacity: 0; visibility: hidden; }
.bg-dark-alt .toggle-line, .bg-dark .toggle-line { color: #ffffff; }
.toggle-line-s2 { width: 36px; }
.toggle-line-s2::after, .toggle-line-s2::before, .toggle-line-s2 > span { width: 30px; height: 2px; left: 0; border-radius: 0; background: rgba(255, 255, 255, 0.6); }
.toggle-line-s2::before { top: 2px; }
.toggle-line-s2::after { top: 22px; bottom: auto; }
.toggle-line-s2 > span { top: 13px; }
.active .toggle-line-s2::before, .active .toggle-line-s2::after { width: 30px; height: 2px; left: 0; }
.active .toggle-line-s2::after { top: 14px; }
.active .toggle-line-s2:before { top: 13px; }

.menu-sub .menu-item > a .badge-version { color: #48494e; }
.bg-ocean-pattern .menu-sub .menu-item > a .badge-version { color: #c8c9cd; }
.menu-sub .menu-item:hover > a .badge-version { color: #ff4157; }
.bg-ocean-pattern .menu-sub .menu-item:hover > a .badge-version { color: #ff4157; }

@media (min-width: 576px) { .menu-btns::before { display: block; }
  .menu-btns > li a { padding: 12px 22px; }
  .menu-btns-s2:before { display: none; } }
@media (min-width: 992px) { .header-menu { display: flex !important; width: auto; align-items: center; position: static; background: transparent; padding: 0; margin: 0; border-radius: 0; overflow: visible; }
  .header-navbar-creative .header-menu { display: block !important; }
  .header-navbar { padding: 0 0 0 15px; margin-top: 0; position: relative; }
  .header-navbar:before { display: none; }
  .has-fixed .header-navbar { margin-top: 0; }
  .menu { display: flex; align-items: center; padding: 0; }
  .menu-item { border-bottom: none; }
  .menu-item > a { padding: 20px 10px; text-transform: uppercase; }
  .menu-s2 .menu-item > a { font-size: 13px; }
  .menu-drop .menu-item > a { font-weight: 400; }
  .menu-s2 .menu-item > a { padding: 30px 10px; }
  .menu-item:hover { z-index: 9999; }
  .bg-dark-alt .menu-item > a, .bg-dark .menu-item > a { color: #ffffff; }
  .bg-dark-alt .menu-item > a:hover, .bg-dark .menu-item > a:hover { color: #ff4157; }
  .bg-dark-alt .menu-item > a.active, .bg-dark .menu-item > a.active { color: #ff4157; }
  .bg-ocean-pattern .menu-item > a.active { color: #ff4157; }
  .menu-sub { position: absolute; max-width: 220px; min-width: 220px; background: #ffffff; padding: 15px 0; top: 100%; left: 0; display: block !important; z-index: 99; opacity: 0; visibility: hidden; transition: all .4s; transform: translateX(0) translateY(-8px); box-shadow: 0px 3px 12px 0px rgba(10, 16, 21, 0.08); }
  .bg-dark-alt .menu-sub, .bg-dark .menu-sub { background: #16222d; }
  .bg-ocean-pattern .menu-sub { background: #290b9d; box-shadow: 0px 3px 8px 0px rgba(10, 16, 21, 0.4); }
  .menu-sub a { width: 100%; display: block; padding: 10px 10px; }
  .menu-sub .menu-item:first-child { border-top: none; }
  .menu-sub .menu-item:hover > a { color: #ff4157; }
  .menu-sub .menu-item:hover > a .badge-version { color: #ff4157; }
  .bg-ocean-pattern .menu-sub .menu-item:hover > a { color: #ff4157; }
  .menu-item:hover > .menu-sub { opacity: 1; visibility: visible; transform: translateX(0) translateY(0); }
  .menu-sub .menu-sub { left: calc(100% - 25px); top: 0; margin: 0; max-width: 180px; min-width: 180px; }
  .menu-sub .menu-sub li:last-child.has-sub .menu-sub { top: auto; bottom: 0; }
  .menu-sub .menu-sub .menu-item a { padding-top: 7px; padding-bottom: 7px; font-size: 13px; }
  .is-dark .menu-sub { background-color: #3d77f9; }
  .menu-mega { position: absolute; left: 50%; background: #fff; padding: 15px 10px; top: 100%; display: block !important; z-index: 99; opacity: 0; visibility: hidden; transition: all .4s; box-shadow: 0px 3px 12px 0px rgba(10, 16, 21, 0.08); transform: translateX(-50%) translateY(-8px); }
  .menu-item:hover > .menu-mega { opacity: 1; visibility: visible; transform: translateX(-50%) translateY(0); }
  .menu-mega .menu-item a { padding-top: 8px; padding-bottom: 8px; }
  .menu-mega-innr { display: flex; }
  .menu-mega-innr > div, .menu-mega-innr ul { flex-grow: 1; }
  .menu-mega-2clmn .menu-mega-innr > div, .menu-mega-2clmn .menu-mega-innr ul { width: 50%; }
  .menu-mega-3clmn .menu-mega-innr > div, .menu-mega-3clmn .menu-mega-innr ul { width: 33.33%; }
  .menu-mega-2clmn { min-width: 350px; max-width: 350px; }
  .menu-mega-3clmn { min-width: 460px; max-width: 460px; }
  .menu-btns { display: flex; margin-bottom: -10px; margin-left: 12px; padding-left: 15px; padding-top: 0; }
  .menu-btns > li { padding: 10px; }
  .menu-btns > li:last-child { padding-right: 0; }
  .menu-btns > li .search { display: inline-block; margin-right: 5px; }
  .menu-btns-s2 { padding-left: 0; margin-left: 0; }
  .menu .has-megamenu.menu-item { position: static; }
  .menu .has-megamenu .menu-mega-4clmn { max-width: 960px; min-width: 960px; margin: 0 auto; transform: translateX(-61.7%) translateY(0px); border-right: 1px solid #fff; }
  .menu .has-megamenu .menu-mega-4clmn .menu-mega-list { text-align: center; }
  .menu .has-megamenu .menu-mega-4clmn .menu-mega-list:not(:last-child) { border-right: 1px solid #f1f2eb; }
  .menu .has-megamenu .menu-mega-4clmn .menu-mega-list:not(:last-child).style-img { border-color: transparent; }
  .menu .has-megamenu .menu-mega-4clmn .menu-mega-list li.menu-item { text-transform: uppercase; font-weight: 500; }
  .menu .has-megamenu .menu-image { max-width: 100%; }
  .has-sub > a { padding-right: 24px !important; position: relative; }
  .has-sub > a:after { right: 8px; }
  .has-sub.menu-item:last-child .menu-mega { left: auto; right: 0; transform: translateX(0) translateY(-8px); }
  .has-sub.menu-item:last-child:hover .menu-mega { transform: translateX(0) translateY(0); }
  .menu-sub .has-sub > a { padding-right: 35px; }
  .menu-sub .has-sub > a:after { transform: translateY(-50%) rotate(-90deg); right: 15px; }
  .has-fixed.is-shrink .header-main .menu .has-megamenu .menu-mega-4clmn { transform: translateX(-61.7%) translateY(-4px); } }
@media (min-width: 992px) { .is-transparent:not(.has-fixed) .menu > .menu-item > a { color: #ffffff; }
  .is-transparent:not(.has-fixed) .menu > .menu-item:hover > a { color: #ff4157; }
  .bg-ocean-pattern .is-transparent:not(.has-fixed) .menu > .menu-item:hover > a { color: #ff4157; }
  .is-transparent:not(.has-fixed) .menu-btns .btn { color: #ffffff; }
  .header-navbar-s3 .menu-item > a { padding: 30px 12px; font-size: 12px; }
  .header-navbar-s3 .menu-sub a { padding: 10px 18px; } }
@media (min-width: 1200px) { .menu-item > a { padding: 30px 18px; }
  .header-navbar-s3 .menu-item > a { padding: 30px 13px; font-size: 14px; }
  .menu-s2 .menu-item > a { font-size: 14px; padding: 30px 14px; }
  .menu-item.has-sub > a { padding-right: 34px !important; }
  .menu-item.has-sub > a:after { right: 18px; }
  .menu-sub a { padding: 10px 18px; }
  .header-navbar-s3 .menu-sub a { padding: 10px 18px; }
  .menu .has-megamenu .menu-mega-4clmn { max-width: 1140px; min-width: 1140px; transform: translateX(-65.7%) translateY(-4px); }
  .has-fixed.is-shrink .header-main .menu .has-megamenu .menu-mega-4clmn { transform: translateX(-65.7%) translateY(-4px); } }
@media (min-width: 1200px) and (max-width: 1360px) { .menu-s2 .menu-item > a { padding: 30px 12px; } }
@media (min-width: 1600px) { .menu-btns { margin-left: 25px; padding-left: 22px; }
  .menu-btns-s2 { margin-left: 0; padding-left: 6px; }
  .menu-btns > li .search { margin-right: 10px; }
  .header-navbar-s3 .menu-item > a { padding: 30px 18px; }
  .menu-sub a { padding: 10px 18px; }
  .header-navbar-s3 .menu-sub a { padding: 10px 18px; }
  .menu-container { max-width: 1427px; }
  .header-main { padding: 30px 0; } }
.menu-creative .menu-item { border-bottom: none; }
.menu-creative .menu-item > a { font-size: 22px; font-weight: 700; color: #f5bbc1; text-transform: capitalize; }
.menu-creative .menu-item > a .badge { background: #ffffff; color: #a70013; }
.menu-creative .menu-item > a .badge-version { background: #fd9faa; color: #ffffff; }
.menu-creative .menu-item.open-nav > a, .menu-creative .menu-item.active > a { color: #ffffff; }
.menu-creative .menu-item.has-sub a:after { display: none; }
.menu-creative .menu-drop .menu-item > a { font-size: 13px; line-height: 28px; color: #fd9faa; padding: 0; }

@media (min-width: 320px) and (max-width: 575px) { .header-sidebar .logo img { height: 30px; }
  .header-sidebar .header-logo.mb-auto { margin-bottom: 0 !important; } }
@media (min-width: 576px) { .menu-creative { position: relative; display: block; width: 100%; }
  .menu-creative .menu-item { opacity: 0; transform: translateX(-40px); }
  .menu-creative .menu-item.open-nav > a, .menu-creative .menu-item.active > a { color: #ffffff; }
  .menu-shown .menu-creative .menu-item { opacity: 1; transform: translateX(0); transition: all 0.6s ease 0.3s; }
  .menu-shown .menu-creative .menu-item:nth-child(1) { transition-delay: 0.3s; }
  .menu-shown .menu-creative .menu-item:nth-child(2) { transition-delay: 0.4s; }
  .menu-shown .menu-creative .menu-item:nth-child(3) { transition-delay: 0.5s; }
  .menu-shown .menu-creative .menu-item:nth-child(4) { transition-delay: 0.6s; }
  .menu-shown .menu-creative .menu-item:nth-child(5) { transition-delay: 0.7s; }
  .menu-shown .menu-creative .menu-item:nth-child(6) { transition-delay: 0.8s; }
  .menu-shown .menu-creative .menu-item:nth-child(7) { transition-delay: 0.9s; }
  .menu-creative .menu-item > a { font-size: 36px; padding: 0; line-height: 60px; }
  .menu-creative .menu-item.has-sub a { padding-right: 0 !important; }
  .menu-creative .menu-drop { min-width: auto; background: transparent; box-shadow: none; }
  .menu-creative .menu-drop .menu-item { display: inline-block; width: 48%; }
  .menu-creative .menu-drop .menu-item > a { font-size: 15px; line-height: 30px; }
  .menu-creative .menu-drop .menu-item .menu-item { width: 48%; }
  .menu-creative .menu-drop .menu-item .menu-item a { font-size: 15px; } }
@media (min-width: 992px) { .toggle-line-s2 { width: 36px; }
  .toggle-line-s2::after, .toggle-line-s2::before, .toggle-line-s2 > span { width: 35px; }
  .active .toggle-line-s2::before, .active .toggle-line-s2::after { width: 35px; }
  .menu-creative .menu-drop .menu-item > a { font-size: 18px; line-height: 36px; } }
@media (min-width: 1600px) { .menu-creative .menu-drop .menu-item { width: 32.9%; } }
/** 03.03 Sections */
.section { position: relative; width: 100%; padding-top: 45px; padding-bottom: 45px; }
.section-contact { padding-left: 30px; padding-right: 30px; }
.section-footer-less { overflow: hidden; }
.section-xbl { padding-bottom: 20px !important; }

.section-s { padding-top: 60px; padding-bottom: 60px; }

.section-pb-s { padding-bottom: 120px; }

.section-pt-s { padding-top: 120px; }

.section-m { padding-top: 60px; padding-bottom: 60px; }

.section-pb-m { padding-bottom: 120px; }

.section-pt-m { padding-top: 120px; }

.section-l { padding-top: 60px; padding-bottom: 60px; }

.section-pb-l { padding-bottom: 120px; }

.section-pt-l { padding-top: 120px; }

.section-x { padding-top: 75px; padding-bottom: 75px; }

.section-pb-x { padding-bottom: 150px; }

.section-pt-x { padding-top: 150px; }

.section-xx { padding-top: 75px; padding-bottom: 75px; }

.section-pb-xx { padding-bottom: 150px; }

.section-pt-xx { padding-top: 150px; }

@media (min-width: 576px) { .section { padding-top: 51.4285714286px; padding-bottom: 51.4285714286px; }
  .section-s { padding-top: 17.1428571429px; padding-bottom: 17.1428571429px; }
  .section-pb-s { padding-bottom: 30px; }
  .section-pt-s { padding-top: 30px; }
  .section-m { padding-top: 34.2857142857px; padding-bottom: 34.2857142857px; }
  .section-pb-m { padding-bottom: 60px; }
  .section-pt-m { padding-top: 60px; }
  .section-l { padding-top: 68.5714285714px; padding-bottom: 68.5714285714px; }
  .section-pb-l { padding-bottom: 120px; }
  .section-pt-l { padding-top: 120px; }
  .section-x { padding-top: 85.7142857143px; padding-bottom: 85.7142857143px; }
  .section-pb-x { padding-bottom: 150px; }
  .section-pt-x { padding-top: 150px; }
  .section-xx { padding-top: 85.7142857143px; padding-bottom: 85.7142857143px; }
  .section-pb-xx { padding-bottom: 150px; }
  .section-pt-xx { padding-top: 150px; } }
@media (min-width: 768px) { .section { padding-top: 60px; padding-bottom: 60px; }
  .section-xbl { padding-bottom: 30px !important; }
  .section-s { padding-top: 20px; padding-bottom: 20px; }
  .section-pb-s { padding-bottom: 30px !important; }
  .section-pt-s { padding-top: 30px !important; }
  .section-m { padding-top: 40px; padding-bottom: 40px; }
  .section-pb-m { padding-bottom: 60px !important; }
  .section-pt-m { padding-top: 60px !important; }
  .section-l { padding-top: 80px; padding-bottom: 80px; }
  .section-pb-l { padding-bottom: 120px !important; }
  .section-pt-l { padding-top: 120px !important; }
  .section-x { padding-top: 100px; padding-bottom: 100px; }
  .section-pb-x { padding-bottom: 150px !important; }
  .section-pt-x { padding-top: 150px !important; }
  .section-xx { padding-top: 100px; padding-bottom: 100px; }
  .section-pb-xx { padding-bottom: 150px !important; }
  .section-pt-xx { padding-top: 150px !important; } }
@media (min-width: 992px) { .section { padding-top: 90px; padding-bottom: 90px; }
  .section-footer-less { padding-bottom: 40px; }
  .section-contact { padding-left: 0; padding-right: 0; }
  .section-xbl { padding-bottom: 70px !important; }
  .section-s { padding-top: 30px; padding-bottom: 30px; }
  .section-s.is-bg-half { padding-top: 30px; }
  .section-pb-s { padding-bottom: 30px !important; }
  .section-pt-s { padding-top: 30px !important; }
  .section-m { padding-top: 60px; padding-bottom: 60px; }
  .section-m.is-bg-half { padding-top: 60px; }
  .section-pb-m { padding-bottom: 60px !important; }
  .section-pt-m { padding-top: 60px !important; }
  .section-l { padding-top: 120px; padding-bottom: 120px; }
  .section-l.is-bg-half { padding-top: 120px; }
  .section-pb-l { padding-bottom: 120px !important; }
  .section-pt-l { padding-top: 120px !important; }
  .section-x { padding-top: 150px; padding-bottom: 150px; }
  .section-x.is-bg-half { padding-top: 150px; }
  .section-pb-x { padding-bottom: 150px !important; }
  .section-pt-x { padding-top: 150px !important; }
  .section-xx { padding-top: 150px; padding-bottom: 150px; }
  .section-xx.is-bg-half { padding-top: 150px; }
  .section-pb-xx { padding-bottom: 150px !important; }
  .section-pt-xx { padding-top: 150px !important; } }
@media (min-width: 1600px) { .section-s { padding-top: 30px; padding-bottom: 30px; }
  .section-s.is-bg-half { padding-top: 30px; }
  .section-pb-s { padding-bottom: 30px !important; }
  .section-pt-s { padding-top: 30px !important; }
  .section-m { padding-top: 60px; padding-bottom: 60px; }
  .section-m.is-bg-half { padding-top: 60px; }
  .section-pb-m { padding-bottom: 60px !important; }
  .section-pt-m { padding-top: 60px !important; }
  .section-l { padding-top: 120px; padding-bottom: 120px; }
  .section-l.is-bg-half { padding-top: 120px; }
  .section-pb-l { padding-bottom: 120px !important; }
  .section-pt-l { padding-top: 120px !important; }
  .section-x { padding-top: 150px; padding-bottom: 150px; }
  .section-x.is-bg-half { padding-top: 150px; }
  .section-pb-x { padding-bottom: 150px !important; }
  .section-pt-x { padding-top: 150px !important; }
  .section-xx { padding-top: 180px; padding-bottom: 180px; }
  .section-xx.is-bg-half { padding-top: 180px; }
  .section-pb-xx { padding-bottom: 180px !important; }
  .section-pt-xx { padding-top: 180px !important; } }
.is-bg-half { padding-top: 0; }

@media (min-width: 768px) { .is-bg-half { padding-top: 30px; }
  .is-bg-half { padding-top: 60px; }
  .is-bg-half { padding-top: 120px; }
  .is-bg-half { padding-top: 150px; }
  .is-bg-half { padding-top: 150px; } }
/** 03.04 Footer */
.wgs { margin-bottom: 30px; }
.wgs-content p { color: #4a4a4a; font-size: .875rem; }
.wgs-content p a { font-weight: 700; }
.bg-dark-alt .wgs-content p a { color: #ffffff; }
.bg-dark-alt .wgs-content p a:hover { color: #ff4157; }
.tc-bunker .wgs-content p { color: #a2a9bf; }
.wgs-content .copyright-sm, .wgs-content .copyright-sm a { font-size: 0.8rem; font-weight: 400; color: #4a4a4a; }
.wgs-content-s2 { padding-top: 5px; }
.wgs-content-s2 h4 { font-size: 13px; text-transform: uppercase; color: #4a4a4a; margin-bottom: 0.5rem; }
.wgs-logo { max-width: 170px; padding-bottom: 20px; }
.wgs-logo img { height: 36px; }
.wgs-title { color: #0a1015; font-size: 1.125rem; margin-bottom: 15px; }
.wgs-title + form { padding-top: 10px; }
.wgs-title-s2 { font-size: 1rem; font-weight: 400; }
.wgs-menu li a { color: #4a4a4a; display: inline-block; padding: 6px 0; }
.wgs-menu li a:hover { color: #ff4157 !important; }
.tc-bunker .wgs-menu li a { color: #a2a9bf; }
.bg-dark-alt .wgs-menu li a { color: #ffffff; }
.bg-dark-alt .wgs-menu li a:hover { color: #ff4157; }
.bg-ocean-pattern .wgs-menu li a:hover { color: #ff4157 !important; }
.sidebar .wgs-menu li:last-child a, footer .wgs-menu li:last-child a { padding-bottom: 0; }
.wgs-menu-s2 { margin-bottom: 5px; margin-left: -7px; }
.wgs-menu-s2 li { display: inline-block; }
.wgs-menu-s2 li a { font-size: 13px; font-weight: 400; padding: 0 7px; position: relative; color: #131313; }
.wgs-menu-s2 li a:after { position: absolute; content: "|"; right: -3px; }
.wgs-menu-s2 li:last-child a:after { display: none; }

.field-group { position: relative; }
.field-group.btn-inline .button { position: absolute; right: 0; top: 12px; }
.field-group.btn-inline .button:hover { color: #ff4157; }
.field-group.btn-inline .button-short { top: 0; height: 100%; background: #3c3c3c; border: 1px solid #3c3c3c; color: #fff; }
.field-group .input { width: 100%; padding: 15px 55px 15px 22px; border: 1px solid #e5e5e5; }
.bg-secondary .field-group .input, .bg-dark-alt .field-group .input, .bg-dark .field-group .input { background: transparent; }
.field-group .input-short { width: 100%; padding: 7px 55px 7px 22px; border: 1px solid #3c3c3c; font-size: 16px; min-height: 44px; margin-bottom: 0; }
.tc-bunker .field-group .input-short::placeholder { color: #4b4b4b; }
.field-group .button { background: transparent; font-size: 18px; border: none; border-left: 1px solid #e5e5e5; padding: 7px 16px; color: #ff4157; }

.footer .wgs { margin-bottom: 0; }
.footer-alt .wgs-content p { font-size: 1rem; color: #4b4b4b; }
.footer-alt .field-group .button { color: #5d5d5d; }
.footer-s1 .field-group .input { background: transparent; border-color: rgba(255, 255, 255, 0.4); color: #ffffff; }
.footer-s1 .field-group button { border-color: rgba(255, 255, 255, 0.4); color: rgba(255, 255, 255, 0.6); }
.footer-s2 .wgs { margin-bottom: 0; }
.footer-s2 .wgs-logo { margin-bottom: 0; margin: auto; }
.footer-s3 .wgs-logo { margin-left: auto; margin-right: auto; margin-bottom: 20px; }
.footer-s3 .wgs-menu li a { color: #ffffff; }
.tc-bunker .footer-s3 .wgs-menu li a { color: #a2a9bf; }
.footer-s3 .wgs-menu li a:hover { color: #ff4157; }

.copyright-s2 { padding: 20px 0; }

@media (min-width: 768px) { .wgs { margin-bottom: 20px; }
  .wgs-title { margin-bottom: 20px; }
  .wgs .menu-inline li { display: inline-block; }
  .wgs .menu-inline li:not(:last-child) { margin-right: 25px; }
  .footer-s2 .wgs-logo { margin: 0; }
  .footer-s3 .wgs-logo { margin: 0 0 20px; } }
@media (min-width: 992px) { .wgs .menu-inline li { display: inline-block; }
  .wgs .menu-inline li:not(:last-child) { margin-right: 45px; }
  .wgs-menu-s2 { margin-left: -10px; }
  .wgs-menu-s2 li a { padding: 0 10px; font-size: 14px; }
  .wgs-content-s2 { padding-bottom: 1rem; } }
@media (min-width: 1200px) { .wgs-logo img { height: auto; } }
@media (min-width: 1280px) { .footer-s2 .wgs-content p { text-align: right; } }
.bdr-copyright { border-top: 1px solid #e5e5e5; }

.copyright-content { padding: 15px 0 0; font-size: 14px; line-height: 24px; color: #8f8f8f; }
.copyright-content p a { color: #4b4b4b; font-weight: 700; }
.copyright-content p a:hover { color: #ff4157; }
.copyright-content .social-style-icon { padding: 0; }
.copyright-content .social li:not(:last-child) { margin-right: 15px; }
.copyright-content .social li a { color: #1c1c1c; font-size: 14px; }
.copyright-content .social li a:hover { color: #ff4157 !important; }

@media (min-width: 768px) { .copyright-content { padding: 15px 0; } }
/** 04. Override */
.mfp-bg, .mfp-wrap, .mfp-wrap[class*="bg-"], .mfp-bg[class*="bg-"] { position: fixed !important; z-index: 99999999 !important; top: 0 !important; }

/** 05 block **/
/** 05.1 Banner */
.banner-heading { font-size: 1.714rem; font-weight: 900; line-height: 1.1; }
.banner-heading-s2 { font-size: 1.714rem; }
.banner-heading-s3 { margin-bottom: 20px; }

@keyframes rotateFull { 0% { transform: rotate(0deg); }
  50% { transform: rotate(90deg); }
  100% { transform: rotate(180deg); } }
@keyframes lineTop { 0% { transform: scale(0, 1); }
  12.5% { transform: scale(1, 1); }
  100% { transform: scale(1, 1); } }
@keyframes lineRight { 0% { transform: scale(1, 0); }
  12.5% { transform: scale(1, 0); }
  25% { transform: scale(1, 1); }
  75% { transform: scale(1, 1); } }
@keyframes lineBottom { 0% { transform: scale(0, 1); }
  25% { transform: scale(0, 1); }
  37.5% { transform: scale(1, 1); }
  100% { transform: scale(1, 1); } }
@keyframes lineLeft { 0% { transform: scale(1, 0); }
  37.5% { transform: scale(1, 0); }
  50% { transform: scale(1, 1); }
  100% { transform: scale(1, 1); } }
.line { background: #ff4157; display: block; }
.line-animate { width: 80%; height: 100%; top: 0; position: absolute; left: 0; z-index: -1; display: none; }
.line-animate.active { display: block; }
.line-top { width: 100%; height: 4px; position: absolute; top: 0; left: 0; transform-origin: left top; transform: scale(1, 1); animation: lineTop 5s linear 0s; }
.line-right { width: 4px; height: 100%; position: absolute; top: 0; right: 0; transform-origin: left top; transform: scale(1, 1); animation: lineRight 5s linear 0s; }
.line-bottom { width: 100%; height: 4px; position: absolute; right: 0; bottom: 0; transform-origin: right top; transform: scale(1, 1); animation: lineBottom 5s linear 0s; }
.line-left { width: 4px; height: 100%; position: absolute; left: 0; bottom: 0; transform-origin: left bottom; transform: scale(1, 1); animation: lineLeft 5s linear 0s; }

@media (min-width: 480px) { .line-animate { width: 60%; } }
@media (min-width: 576px) { .line-animate { width: 60%; }
  .banner-inner .line-animate { width: 60%; } }
@media (min-width: 768px) { .line-animate { width: 50%; }
  .banner-inner .line-animate { width: 60%; } }
@media (min-width: 992px) { .line-animate .banner-content { width: 40%; } }
.banner { position: relative; }
.banner-heading + p { margin: 25px 0; }
.banner .size-sm { font-size: 1.75rem; }
.banner-block { display: flex; align-items: center; min-height: 100vh; padding: 70px 0 0; }
.header-s1 .banner-block { padding: 75px 0 0; }
.banner-content { padding-left: 15px; padding-top: 50px; padding-bottom: 50px; }
.banner-s3 .banner-content { padding-left: 0; }
.banner-alaska .banner-content { padding-left: 0; }
.banner-helena .banner-content, .banner-inner .banner-content { padding-top: 0; padding-bottom: 0; }
.banner-content-s2 { padding-left: 0; margin-left: 0; }
.banner-inner-s4 .banner-content { padding-left: 0; margin-left: 0; }
.banner-s2 { position: relative; }
.banner-s2 .sub-heading { font-size: 0.75rem; font-family: "Poppins", sans-serif; font-weight: 400; letter-spacing: 3.2px; color: #dbdbf5; margin-bottom: 10px; }
.banner-s2 .banner-heading { font-weight: 600; color: #ffffff; margin-bottom: 20px; line-height: 1.286; }
.banner-s2 .banner-content { position: relative; padding: 50px 40px; }
.banner-s2 .banner-content .sub-heading { color: #dbdbf5 !important; }
.banner-s2 .banner-btn { display: flex; align-items: center; }
.banner-s2 .banner-btn h6 { color: #ffffff; font-size: .75rem; font-weight: 700; margin-right: 10px; margin-bottom: 0; text-transform: uppercase; }
.banner-inner .banner-heading { line-height: 1.4; font-weight: 900; }
.banner-inner .banner-heading + .btn { margin-top: 25px; }
.banner-inner .banner-block { min-height: 100%; padding: 95px 0  80px; }
.header-s1 .banner-inner .banner-block { padding: 120px 0 45px; }
.banner-inner-md .banner-block { padding: 154px 0 100px; }
.banner-inner-s4 .banner-block { min-height: 100%; padding: 120px 0 80px; }
.banner-inner-s4 .banner-heading { text-transform: uppercase; }
.banner-inner-title { font-size: 1.8rem; font-weight: 600; line-height: 1.25; }
.banner-inner-title + .lead { font-family: "Poppins", sans-serif; margin-top: 8px; padding-right: 25px; }
.banner-inner-title + p { padding-top: 10px; }
.banner-inner-title-s2 { font-size: 1.8rem; font-weight: 800; line-height: 1.3; }
.banner-inner-title-s3 { line-height: 1.25; font-weight: 800; font-size: 1.8rem; }
.banner-s3 .banner-heading { margin-bottom: 15px; font-size: 2rem; }
.banner-s3 .banner-block { padding: 100px 0; }
.banner-s3 .social-style-icon { padding-bottom: 30px; }
.banner-menu li { display: inline-block; color: #ffffff; font-weight: 400; line-height: .8; font-size: 0.875rem; }
.banner-menu li:first-child a { padding-left: 0; }
.banner-menu li:not(:last-child) a::after { position: absolute; content: "/"; right: -5px; top: 2px; }
.banner-menu li a { color: #ffffff; position: relative; padding: 0 10px; }
.banner-menu li a:hover { color: #ff4157; }
.banner-alaska .bg-image  { background-size: contain; }
.banner-image { position: relative; }
.banner-btn-creative { padding-top: 18px; }
.banner-block-creative { min-height: 100%; padding: 175px 0 114px; }

.pm-0 { padding-left: 0 !important; margin-left: 0 !important; }

.banner-title { font-size: 1.5rem; }

@media (min-width: 992px) and (max-width: 1199px) { .banner-inner.sign-up-pd .banner-block { padding: 180px 0 80px; } }
@media (min-width: 481px) { .banner-inner-title-s3 { font-size: 2.8rem; }
  .banner-title { font-size: 2rem; } }
@media (min-width: 576px) { .banner-heading { font-size: 2.5rem; }
  .banner-content { padding-left: 50px; }
  .banner-alaska .banner-content { padding-left: 0; }
  .banner-s3 .banner-content { padding-left: 0; }
  .banner-content-s2 { padding-left: 0; margin-left: 0; }
  .banner .size-sm { font-size: 2.5rem; }
  .banner-s2 .banner-content { padding: 60px 50px; }
  .banner-s2 .banner-heading { line-height: 1.2; }
  .banner-s3 .banner-heading { font-size: 3rem; }
  .banner-menu li { font-size: 1.125rem; }
  .banner-title { font-size: 1.9rem; }
  .banner-block-creative { padding: 114px 0 114px; } }
@media (min-width: 768px) { .banner-s2 .sub-heading { font-size: 1rem; }
  .banner-s2 .banner-btn h6 { font-size: 1rem; }
  .banner-alaska .banner-content { padding-left: 0px; }
  .banner-helena .banner-content, .banner-inner .banner-content { padding-top: 50px; padding-bottom: 50px; }
  .banner-heading-s3 { margin-bottom: 20px; }
  .banner-inner-title { font-size: 1.7rem; }
  .banner-inner-title-s2 { font-size: 2rem; }
  .banner-inner-title-s3 { font-size: 2.8rem; }
  .banner-inner-lg .banner-block { padding: 120px 0 80px; }
  .banner-block-creative { min-height: 100vh !important; }
  .banner-title { font-size: 2.6rem; } }
@media (min-width: 992px) { .banner-heading { font-size: 3.75rem; line-height: 1.2; }
  .banner-heading-s3 { margin-bottom: 40px; }
  .banner-content { padding-left: 70px; }
  .banner-alaska .banner-content { padding-left: 0; }
  .banner-content-s2 { padding-left: 0; margin-left: 0; }
  .banner .size-sm { font-size: 3.75rem; }
  .banner-s2 .banner-heading { margin-bottom: 35px; }
  .banner-s2 .banner-content { padding: 70px 60px; }
  .banner-s2 .sub-heading { margin-bottom: 18px; }
  .banner-s3 .banner-heading { font-size: 3rem; }
  .banner-inner .banner-heading { margin-bottom: 0px; }
  .banner-inner .banner-heading + .btn { margin-top: 37px; }
  .header-s1 .banner-inner .banner-block { padding: 172px 0 55px; }
  .banner-inner-s4 .banner-heading { margin-bottom: 20px; }
  .banner-inner-title { font-size: 2rem; }
  .banner-inner-title-s2 { font-size: 2.8rem; }
  .banner-inner-title + .lead { padding-right: 25px; }
  .banner-inner-title-s3 { font-size: 3.5rem; }
  .banner-title { font-size: 2.8rem; } }
@media (min-width: 1200px) { .banner-heading-s2 { font-size: 5.55rem; line-height: 1; }
  .banner-inner-title { font-size: 2.85rem; }
  .banner-inner-title-s2 { font-size: 3.5rem; }
  .banner-inner-title-s3 { font-size: 4.5rem; }
  .banner-inner-md .banner-block { padding: 173px 0 87px !important; }
  .banner-block-creative { padding: 100px 0; }
  .banner-title { font-size: 3.5rem; line-height: 1.11; margin-bottom: 0.75rem; } }
@media (min-width: 1200px) and (min-height: 680px) { .body-boxed .banner-block { min-height: calc(100vh - 60px); }
  .banner-content-s2 { padding-left: 0; margin-left: 0; } }
@media (min-width: 1360px) and (min-height: 680px) { .body-boxed .banner-block { min-height: calc(100vh - 100px); } }
@media (min-width: 1280px) { .header-s1 .banner-block { padding: 120px 0 0; }
  .banner-content { padding-left: 0; }
  .banner-heading + p { margin: 35px 0 55px; }
  .banner-inner .banner-heading { font-size: 3.75rem; line-height: 1.5; }
  .banner-inner .banner-block { padding: 100px 0; min-height: 100%; }
  .header-s1 .banner-inner .banner-block { padding: 192px 0 75px; }
  .banner-inner-s4 .banner-block { min-height: 600px; padding: 165px 0 100px; }
  .banner-inner-sm .banner-block { padding: 122px 0 53px; }
  .banner-inner-md .banner-block { padding: 173px 0 87px; }
  .banner-inner-lg .banner-block { height: 100vh; padding: 112px 0 43px; }
  .banner-s2 .banner-content { padding: 70px 80px; }
  .banner-s2-inner .banner-heading { margin-bottom: 0; }
  .banner-s2-inner .banner-content { padding-top: 40px; padding-bottom: 40px; }
  .banner-s2-inner .banner-block { padding: 160px 0 80px; }
  .banner-s3 .banner-heading { font-size: 3.75rem; margin-bottom: 45px; }
  .banner-s3 .banner-heading + p { margin-bottom: 30px; }
  .banner-menu li { font-size: 1.875rem; }
  .banner-menu li:first-child a { padding-left: 0; }
  .banner-menu li:not(:last-child) a::after { right: -10px; top: 6px; }
  .banner-menu li a { padding: 0 25px; }
  .sign-up .banner-block { padding: 160px 0 10px; } }
@media (min-width: 1200px) and (max-width: 1370px) { .banner-helena .image-block-s2 { max-width: 480px !important; margin-left: auto !important; margin-right: auto !important; }
  .gshape-none { display: none !important; } }
@media (min-width: 1370px) { .banner-inner-title-s2 { font-size: 3.75rem; } }
@media (min-width: 1600px) { .banner-block { padding: 80px 0 0; }
  .header-s1 .banner-block { padding: 150px 0 0; }
  .banner-block-creative { padding: 100px 0 114px; }
  .banner-kansas { padding: 110px 0 110px; }
  .banner-heading { font-size: 4.5rem; line-height: 1.25; }
  .banner-heading-s2 { font-size: 5.55rem; line-height: 1; }
  .banner-heading-s3 { line-height: 1.195; margin-bottom: 40px; }
  .banner .size-sm { font-size: 5.3125rem; line-height: 1.294; }
  .banner-content { margin-right: -40px; }
  .banner-kansas .banner-content { margin-right: 0; }
  .banner-inner .banner-block { padding: 150px 0; }
  .header-s1 .banner-inner .banner-block { padding: 320px 0 180px; }
  .banner-inner-sm .banner-block, .banner-inner-lg .banner-block { padding: 184px 0 88px; }
  .banner-inner-s4 .banner-block { padding: 165px 0 100px; }
  .banner-s2 .banner-content { padding: 100px; }
  .banner-s2-inner .banner-content { padding: 50px 80px; }
  .banner-s2-inner .banner-block { padding: 220px 0 150px; }
  .banner-s4 .banner-block { padding: 140px 0 250px; }
  .banner-alaska .bg-image  { background-size: cover; }
  .banner-title { font-size: 4rem; } }
@media (min-width: 1200px) and (max-width: 1360px) { .banner-content { margin-left: 50px; }
  .banner-alaska .banner-content { margin-left: 0; }
  .banner-content.pdl-0 { padding-left: 0; } }
.banner-btn { position: relative; }

.scroll { position: absolute; left: 0; right: 0; top: -100px; margin: auto; }
.scroll-title { color: #4a4a4a; font-weight: 700; margin-bottom: 17px !important; }
.scroll-s2 h6 { font-size: 12px; margin-bottom: 15px; }
.scroll-s2 img { max-width: 30px; animation: scroll 1.3s infinite; }
.scroll-down { position: absolute; left: 50%; bottom: 70px; margin: auto; text-align: center; width: auto; right: auto; top: auto; transform: translatex(-50%); }
.scroll-icon { position: absolute; top: 30px; left: 50%; width: 30px; height: 50px; margin-left: -15px; border: 2px solid #ffffff; border-radius: 50px; }
.scroll-icon::after { position: absolute; top: 10px; left: 50%; content: ''; width: 6px; height: 6px; margin-left: -3px; background-color: #ffffff; border-radius: 100%; -webkit-animation: dotanimation 2s infinite; animation: dotanimation 2s infinite; }
.scroll-icon-s2 { border: 2px solid #1a5ff8; width: 40px; height: 58px; position: relative; display: inline-block; top: 0; margin: 0 auto; left: 0; }
.scroll-icon-s2:after { background-color: #1a5ff8; animation: none; height: 13px; width: 2px; top: 5px; margin-left: -1px; border-radius: 0; }

@media (max-width: 991px) { .sign-up-pd .image-block-s2 { display: none; } }
@keyframes dotanimation { 0% { transform: translate(0, 0); opacity: 0; }
  40% { opacity: 1; }
  80% { transform: translate(0, 15px); opacity: 0; }
  100% { opacity: 0; } }
@keyframes scroll { 0% { opacity: 1; }
  100% { opacity: 0; transform: translateY(20px); } }
@media (min-width: 320px) and (max-width: 767px) and (min-height: 620px) and (orientation: portrait) { .banner-block { min-height: 70vh; } }
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) { .banner-block { min-height: 60vh; } }
/** 05.2 Feature */
.feature { display: flex; height: 100%; max-width: 400px; }
.text-center .feature { margin: 0 auto; }
.feature-icon { font-size: 48px; color: #ff4157; line-height: 0.612; }
.feature-icon-box { position: relative; }
.feature-icon-s2 { font-size: 35px; }
.feature .color-light { color: #ffffff; }
.feature-box { display: flex; align-items: flex-start; }
.feature-content { margin-left: 20px; }
.feature-content-s2 { font-weight: 400; }
.feature-content-s3 { margin-top: 20px; }
.feature-content-s3 p:last-child { margin-bottom: 0; }
.feature-content-s4 { margin-left: 20px; margin-top: -6px; }
.feature-content-s4 p:last-child { margin-bottom: 0; }
.feature-s2 { display: block; padding: 20px 0; }
.feature-s2 .content { color: #4a4a4a; }
.bg-dark-alt .feature-s2 .content { color: #ffffff; }
.feature-s2 .content p { margin-bottom: 0; font-weight: 400; }
.feature-s2 .icon { font-size: 72px; color: #ecf3f6; z-index: -1; }
.feature-s2 .icon-box { position: relative; }
.text-center .feature-s2 .icon-box { margin: 0 auto 10px; }
.feature-s2 .icon-box strong.icon { font-family: "Poppins", sans-serif; color: #ececec; line-height: .8; }
.bg-dark-alt .feature-s2 .icon-box strong.icon { color: rgba(255, 255, 255, 0.04); }
.feature-s2 .icon-box-content { position: absolute; left: 0; top: 50%; right: 0; transform: translateY(-50%); }
.feature-s2 .icon-box-content h4 { color: #ff4157; }
.feature-s2 .icon-box-content h6 { font-size: 1rem; }
.bg-dark-alt .feature-s2 .icon-box-content h6 { color: #ffffff; }
.feature-s2-inner { padding: 30px; }
.feature-s2-alt-inner { padding: 30px; }
.feature-s2-alt-inner .feature-content { margin-left: 0; }
.feature-heading { position: absolute; left: 0; top: 50%; right: 0; transform: translateY(-50%); }
.feature-heading h4 { font-size: 1.25rem; color: #ff4157; }
.feature-heading h4 span { display: block; line-height: .8; }
.tc-light .feature-heading h4 { color: #ff4157 !important; }
.feature-heading h4 small { font-size: 1rem; font-weight: 700; color: #131313; }
.tc-light .feature-heading h4 small { color: #ffffff; }
.feature-heading-s2 { position: relative; transform: translateY(0); }
.feature-heading-s2 h4 { color: #131313; }
.feature-heading-s2 h4 strong { font-size: 1.5rem; padding: 10px 0; display: block; line-height: 1.5; }
.feature-heading-s2 h4 span { font-size: 72px; color: rgba(173, 192, 199, 0.4); position: absolute; left: 0; right: 0; margin: auto; z-index: -1; }
.tc-light .feature-heading-s2 h4 { color: #ffffff !important; }
.feature-heading-s2 h3 { margin: 20px 0 10px; }
.feature-alt { display: block; padding: 30px 20px 17px; }
.feature-alt-s3 { padding: 0; }
.feature-alt .feature-content { margin: 10px 0 0; }
.feature-alt .feature-content h3 { margin: 20px 0 12px; }
.feature-alt-s2 { padding: 0px; }
.feature-alt-s2-inner { padding: 30px; }
.feature-alt-s2-inner .feature-content { margin-left: 0; }
.feature-icon-s3 { width: 70px; height: 70px; text-align: center; background: #ffffff; color: #ff4157; border-radius: 50%; margin: 0 auto; padding: 13px 0; }
.feature-icon-s3 .icon { font-size: 24px; color: #ff4157; }
.bg-ocean-pattern .feature-icon-s3 .icon { color: #ff4157; }
.tc-light .feature-icon-s3 .icon { color: #ff4157; }
.feature-area { padding: 45px 35px; }
.feature-border { position: relative; height: 100%; }
.feature-border > * { position: relative; z-index: 3; }
.feature-border:after { position: absolute; content: ''; border: 1px solid #d4dce8; top: -0.5px; bottom: -0.5px; left: -0.5px; right: -0.5px; z-index: 1; }
.bg-dark-alt .feature-border:after { border: 1px solid #686886; }
.tc-bunker .feature-border:after { border: 1px solid #131e27; }
.feature-border:before { position: absolute; content: ''; top: -1px; bottom: -1px; left: -1px; right: -1px; background: #ffffff; opacity: 0; transition: all .4s; z-index: 2; }
.bg-dark-alt .feature-border:before { background: #0e161d; }
.feature-border:hover:before { opacity: 1; }
.feature-item { padding: 30px; height: 100%; }
.feature-count [class*="col-"] { counter-increment: counter; }
.feature-count [class*="col-"] .feature-title:before { content: "0" counter(counter); color: rgba(255, 65, 87, 0.6); font-size: 50px; font-weight: 700; vertical-align: middle; padding-right: 20px; }
.feature-title { margin: 15px 0 0; }

.heading-light { font-size: 1.375rem; font-weight: 400; }

.service-single-v3 p { color: #4a4a4a; font-weight: 300; }

.wgs-service { background: #ffffff; }
.wgs-service ul li { display: block; border-bottom: 1px solid #e2e8eb; }
.wgs-service ul li:first-child a { background: rgba(236, 246, 250, 0.5); font-weight: 700; }
.wgs-service ul li a { display: block; padding: 20px 30px; font-size: 1.125rem; font-weight: 400; }
.wgs-service ul li a:hover { background: rgba(236, 246, 250, 0.5); }

.hov-primary { transition: all ease .5s; }
.hov-primary:hover { background-color: #ff4157; color: #ffffff; }
.hov-primary:hover .feature-icon { color: #ffffff; }
.hov-primary:hover .feature-content * { color: #ffffff; }
.hov-secondary { transition: all ease .5s; }
.hov-secondary:hover { background-color: #1a5ff8; color: #ffffff; }
.hov-secondary:hover .feature-icon { color: #ffffff; }
.hov-secondary:hover .feature-content * { color: #ffffff; }
.hov-flip { transition: transform 0.6s; transform-style: preserve-3d; }
.hov-flip:hover { transform: rotateY(180deg); }
.hov-top { transition: all ease .5s; }
.hov-top:hover { transform: translateY(-20px); }
.hov-sweep { position: relative; }
.hov-sweep::before { content: ""; position: absolute; top: 0; left: 0; right: 0; bottom: 0; transform: scaleX(0); background: #1a5ff8; transition: all ease .6s; width: 100%; height: 100%; }
.hov-sweep:hover::before { transform: scaleX(1); }
.hov-sweep:hover .feature-icon, .hov-sweep:hover .feature-content * { position: relative; color: #ffffff; transition: .6s; }

@media (min-width: 576px) { .feature { display: block; }
  .feature-content { margin-left: 0px; }
  .feature-content-s4 { margin-top: 0; }
  .feature-icon { margin-bottom: 20px; }
  .feature-icon-s3 { margin-bottom: 25px; }
  .feature h4 { margin-bottom: 11px; }
  .feature-heading-s2 h4 { margin-bottom: 10px; }
  .feature-lead .lead { line-height: 1.5; }
  .feature-area { padding: 50px 40px; } }
@media (min-width: 768px) { .feature-content { margin-left: 0px; }
  .feature-alt { padding: 40px; }
  .feature-alt-s2 { padding: 0 20px; }
  .feature-alt-s3 { padding: 0 30px 30px 0; }
  .feature-area { padding: 60px 50px; }
  .feature-s4 { padding: 60px 50px; }
  .feature-border:before { top: -10px; bottom: -10px; left: -10px; right: -10px; } }
@media (min-width: 992px) { .feature { display: flex; }
  .feature h2 { font-size: 1.875rem; }
  .feature h3 { margin-bottom: 12px; line-height: 1.25; }
  .feature p { margin-bottom: 15px; }
  .feature-icon { font-size: 49px; }
  .feature-s4 .feature-icon { font-size: 70px; }
  .feature-icon-s2 { font-size: 35px; }
  .feature-icon-s4 { font-size: 60px; }
  .feature-content { margin-left: 25px; }
  .feature-content-s2 p { margin-bottom: 0; }
  .feature-content-s3 h3 { margin-bottom: 12px; }
  .feature-content-s4 { margin-left: 20px; margin-top: -6px; }
  .feature-content-s5 h3 { font-size: 1.333rem; }
  .feature-s2 { display: block; padding: 20px 15px 0; }
  .feature-s2-content p { color: #4a4a4a; }
  .feature-s2-inner { padding: 40px; }
  .feature-s2-alt-inner { padding: 30px; }
  .feature-alt { display: block; padding: 50px 20px 37px; }
  .feature-alt .feature-content { margin: 10px 0 0; }
  .feature-alt .feature-content h3 { margin: 23px 0 12px; }
  .feature-alt .feature-content p { margin-bottom: 10px; }
  .feature-alt .feature-content p:last-child { margin-bottom: 0; }
  .feature-alt-s2 { padding: 0 20px; }
  .feature-alt-s3 { padding: 0; }
  .feature-s3 { background: #fefefe; }
  .feature-s3 p { color: #4a4a4a; }
  .feature-s4 { padding: 60px 70px; margin: 0; max-width: 100%; }
  .style-mt { height: auto; } }
@media (min-width: 1200px) { .feature-area { padding: 80px; }
  .feature-content { margin-left: 30px; }
  .feature-content-s4 { margin-left: 20px; }
  .feature-s2 { padding: 20px 20px 0; }
  .feature-s2-inner { padding: 40px; }
  .feature-s2-alt-inner { padding: 50px 50px 30px; }
  .feature-s4 { padding: 35px 23px; }
  .feature-item { padding: 60px; }
  .style-mt { margin-top: -70px; } }
@media (min-width: 1600px) { .feature-area { padding: 80px 100px; }
  .feature-s4 { padding: 60px 70px; } }
.section-feature-alt .feature-alt { padding: 20px 0; }
.section-feature-alt .bdr-r { border: none; }

@media (min-width: 992px) { .section-feature-alt { padding-bottom: 0; }
  .section-feature-alt .bdr-rt { border: 1px solid #525269; border-width: 1px 1px 0 0; } }
@media (min-width: 1200px) { .section-feature-alt .feature-alt { padding: 60px 15px 50px; }
  .section-feature-alt .bdr-rt { border: 1px solid #525269; border-width: 1px 1px 0 0; } }
@media (min-width: 1600px) { .section-feature-overlap { padding-top: 0; margin-top: -170px; } }
/** 05.3 Services */
.service-block { padding: 25px 15px; }
.service-box { padding: 30px; }
.service-box h5 { margin-bottom: 17px; }
.service-list h4 { font-size: 1.2rem; }
.service-list li a { font-size: 18px; color: #4a4a4a; font-weight: 400; padding: 12px 0; display: block; }

.section-services .text-block { padding-bottom: 30px; }

@media (min-width: 768px) { .service-block { padding: 55px 30px; }
  .service-box { padding: 25px; }
  .service-list h4 { font-size: 1.2rem; }
  .section-services .text-box .lead { font-size: 1.2rem; } }
@media (min-width: 992px) { .service-box { padding: 50px; }
  .service-list h4 { font-size: 1.33rem; }
  .section-services .image-block { margin-top: -15px; }
  .section-services [class*="col-"] { padding-left: 0; padding-right: 0; }
  .section-services [class*="col-"]:not(:first-child) .text-block { padding-left: 0px; }
  .section-services .text-box .lead { font-size: 1.5rem; } }
@media (min-width: 1200px) { .service-block { padding: 55px 80px; } }
/**  05.4 Counter **/
.counter { display: block; height: 100%; justify-content: center; text-align: center; }
.counter-icon { font-size: 25px; color: #ff4157; line-height: 1.4; }
.counter .color-light { color: #ffffff; }
.counter-s2 { margin: -0.5px; }
.counter-box { display: flex; align-items: flex-start; }
.counter-content { margin-left: 0; }
.counter-bdr { border: 1px solid #6450b6; }
.counter h2 { margin-bottom: 0; }
.counter h3 { font-size: 1.5rem; }
.counter h4 { font-size: 1.125rem; }
.counter p { font-size: 0.75rem; line-height: 1.1; }
.counter-s2 { padding: 10px 15px; }
.counter-s2 .counter-content { margin-left: 20px; }

@media (min-width: 576px) { .counter-icon { font-size: 35px; }
  .counter-content { margin-left: 0; }
  .counter-s2 { padding: 30px 40px; }
  .counter p { font-size: .875rem; } }
@media (min-width: 768px) { .counter-icon { font-size: 35px; }
  .counter-s2 { padding: 30px 20px; }
  .counter-s2 .counter-content-s2 { margin-left: 0; }
  .counter-content { margin-left: 0px; }
  .counter h3 { font-size: 1.25rem; } }
@media (min-width: 992px) { .counter { display: flex; }
  .counter-content { text-align: left; margin-left: 15px; }
  .counter h2 { font-size: 1.875rem; }
  .counter h3 { font-size: 1.5rem; margin-bottom: 20px; }
  .counter-icon { font-size: 49px; }
  .counter-content { margin-left: 25px; }
  .counter-s2 .counter-content-s2 { margin-left: 20px; } }
@media (min-width: 1280px) { .counter-content { margin-left: 30px; } }
.section-counter-m { padding-top: 70px; padding-bottom: 70px; }

/**  05.5 Project */
.section-project { width: 100%; overflow: hidden; }

.project[class*="gutter"] { margin-left: 0 !important; margin-right: 0 !important; }
.project-filter { padding: 0 0 30px; text-align: center; }
.project-filter li { display: inline-block; cursor: pointer; padding: 0 15px; position: relative; line-height: 2.2; color: #4b4b4b; font-weight: 300; font-size: 1.125rem; }
.project-filter li:not(:last-child)::after { position: absolute; content: "/"; right: -6px; top: 0; bottom: 0; margin: auto 0; color: #4b4b4b; }
.tc-light .project-filter li { color: #ffffff; }
.tc-bunker .project-filter li { color: #a2a9bf !important; }
.project-filter li.active { color: #ff4157 !important; }
.bg-ocean-pattern .project-filter li.active { color: #ff4157 !important; }
.bg-dark .project-filter li, .bg-dark-light .project-filter li { color: #ffffff; }
.bg-dark .project-filter li:not(:last-child)::after, .bg-dark-light .project-filter li:not(:last-child)::after { color: #ffffff; }
.bg-dark .project-filter li.active, .bg-dark-light .project-filter li.active { color: #ff4157; }
.project-filter-s2 { text-align: left; margin-left: -15px; }
.project-filter-s2 li { font-size: 1rem; font-weight: 400; line-height: normal; padding-bottom: 10px; }
.project-filter-s2 li:not(:last-child)::after { display: none; }
.project-filter-s3 { margin: 0 -15px; }
.project-filter-s3 li:not(:last-child):after { color: currentColor; }
.project-call h2 { margin-bottom: 20px; margin-top: -12px; }
.project-wrapper { margin-left: -15px; margin-right: -15px; }
.project-wrapper-s2 { margin-left: -25px; margin-right: -25px; }
.project-more { margin-left: -15px; margin-right: -15px; }
.project-s4 [class*="col"]:last-child { padding-bottom: 0px !important; }
.project-alt-item { display: block; padding: 40px 0 0 40px; box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.09); overflow: hidden; }
.project-alt-item:hover .project-alt-img { transform: scale(1.03); }
.project-alt-item:hover .project-alt-img:after { opacity: 1; }
.project-alt-item:hover .project-alt-link { bottom: 40px; opacity: 1; visibility: visible; }
.project-alt-item:hover .project-alt-link:hover { color: #ffffff; }
.project-alt-img { position: relative; z-index: 1; transform-origin: 100% 100%; transition: all 0.3s; }
.project-alt-img img { width: 100%; }
.project-alt-img:after { position: absolute; content: ""; left: 0; top: 0; bottom: 0; right: 0; opacity: 0; transition: all 0.5s; }
.bg-light-blue .project-alt-img:after { background: linear-gradient(to top, #71ffdf 0%, rgba(113, 255, 223, 0.2) 100%); }
.bg-orange .project-alt-img:after { background: linear-gradient(to top, #fec08f 0%, rgba(254, 192, 143, 0.2) 100%); }
.bg-blue .project-alt-img:after { background: linear-gradient(to top, #8571ff 0%, rgba(133, 113, 255, 0.2) 100%); }
.bg-purple .project-alt-img:after { background: linear-gradient(to top, #ac4de2 0%, rgba(172, 77, 226, 0.2) 100%); }
.bg-green .project-alt-img:after { background: linear-gradient(to top, #71ff71 0%, rgba(113, 255, 113, 0.2) 100%); }
.bg-dark-blue .project-alt-img:after { background: linear-gradient(to top, #2746e5 0%, rgba(39, 70, 229, 0.2) 100%); }
.project-alt-link { font-size: 14px; color: #000; font-weight: 700; text-transform: uppercase; position: absolute; left: 40px; bottom: 0px; display: inline-flex; align-items: center; transform-origin: left 0; transition-delay: 1s; z-index: 1; transition: all 0.5s; opacity: 0; visibility: hidden; }
.project-alt-link:before { width: 22px; height: 2px; background: currentColor; content: ""; margin-right: 10px; display: inline-block; }
.project-alt-content { margin-bottom: 2rem; transition: all 0.5s; }
.project-alt-content h4 { margin-bottom: 0.4rem; transition: all 0.5s; }
.project-alt-content p, .project-alt-content li a { font-size: 16px; font-weight: 400; transition: all 0.5s; }
.project-alt-content h4, .project-alt-content p, .project-alt-content a { color: #000000; }
.project-alt-content h4:hover > a { color: #ffffff; }
.project-alt-content li { display: inline-block; }
.project-alt-content li a:hover { color: #ffffff; }
.project-alt-button { margin-top: 60px; }
.project-alt-tag-link li a { position: relative; padding: 0 5px; }
.project-alt-tag-link li a:after { position: absolute; content: "/"; top: 50%; left: 100%; transform: translateY(-50%); color: #000000; }
.project-alt-tag-link li a:hover:after { color: #000000; }
.project-alt-tag-link li:last-child a::after { display: none; }

.filter-left { text-align: left; }
.filter-left li:first-child { padding-left: 0; }

@media (min-width: 576px) { .project-filter li { padding: 0 15px; }
  .project-filter-s2 li { padding-bottom: 0; }
  .project-filter-s3 { margin: 0 -15px; }
  .project-md { padding-bottom: 50px; } }
@media (min-width: 992px) { .project-filter { padding: 20px 0 30px; }
  .project-filter li { padding: 0 25px; }
  .project-filter-s2 { padding-top: 0; }
  .project-filter-s3 { margin: 0 -25px; }
  .project-md { padding-bottom: 50px; }
  .project-call h2 { margin-bottom: 0px; margin-top: 0; } }
@media (min-width: 1200px) { .project-filter li { padding: 0 32px; }
  .project-filter-s2 li { padding: 0 15px; }
  .project-filter-s3 { margin: 0 -32px; } }
.filtr-item a { display: block; }

.project-item { position: relative; text-align: center; }
.project-wrapper-s2 .project-item, .project-more .project-item { max-width: 400px; margin-left: auto; margin-right: auto; overflow: hidden; }
.project-item:hover .project-over { transform: translateY(0); opacity: 1; }
.project-item:hover .project-image img { transform: scale(1.1); }
.project-wrapper-s2 .project-item:hover .project-image img { transform: scale(1); }
.project-item:hover .project-content { transform: translateY(0); opacity: 1; transition: .5s; }
.project-item h4 { font-size: 1.125rem; color: #ff4157; }
.project-item p { font-size: .875rem; color: #4b4b4b; }
.bg-dark-alt .project-item p { color: #ffffff; }
.project-image { width: 100%; overflow: hidden; }
.project-image img { width: 100%; transition: .5s; }
.project-box-content h2 { margin-bottom: 20px; }
.project-box-content h4 { margin-bottom: 15px; }
.project-s2 [class*="col-"] { padding-bottom: 28px; }
.project-content { opacity: 1; }
.project-over { position: absolute; left: 15px; top: 15px; right: 15px; bottom: 15px; background: rgba(255, 255, 255, 0.9); display: flex; align-items: center; justify-content: center; width: calc(100% - 30px); transition: all .3s; opacity: 0; transform: translateY(30px); }
.bg-dark .project-over, .bg-dark-alt .project-over { background: rgba(30, 32, 47, 0.9); color: #ffffff; }
.project-over-s2 { left: 0; top: 0; right: 0; bottom: 0; width: 100%; background: rgba(35, 9, 133, 0.9); }
.bg-ocean-pattern .project-over-s2 { background: rgba(74, 12, 199, 0.9); }
.project-alt .project-content { opacity: 1; transform: translateY(0); }
.project-alt .project-content h4 { font-size: 1rem; line-height: 1.5; text-transform: capitalize; margin-bottom: 0; }
.project-alt .project-item:hover .project-btn { opacity: 1; width: 100%; }
.project-alt .project-item:hover .project-content { transform: translateY(0); }
.project-alt .project-item:hover .project-over { width: 100%; }
.project-alt .project-image { position: relative; }
.project-alt .project-over { position: static; opacity: 1; transform: scale(1); background: transparent; margin: 20px 0 0; width: 100%; }
.project-v5 [class*="col-"] { padding: 0; }
.project-btn { position: absolute; width: 100%; height: 100%; left: 0; top: 0; display: inline-block; background: rgba(20, 29, 37, 0.8); opacity: 0; }
.project-btn i { border: 1px solid #ff4157; padding: 13px; width: 45px; height: 45px; left: 0; top: 0; margin: auto; position: absolute; right: 0; bottom: 0; transition: all ease .3s; color: #ff4157; }
.project-btn i:hover { border-color: #ffffff; color: #ffffff; }
.project-box-pad { padding: 30px; }

@media (min-width: 576px) { .project-s2 { margin-left: -15px; margin-right: -15px; }
  .project-wrapper-s2 .project-item { max-width: 400px; margin-left: auto; margin-right: auto; overflow: hidden; } }
@media (min-width: 768px) { .project-filter-s2 li { font-size: 1.333rem; } }
@media (min-width: 992px) { .project-box-pad { padding: 50px 40px 50px; } }
@media (min-width: 1600px) { .project-over { position: absolute; left: 30px; top: 30px; right: 30px; bottom: 30px; width: calc(100% - 60px); }
  .project-over-s2 { left: 0; top: -30px; right: 0; bottom: 0; width: 100%; padding-top: 30px; } }
/**  05.6 Testimonial **/
.tes-bg { background: #19232a; }

.tes-thumb { position: static !important; height: 350px; }
.tes-thumb .bg-image  { left: 15px !important; right: 15px !important; }
.tes-block { padding: 20px; position: relative; }
.tes-block::before { position: absolute; content: url("../images/quotes-alter.png"); right: 40px; bottom: -30px; }
.tes-block-transparent { background: transparent; }
.tes-block-transparent:before { display: none; }
.tes-block .author-con p { font-size: .75rem; font-weight: 300; }
.tc-bunker .tes-block .author-con p { color: #ffffff !important; }
.tes-block .author-name { font-size: .875rem; line-height: 1.25rem; font-weight: 600; margin-bottom: 0; }
.tes-block .author-name-s2 { font-size: 1rem !important; }
.tes-content { font-size: 1.125rem; font-weight: 500; margin-bottom: 20px; }
.tes-content .lead { font-style: italic; line-height: 1.5; font-size: 1rem; }
.tc-light .tes-content p { color: #ffffff !important; }
.tc-bunker .tes-content-s2 p { color: #a2a9bf !important; }
.tes-content-box { position: relative; margin-bottom: 56px; }
.tes-content-box:after { width: 0; height: 0; border-right: 33px solid transparent; border-bottom: 33px solid #fff; border-left: 0px solid transparent; position: absolute; content: ""; bottom: -33px; left: 56px; transform: rotate(90deg); }
.bg-dark-alt .tes-content-box:after { border-bottom: 33px solid #0E161D; }
.tes-content-box blockquote { margin-bottom: 40px; }
.tes-title { margin-bottom: 7px; }
.tes-author .author-image { max-width: 60px; border-radius: 50%; height: 100%; overflow: hidden; }
.tes-author .author-con { font-size: .75rem; margin-left: 20px; }
.tes-author .author-con p { font-weight: 300; line-height: 1.667; }
.tes-author .author-con .tc-light { color: #ffffff; }
.tes-author .author-con-s2 .author-name { color: #ff4157 !important; }
.tes-author .author-name { font-weight: 600; font-size: 1rem; color: #ff4157; margin: 0; }
.tes-content-box .tes-author .author-name { color: #131313; font-weight: 900; }
.tes-content-box .tes-author .author-desig { font-size: 16px; color: #4a4a4a; }
.tes-s1 .tes-block { overflow: hidden; }
.tes-s1 .slick-arrow { background: #e4eaed; width: 40px; height: 40px; border-radius: 0; opacity: 1; padding: 9px; font-size: 1rem; }
.tes-s1 .slick-arrow .icon { color: #4b4b4b; }
.tes-s1 .slick-arrow:hover { background: #c5d2d9; }
.bg-dark-alt .tes-s1 .slick-arrow { background: #17212b; }
.bg-dark-alt .tes-s1 .slick-arrow .icon { color: #ffffff; }
.bg-dark-alt .tes-s1 .slick-arrow:hover { background: #293b4c; }
.tes-s1 .slick-next { right: -15px; }
.tes-s1 .slick-prev { left: -15px; }
.tes-s2 { position: relative; }
.tes-s2 .tes-block { padding: 30px; }
.tes-s2 .tes-block::before { display: none; }
.tes-s2 .tes-content { margin: 10px 0 30px; }
.tes-s2 .author-con { display: flex; align-items: center; }
.tes-s2 .author-con h6 { font-size: 1rem; margin-bottom: 0; }
.tes-s2 .author-con .img { max-width: 54px; }
.tes-s2 .author-con .content { margin-left: 15px; }
.tes-s2 .slick-arrow { width: 40px; height: 40px; background: #ff4157; color: #ffffff; border-radius: 0; opacity: 1; bottom: 0px; top: auto; transform: translate(0); padding: 0; font-size: 16px; padding: 8px; position: static; }
.tes-s2 .slick-next { right: -43px; }
.tes-s2 .tes-arrow { position: absolute; right: 0; bottom: 0; }
.tes-s2 .slick-prev { right: 0; left: auto; }
.tes-s3 .tes-block { padding: 40px 20px; }
.tes-s3 .tes-block::before { content: url("../../assets/images/quotes.png"); left: 50px; top: 15px; right: auto; bottom: auto; z-index: 1; }
.tes-s3 .tes-block .author-con p:not(.tc-light) { color: #131313; }
.tes-s3 .tes-block .author-name { margin-bottom: 5px; line-height: 1.5; }
.tes-s3 .tes-content { padding: 50px 35px 40px; }
.tes-s3-alt .tes-block { padding: 40px 0px; }
.tes-s3-alt .tes-block::before { left: 40px; }
.tes-s3-alt .slick-list { overflow: hidden; }
.tes-s3-alt .slick-list .slick-slide { opacity: 1; }
.tes-s4 .tes-block { padding: 0 20px; }
.tes-s4 .tes-content { padding: 30px; }
.tes-block-s3 h2 { margin-bottom: 20px; }

.project-preview { position: relative; }
.project-preview .slick-slide > div { display: flex; }

.tes-arrow { display: none; }

.tes-arrow.active { display: inline-block; }
.tes-arrow-s1, .tes-arrow-s2 { position: absolute; left: 0; top: 50%; width: 100%; height: auto; }
.tes-arrow-s1 .slick-arrow, .tes-arrow-s2 .slick-arrow { width: 50px; height: 50px; border-radius: 0; opacity: 1; background: transparent; z-index: 1; padding: 0; line-height: 48px; }
.tes-arrow-s1 .slick-arrow::before, .tes-arrow-s2 .slick-arrow::before { position: absolute; left: 0; top: 0; right: 0; bottom: 0; background: #230985; opacity: 0.5; content: ""; z-index: -1; }
.tes-arrow-s1 .slick-arrow i, .tes-arrow-s2 .slick-arrow i { color: #ffffff; font-size: 1rem; opacity: 1; transition: all 0.4s; }
.tes-arrow-s1 .slick-arrow.slick-prev, .tes-arrow-s2 .slick-arrow.slick-prev { left: 15px; }
.tes-arrow-s1 .slick-arrow.slick-next, .tes-arrow-s2 .slick-arrow.slick-next { right: 15px; }
.tes-arrow-s1 .slick-arrow:hover i, .tes-arrow-s2 .slick-arrow:hover i { color: #ff4157; }
.tes-arrow-s2 .slick-arrow:before { background: #373539; }

.author-image-lg { width: 90px; height: 90px; }
.author-image-serv { margin: 20px 0; width: 70px; height: 70px; }
.text-center .author-image-serv { margin: 20px auto; }
.author-name-s2 { font-size: 1rem; margin-bottom: 3px; font-weight: 600; }
.author-name-s2 + p { font-size: 12px; font-weight: 300; }
.author-con-s2 { display: flex; padding-top: 0px; flex-direction: column; }
.author-con-s3 { display: flex; align-items: center; }
.author-video { padding-right: 24px; }

@media (min-width: 576px) { .tes-s3-alt { max-width: none; margin: auto -15px; }
  .tes-s3-alt .tes-block { padding: 40px 20px; } }
@media (min-width: 768px) { .tes-content .lead { font-size: 1.5rem; }
  .tes-content-box .lead { font-size: 1.2rem; }
  .tes-thumb { height: 100%; }
  .tes-block { padding: 40px 30px; }
  .tes-s1 .slick-arrow { top: 45%; }
  .tes-s3 .tes-block { padding: 55px 15px; }
  .tes-s3 .tes-block::before { top: 30px; }
  .tes-s3 .tes-block-transparent { padding: 0 15px; }
  .tes-s3-alt .tes-block { padding-bottom: 0; }
  .item-s2 { padding: 0 15px; } }
@media (min-width: 992px) { .tes-content { margin-bottom: 20px; }
  .tes-content-box { margin-bottom: 56px !important; }
  .tes-block { padding: 55px 60px; }
  .tes-block-transparent { padding: 0 15px; }
  .tes-block-s3 h2 { margin-bottom: 40px; }
  .tes-s1 .slick-next { right: -85px; }
  .tes-s1 .slick-prev { left: -85px; }
  .tes-s3 .tes-block { padding: 55px 15px; }
  .tes-s3 .tes-block-transparent { padding: 0 15px; }
  .tes-s3 .tes-block::before { top: 35px; }
  .tes-s3 .tes-content { padding: 55px 37px; }
  .tes-s3-alt .tes-block { padding-bottom: 0; }
  .tes-s3-alt .tes-block::before { left: 50px; }
  .item-s2 { padding: 0 30px; }
  .author-con-s3 { padding-top: 20px; } }
@media (min-width: 1200px) { .tes-content { margin-bottom: 26px; }
  .tes-content-box { margin-bottom: 56px; }
  .tes-block .author-name { font-size: 1rem; margin-bottom: 5px; }
  .tes-s2 .tes-block { padding: 70px 80px; } }
@media (min-width: 320px) and (max-width: 480px) { .tes-content-box .tes-author .author-desig { font-size: 14px; }
  .tes-content-box .tes-author .author-name { font-size: 16px; } }
/**  05.7 Team **/
.team-single { margin-bottom: 0; position: relative; max-width: 400px; margin: auto; }
.team-content { padding-top: 25px; }
.team-content .team-name, .team-content h5 { font-size: 1rem; color: #131313; margin-bottom: 5px; }
.tc-bunker .team-content .team-name, .tc-bunker .team-content h5 { color: #ffffff; }
.team-content .color-primary { color: #ff4157; }
.team-content p { font-size: .875rem; font-weight: 300; }
.tc-bunker .team-content-s2 .team-name, .tc-bunker .team-content-s2 h5 { color: #ff4157 !important; }
.team-content-s2 p { color: #4b4b4b !important; }
.bg-dark-alt .team-content-s2 p { color: #a2a9bf !important; }
.team-content-s2-alt { padding: 25px 0; }
.team-pad { padding: 30px 20px; }
.team-hover { position: absolute; content: ""; left: 30px; top: 30px; right: 30px; bottom: 30px; background: rgba(255, 65, 87, 0.8); display: flex; align-items: flex-end; padding-left: 30px; padding-bottom: 25px; transform: translateY(30px); opacity: 0; transition: 0.5s; }
.team-single:hover .team-hover { transform: translateY(0); opacity: 1; }
.team-hover .team-name { font-size: 1.5rem; font-weight: 900; margin-bottom: 0; }
.team-hover .team-desig { font-size: 1rem; font-weight: 400; }
.team-image { width: 100%; overflow: hidden; position: relative; }
.team-image img { transition: 0.5s; max-width: 100%; }
.team-s2 .team-image img { width: 100%; }
.team-single:hover .team-image img { transform: scale(1.1); }
.team-show { position: absolute; left: 0; top: 0; content: ''; height: 100%; width: 100%; background: transparent; transition: all .4s; z-index: 20; opacity: 1; }
.team-single:hover .team-show { background: rgba(255, 65, 87, 0.4); }
.team-popup { position: relative; max-width: 100%; margin: 10px; }
.team-popup-info { padding: 30px; }
.team-popup-info p:last-child { margin-bottom: 0; }
.team-social { margin-bottom: 10px; margin-left: -10px; }
.team-social li { display: inline-block; padding: 10px; }

.mfp-close { cursor: pointer; top: -15px; right: -15px; height: 36px; width: 36px; line-height: 37px; padding: 0; text-align: center; z-index: 9999; border-radius: 50%; color: #415076 !important; opacity: 1; background: #fff; box-shadow: 0px 2px 7px 0px rgba(65, 80, 118, 0.2); transition: all .4s; }
.mfp-container { overflow: auto; }

.logo-item { max-width: 140px; width: auto; margin-left: auto; margin-right: auto; }
.logo-item-s2 { padding: 20px; max-width: 100%; height: 100%; box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.09); }

.section-logo { padding: 70px 0; }
.section-logo-dark { padding: 15px 0; }

@media (min-width: 450px) and (max-width: 575px) { .bg-ocean-pattern .logo-item { padding: 0 15px; }
  .section-logo-pdx .logo-item { padding: 0 15px; } }
@media (min-width: 575px) { .team-hover .team-name { font-size: 1.3rem; } }
@media (min-width: 768px) { .team-content { padding: 25px 0 0; }
  .team-content-s2-alt { padding: 25px 0; }
  .team-popup-info { padding: 30px 60px; }
  .team-hover .team-name { font-size: 1.5rem; }
  .logo-item-s2 { padding: 25px; } }
@media (min-width: 992px) { .team-pad { padding: 50px 40px; }
  .team-hover { padding-left: 70px; padding-bottom: 63px; padding-right: 20px; }
  .team-hover .team-name { font-size: 2rem; }
  .team-hover .team-desig { font-size: 1.333rem; }
  .team-popup { max-width: 950px; margin: auto; } }
@media (min-width: 1200px) { .team-hover { padding-left: 30px; padding-bottom: 25px; }
  .team-hover .team-name { font-size: 1.5rem; }
  .team-hover .team-desig { font-size: 1rem; }
  .logo-item { max-width: 190px; }
  .logo-item-s2 { padding: 30px 45px; } }
@media (min-width: 1600px) { .team-pad { padding: 50px 40px; }
  .team-hover { padding-left: 70px; padding-bottom: 63px; padding-right: 20px; }
  .team-hover .team-name { font-size: 2rem; }
  .team-hover .team-desig { font-size: 1.333rem; } }
/**  05.8 Cta **/
.cta-heading { font-size: 1.875rem; }
.cta-text h2 { font-weight: 300; font-size: 30px; }
.cta-text-s2 h2 { font-weight: 300; }
.cta-text-s2 h2 strong { display: block; }
.cta-text-s3 h2 { font-size: 1.5rem; line-height: 1.5; font-weight: 400; }
.cta-text-s3 h2 strong { display: block; font-weight: 700; }
.cta-text-s4 h2 { font-weight: 700; }
.cta-s1 .social li:not(:last-child) { margin-right: 10px; }
.cta-s1 .social li a { font-size: 14px; width: 30px; height: 30px; padding: 8px 0; }
.cta-s3 p { margin-bottom: 40px; }
.cta-btn .btn { padding: 14px 34px 13px; }
.cta-btn-s3 .btn { padding: 13px 40px 12px; border: 2px solid #ffffff; }
.cta-btn-s3 .btn:hover { background: #ff4157; border-color: #ff4157; }

@media (min-width: 576px) { .cta-text h2 { font-size: 2.25rem; line-height: 50px; }
  .cta-box p + .btn { margin-top: 10px; } }
@media (min-width: 768px) { .cta-btn .btn { padding: 18px 40px 17px; }
  .cta-btn-s3 .btn { padding: 12px 40px 13px; }
  .cta-text-s2 h2 strong { margin-top: -5px; font-size: 3rem; }
  .cta-text-s3 h2 strong { font-size: 3rem; line-height: 1.25; margin-bottom: 7px; }
  .cta-s1 .social li:not(:last-child) { margin-right: 15px; }
  .cta-s1 .social li a { font-size: 18px; width: 50px; height: 50px; padding: 14px 0; } }
@media (min-width: 992px) { .cta-heading { font-size: 3rem; margin-bottom: 15px; }
  .cta-btn .btn { padding: 24px 54px 23px; }
  .cta-btn-s3 .btn { padding: 12px 43px 13px; }
  .cta-box p { font-size: 1.5rem; margin-bottom: 30px; }
  .cta-box p + .btn { margin-top: 15px; }
  .text-center .cta-box p + a { margin-top: 0; }
  .cta-box-s3 h2 { font-size: 2.25rem; line-height: 1.667; font-weight: 400; }
  .cta-box-s3 h2 strong { font-size: 3rem; display: block; font-weight: 700; margin-bottom: 15px; }
  .cta-s3 p { margin-bottom: 40px; }
  .cta-text-s4 h2 { font-size: 2.67rem; }
  .section-cta-alt .cta-heading { font-size: 3rem; line-height: 1.25; margin-bottom: 20px; } }
@media (min-width: 1200px) { .cta-heading { font-size: 4.5rem; margin-bottom: 15px; line-height: 0.833; margin-bottom: 28px; } }
/**  05.9 Post **/
.post .social a { background: #ff4157; border-color: #ff4157; }
.post-v1 .post-content { padding: 35px 0 0; }
.post-content { padding: 15px 0; }
.post-content h4 { color: #131313; }
.post-content h4 a { color: inherit; }
.post-content h4 a:hover { color: #ff4157; }
.tc-bunker .post-content h4 { color: #ffffff; }
.post-content-s2 { padding: 35px 40px 33px; }
.post-content-s2 .content { font-weight: 400; font-size: 0.89rem; }
.post-content-s2 .post-title { font-weight: 700; font-size: 1.1rem !important; line-height: 1.44 !important; }
.post-tag { font-size: .75rem; color: #94aeb8; }
.post-content-s2 .post-tag { font-weight: 400; }
.post-date { font-weight: 300; color: #94aeb8; margin-bottom: 10px; }
.post-full-s2 .post-date { line-height: normal; }
.post-alt { background: #ffffff; margin-bottom: 0; height: 100%; }
.post-alt .post-thumb { max-width: 100%; }
.post-alt .post-tag { margin-bottom: 6px; }
.post-alt .post-content { padding: 20px; margin: 0; }
.post-alt .post-content-s2 { padding: 30px; }
.post-alt .post-content h4 { line-height: 1.667; }
.post-full .post-thumb { max-width: 100%; }
.post-full .post-content .content { padding-bottom: 15px; }
.post-full .post-content p { color: #4b4b4b; }
.tc-bunker .post-full .post-content p { color: #a2a9bf; }
.post-full .post-content h3 { font-size: 1.25rem; margin: 15px 0 12px; }
.post-full .post-content h3 a { color: #131313; }
.post-full .post-content h3 a:hover { color: #ff4157; }
.tc-bunker .post-full .post-content h3 a { color: #ffffff; }
.tc-bunker .post-full .post-content h3 a:hover { color: #ff4157; }
.bg-ocean-pattern .tc-bunker .post-full .post-content h3 a:hover { color: #ff4157; }
.post-full .post-content h4 { font-size: 1.125rem; }
.post-full .post-entry { position: relative; padding: 40px 0 50px; }
.post-full .post-date { background: #ff4157; color: #ffffff; flex-shrink: 0; height: 50px; width: 50px; text-align: center; font-size: .875rem; font-family: "Poppins", sans-serif; position: absolute; top: -50px; }
.bg-ocean-pattern .post-full .post-date { background: #ff4157; }
.post-full .post-date span, .post-full .post-date strong { display: block; font-size: 1rem; font-weight: 700; }
.tc-bunker .post-full .post-date p { color: #ffffff; }
.post-full .post-author .author-thumb { max-width: 45px; }
.post-full .post-author .author-thumb img { border-radius: 50%; }
.post-full .post-author .author-name { font-size: 0.875rem; margin-left: 10px; }
.post-full .post-tag { color: #131313; }
.post-full .post-tag a { color: #94aeb8; display: inline-flex; align-items: center; }
.post-full .post-tag a:hover { color: #ff4157; }
.bg-ocean-pattern .post-full .post-tag a:hover { color: #ff4157; }
.post-full .post-tag .icon { color: #ff4157; margin-right: 5px; font-size: 18px; }
.bg-ocean-pattern .post-full .post-tag .icon { color: #ff4157; }
.post-full .post-tag .icon.ti-comment { margin-right: 10px; }
.post-full .post-cat { margin-right: 20px; }
.post-full-s2 .post-content { padding-top: 0; }
.post-full-s2 .post-content .author-name p { color: #ffffff; }
.tc-bunker .post-full-s2 .post-content .author-name p { color: #ffffff; }
.post-full-s2 .post-cat li a { color: #ffffff; font-size: 14px; }
.post-details .social li { display: inline-block; margin-right: 0; }
.post-details .social li:not(:last-child) { margin-bottom: 10px; }
.post-details .social li a { width: 35px; height: 35px; font-size: 16px; padding: 8px 0; }
.post-details .social li a:hover { color: #ffffff; background: #ff4157; border-color: #ff4157; }
.post-details .social li .fac { background: #5678bf; border-color: #5678bf; }
.post-details .social li .twi { background: #73c2fd; border-color: #73c2fd; }
.post-details .social li .goo { background: #dd4b39; border-color: #dd4b39; }
.post-details .social li .pin { background: #c32026; border-color: #c32026; }
.post-details .post-date { margin-right: 10px; }
.post-details .post-author { margin-bottom: 20px; }
.post-details .block-text { font-size: 1.125rem; color: #94aeb8; padding-left: 20px; border-left: 2px solid #ff4157; margin: 30px 0; }
.bg-ocean-pattern .post-details .block-text { border-left: 2px solid #ff4157; }
.post-details .block-text em { color: #94aeb8; }
.post-v1 { display: block; }
.post-v2 .post-content { padding: 0 20px 20px; }
.post-v2 .post-content-s2 { padding: 0 20px 40px; }
.post-v2 .post-entry { padding: 10px 0 0; }
.post-v2 .post-author { padding: 10px 0; margin-right: 20px; }
.post-v3 .block-text { font-size: 1.25rem; font-style: italic; color: #131313; margin: 20px 0; }
.post-btn { margin-top: 60px; }
.post-thumb-s2 img { width: 100%; height: 100%; object-fit: cover; }
.post-thumb img { width: 100%; }

.post-full-s2 .block-text em { color: #ffffff; }

@media (min-width: 576px) { .post-content { margin-left: 20px; padding-top: 0; }
  .post-alt .post-content h4 { margin: 8px 0 0px; }
  .post-alt .post-content h4.post-title { padding-bottom: 10px; }
  .post .social { padding-top: 30px; }
  .post-full .post-date { position: static; }
  .post-details .social li { display: block; }
  .post-v2 .post-date { position: absolute; }
  .post-v2 .post-content { margin-left: 0; } }
@media (min-width: 768px) { .post-v1 .post-content { padding: 15px 0; }
  .post-v1 .post-content h4 { margin-bottom: 16px; }
  .post-content { margin-left: 30px; }
  .post-content h4 a { font-size: inherit; }
  .post-alt { margin-bottom: 0; }
  .post-alt .post-content h4 { line-height: 1.444; font-size: 1rem; }
  .post-alt .post-content h4 + .btn { margin-top: 7px; }
  .archive .post-alt .post-thumb { height: auto; max-width: 100%; }
  .post-alt .post-thumb-inline { max-width: 40%; height: 100%; }
  .post-thumb { max-width: 270px; flex-shrink: 0; }
  .post-v1 { display: flex; }
  .post-v1 .post-content { padding: 9px 0; } }
@media (min-width: 992px) { .post { padding: 0; }
  .post-alt .post-content { padding: 35px 40px 33px; }
  .post-alt .post-content h4 { font-size: 1.125rem; }
  .post-alt .post-content-s2 { padding: 30px; }
  .post-alt .post-thumb { max-width: 100%; height: auto; }
  .post-full .post-date { width: 90px; height: 90px; padding: 15px; }
  .post-full .post-date strong { font-size: 1.875rem; }
  .post-full .post-content h3 { font-size: 1.5rem; }
  .post-details .post-author { margin-bottom: 0px; margin-right: 30px; }
  .post-v2 .post-date { top: -90px; } }
@media (min-width: 1200px) { .post-alt .post-content { padding: 35px 40px 33px; }
  .post-alt .post-content-s2 { padding: 30px; } }
@media (min-width: 1600px) { .post-alt .post-content-s2 { padding: 35px 40px 33px; } }
.feed { padding: 30px; border: 1px solid #e2e8eb; }
.bg-dark-alt .feed { border: 1px solid rgba(81, 80, 126, 0.6); }
.feed-icon { max-width: 60px; margin-right: 26px; }
.feed-block:not(:last-child) { margin-bottom: 30px; }
.feed-block ul li .user { display: flex; font-size: 1.125rem; align-items: center; }
.feed-block ul li .user a { margin-right: 20px; color: #4a4a4a; }
.tc-light .feed-block ul li .user a { color: #ffffff; }
.bg-dark-alt .feed-block ul li .user a { color: #ff4157; }
.feed-block ul li:not(:first-child) { margin-top: 30px; }
.feed-block ul li:not(:first-child) .user { display: none; }
.feed-content h4 { margin: 0; }
.feed-content a { font-size: .75rem; color: #4a4a4a; }
.feed-content a:hover { color: #ff4157; }
.tc-light .feed-content a { color: #ffffff; }
.tc-light .feed-content a:hover { color: #ff4157; }
.feed-icon .icon { font-size: 60px; color: #73c2fd; }
.feed p { margin-bottom: 10px; font-size: 1rem; font-weight: 400; line-height: 1.5; color: #4a4a4a; }
.feed p:last-child { margin-bottom: 0; }
.tc-bunker .feed p { color: #a2a9bf; }
.feed p a { color: #131313; font-weight: 500; }
.feed p a:hover { color: #ff4157; }
.tc-light .feed p a { color: #ffffff; }
.tc-light .feed p a:hover { color: #ff4157; }
.tc-bunker .feed p a { color: #ffffff; }
.tc-bunker .feed p a:hover { color: #ff4157; }
.feed .timePosted a { color: #94aeb8; font-size: 1rem; font-weight: 400; }
.feed .timePosted a:hover { color: #ff4157; }

@media (min-width: 768px) { .feed-content a { font-size: .875rem; color: #4a4a4a; }
  .bg-dark-alt .feed-content a { color: #a2a9bf; }
  .bg-dark-alt .feed-content a:hover { color: #ff4157; } }
@media (min-width: 992px) { .feed { padding: 60px; } }
.wgs-sidebar { padding: 40px; }
.wgs-sidebar .form-group { position: relative; }
.wgs-sidebar .form-group .search-btn { position: absolute; right: 0px; height: 100%; top: 0px; background: transparent; width: 50px; color: #131313; border: 0px; transition: color 0.4s; }
.bg-dark-alt .wgs-sidebar .form-group .search-btn, .bg-dark .wgs-sidebar .form-group .search-btn { color: #ffffff; }
.wgs-sidebar .form-group .search-btn:hover { color: #ff4157; }
.wgs-sidebar .form-input { border: 1px solid #e5e5e5; min-height: 60px; color: #131313; font-size: 1rem; width: 100%; padding: 10px 25px; }
.bg-dark-alt .wgs-sidebar .form-input::placeholder, .bg-dark .wgs-sidebar .form-input::placeholder { color: #a2a9bf; }
.wgs-sidebar .form-input:focus { box-shadow: none; }
.bg-dark-alt .wgs-sidebar .form-input, .bg-dark .wgs-sidebar .form-input { background: transparent; color: #ffffff; border-color: rgba(226, 232, 235, 0.18); }
.wgs-sidebar .form-input-fill { border: none; border-radius: 0; }
.wgs-sidebar .form-input-fill:focus { box-shadow: none; border: none; outline: none; }
.wgs-sidebar .form-input-fill + .search-btn { top: 3px; }
.wgs-sidebar-s2 { padding: 0; }
.wgs-sidebar-s2 .post-recent li { border-bottom: 1px solid #e2e8eb; padding-bottom: 20px; }
.wgs-heading { font-size: 1.25rem; margin-bottom: 20px; }
.wgs-content .post-recent .post-tag { color: #94aeb8; }
.wgs-content .post-recent li:not(:last-child) { margin-bottom: 20px; }
.wgs-content h5 { font-size: 1rem; margin-bottom: 10px; line-height: 1.5; }

.tag-list li { margin: 5px 5px 5px 0; display: inline-flex; }
.tag-list li a { padding: 3px 14px; color: #4b4b4b; font-size: .75rem; text-transform: capitalize; background: #ffffff; }
.bg-dark-alt .tag-list li a, .bg-dark .tag-list li a { background: #0a1015; color: #ffffff; }
.tag-list li a:hover { background: #ff7484; color: #ffffff; }
.tag-list-s2 li a { background: #230985; }
.bg-dark-alt .tag-list-s2 li a { background: #230985; color: #ffffff; padding: 3px 19px; }
.tag-list-s2 li a:hover { background: #ff4157; }

.wgs li a .base { color: #ff4157; }
.wgs-sidebar { padding: 20px; }
.wgs-sidebar-s2 { padding: 0; }
.wgs-search { padding: 0; }
.wgs-recents ul li a { color: #131313; }
.wgs-recents ul li a:hover { color: #ff4157; }
.tc-bunker .wgs-recents ul li a { color: #a2a9bf; }
.tc-bunker .wgs-recents ul li a:hover { color: #ff4157; }
.bg-ocean-pattern .tc-bunker .wgs-recents ul li a:hover { color: #ff4157; }
.wgs-recents-s2 ul li a { color: #ffffff; }
.tc-bunker .wgs-recents-s2 ul li a { color: #ffffff; font-weight: 600; }
.wgs-category-s2 { padding-bottom: 30px; }
.wgs-tags-s2 .wgs-content .tag-list a { background: #f2f2f2; font-size: 15px; padding: 8px 18px; }

@media (min-width: 992px) { .wgs-sidebar { padding: 40px; }
  .wgs-sidebar:not(:last-child) { margin-bottom: 40px; }
  .wgs-sidebar-s2 { padding: 0; }
  .wgs-search { padding: 0; }
  .wgs-category-s2 { padding-bottom: 60px; }
  .tag-list li a { padding: 3px 20px; } }
.comments { margin-top: 40px; }
.comments-list > li:not(:last-child) { border-bottom: 1px solid #e2e8eb; }
.bg-dark-alt .comments-list > li:not(:last-child), .bg-dark .comments-list > li:not(:last-child) { border-color: rgba(226, 232, 235, 0.18); }
.comments-list .media { color: #4b4b4b; }
.comments-list .media img { width: 40px; height: 40px; border-radius: 50%; margin-right: 15px; }
.comments-list .media-name { font-weight: 700; margin-bottom: 10px; color: #131313; }
.bg-ocean-pattern .comments-list .media-name { color: #ffffff; }
.comments-list .media-name span { color: #94aeb8; font-weight: 400; }
.bg-ocean-pattern .comments-list .media-name span { color: #ffffff; }
.comments-list .media a { color: #ff4157; font-weight: 700; text-transform: capitalize; }
.bg-ocean-pattern .comments-list .media a { color: #ff4157; }
.comments-list .media .media { border-bottom: none; border-top: 1px solid #e2e8eb; }
.bg-dark-alt .comments-list .media .media { border-color: rgba(226, 232, 235, 0.18); }
.comments-list .media .comment-content { padding-bottom: 25px; }
.comments-list .comments-row { padding-top: 25px; }
.comments-list .comments-row > ul > li { padding-left: 50px; border-top: 1px solid #e2e8eb; }
.bg-dark-alt .comments-list .comments-row > ul > li, .bg-dark .comments-list .comments-row > ul > li { border-color: rgba(226, 232, 235, 0.18); }
.comments-form { margin-top: 40px; }
.comments-form .form-text { margin-bottom: 40px; }
.comments-form .input { border: none; }
.comments-form .input-bordered { border: 1px solid #5647cb; }
.comments-form-s2 .input-bordered { border-color: rgba(120, 119, 140, 0.25); }

.comments-form-visible .input { border: 1px solid #e5e5e5; }

.comment-content p { margin-bottom: 8px; }

@media (min-width: 992px) { .comments { margin-top: 100px; }
  .comments-list .media img { width: 90px; height: 90px; margin-right: 30px; }
  .comments-list .comments-row > ul > li { padding-left: 120px; }
  .comments-form { margin-top: 90px; }
  .comments-form .form-text { margin-bottom: 40px; } }
.pagination { display: block; text-align: center; }
.pagination-area { margin: 0; padding-bottom: 30px; }
.pagination li { margin-left: 10px; display: inline-block; }
.pagination li a { padding: 9px 17px; border: 1px solid #e5e5e5; color: #131313; display: block; }
.bg-dark-alt .pagination li a { border: none; color: #ffffff; background: #121d26; }
.bg-ocean-pattern .pagination li a { border: 1px solid #92a6e4; color: #92a6e4; background: transparent; }
.pagination li.active a, .pagination li:hover a { background: #ff4157; color: #ffffff; }
.bg-ocean-pattern .pagination li.active a, .bg-ocean-pattern .pagination li:hover a { border: 1px solid transparent; }
.pagination-s3 li a { border: 1px solid #ffffff; color: #a2a9bf; background: transparent; }
.pagination-s3 li.active a, .pagination-s3 li:hover a { background: #ff4157; color: #ffffff; border: 1px solid transparent; }

@media (min-width: 576px) { .content-left + .post-content-wd { Width: calc(100% - 130px); } }
@media (min-width: 768px) { .pagination-area { margin: 15px; padding-bottom: 0px; } }
/**  05.10 Career **/
.btn-arrow-alt { font-size: 1.5rem; font-weight: 400; color: #4a4a4a; }
.tc-bunker .btn-arrow-alt { color: #a2a9bf; }
.btn-arrow-alt .icon { color: #ff4157; padding-left: 15px; transition: .5s; font-size: 20px; }
.bg-ocean-pattern .btn-arrow-alt .icon { color: #ff4157; }
.btn-arrow-alt:hover { color: #ff4157; }
.btn-arrow-alt:hover .icon { padding-left: 20px; }
.btn-career { display: flex; padding: 20px 30px; border: 1px solid #ecf6fa; color: #131313; font-size: 1.125rem; font-weight: 600; justify-content: space-between; line-height: .8; }
.btn-career em { color: #ff4157; transition: .5s; }
.btn-career:hover { background: #ff4157; color: #ffffff; }
.btn-career:hover em { color: #ffffff; }

@media (min-width: 768px) { .btn-career { font-size: 1.5rem; } }
.section-career h3 { font-weight: 600; }
.section-career h5 { font-weight: 600; }

.career-box h3 { margin-bottom: 50px; line-height: 1.5; }

.career-process { padding: 25px; }
.career-process-icon { position: relative; }
.career-process-icon .serial { font-size: 100px; color: #4b4b4b; z-index: -1; opacity: .1; line-height: .8; }
.career-process-icon img { max-width: 50px; position: absolute; left: 0; top: 50%; right: 0; transform: translateY(-50%); margin: auto; }
.career-process h4 { color: #4a4a4a; }
.career-content-box h5 { margin-bottom: 13px; }

@media (min-width: 768px) { .btn-career { padding: 30px; } }
.accordion { font-weight: 300; }
.accordion-header { background: #ffffff; padding: 20px; cursor: pointer; }
.accordion-header p { margin: 0; color: #4b4b4b; }
.accordion-header p:nth-child(2), .accordion-header p:nth-child(3) { display: none; }
.accordion-header .collapsed .view-btn { opacity: 1; }
.accordion .view-btn { border-color: #ff4157; color: #4b4b4b; opacity: 0; }
.accordion .view-btn .far { color: #ff4157; display: none; }
.accordion .view-btn:hover { background: #ff4157; color: #ffffff; }
.accordion .view-btn:hover .far { color: #ffffff; }
.accordion-item:not(:last-child) { margin-bottom: 10px; }
.accordion-body { background: #ffffff; padding: 0 20px 20px; }
.accordion-box { margin-bottom: 30px; }
.accordion-box h4 { margin-bottom: 20px; color: #4b4b4b; }
.accordion-box a + p { margin-top: 20px; }

@media (min-width: 576px) { .accordion-header { padding: 27px 40px; }
  .accordion-header p:nth-child(2), .accordion-header p:nth-child(3) { display: block; }
  .accordion .view-btn .far { display: inline-block; }
  .accordion-item:not(:last-child) { margin-bottom: 20px; }
  .accordion-body { background: #ffffff; padding: 0 40px 40px; } }
/**  05.11 Contact **/
.contact-text h4 { margin-bottom: 15px; }
.contact-text p.lead { margin-bottom: 10px; }
.contact-text-s2 { padding: 30px; }
.contact-text-s3 { box-shadow: 0px 2px 20px 0px rgba(26, 49, 91, 0.1); }
.contact-text-s3 h4 { font-size: 1.12rem; margin-bottom: 0; }
.contact-text-s3 h4 + p { margin-top: 10px; }
.contact-list li { display: flex; align-items: center; border-bottom: 1px solid #e2e8eb; font-size: 1rem; font-weight: 700; padding: 12px 0; }
.bg-dark-alt .contact-list li, .bg-dark .contact-list li { border-color: rgba(226, 232, 235, 0.18); }
.contact-list li a { color: #131313; }
.tc-light .contact-list li a { color: #ffffff; }
.contact-list li a:hover { color: #ff4157; }
.tc-bunker .contact-list li a { color: #a2a9bf; }
.tc-bunker .contact-text-s4 .contact-list li a { color: #ffffff; }
.bg-ocean-light .contact-list li a { color: #ffffff; }
.contact-icon { font-size: 24px; color: #ff4157; margin-right: 14px; }
.contact-content { font-size: 1rem; line-height: 1.5; }
.contact-content-s3:not(:last-child) { margin-bottom: 30px; }
.contact-content address { margin-bottom: 0; }
.contact-box { padding: 30px; }

.g-map { min-height: 300px; }

.contact-bg-map .bg-image { background-size: 90%; background-position: bottom; }

@media (min-width: 768px) { .contact-text-s2 { padding: 50px; }
  .contact-bg-map .bg-image  { background-size: 75%; background-position: 178% 56%; } }
@media (min-width: 992px) { .contact-text-s3 h4 { font-size: 1.333rem; }
  .contact-text-s4 h4 { font-size: 1.4rem; } }
@media (min-width: 1280px) { .contact-text-s2, .contact-text-s4 { margin-left: 30px; } }
@media (min-width: 1600px) { .contact-text-s2 { padding: 50px; }
  .g-map { min-height: 600px; }
  .contact-text-s3 { box-shadow: 0px 2px 139px 0px rgba(26, 49, 91, 0.25); } }
/**  05.12 form **/
form .input { width: 100%; font-size: 1rem; border: 1px solid #ecf6fa; padding: 18px 20px; margin-bottom: 10px; color: #4b4b4b; background: transparent; }
.genox-form-s2 form .input { color: #4a4a4a; font-weight: 300; }
form .input::placeholder { color: #4b4b4b; opacity: .7; }
form .input::-webkit-input-placeholder { color: #4b4b4b; opacity: .7; }
form .input::-moz-placeholder { color: #4b4b4b; opacity: .7; }
form .input:-ms-input-placeholder { color: #4b4b4b; opacity: .7; }
form .input:focus { box-shadow: none; outline-style: none; }
.bg-dark-alt form .input { color: #a2a9bf; }
.bg-dark-alt form .input::placeholder { color: #a2a9bf; }
.tc-bunker form .input { color: #ffffff; }
.tc-bunker form .input::placeholder { color: #94aeb8; }
form .input-msg { min-height: 80px; resize: none; margin-bottom: 20px; }
form .input-select { position: relative; -webkit-appearance: none; -moz-appearance: none; appearance: none; height: auto; }
form .input-select:focus { outline-style: none; }
form .input-select:not(.bdr-b):focus { border-color: transparent; }
.tc-bunker form .input-select { color: rgba(148, 174, 184, 0.7); }
form .input-upload { background-color: transparent; color: #131313; border: 1px dotted #131313; }
form .input-upload:hover { color: #131313; background: #efefef !important; border-color: transparent; }
form .input-bordered { border: 1px solid currentColor; }
form input[type="file"] { position: absolute; top: 0; right: 0; left: 0; bottom: 0; width: 100%; margin: 0; padding: 0; cursor: pointer; opacity: 0; }
form .file-field .btn { font-size: 12px; }
form .bdr-b { border-color: #131313; padding-left: 0; }
form .bdr-b-light { border-color: #a2b3cd; transition: 0.44s; }
.tc-bunker form .bdr-b-light { border-color: rgba(226, 232, 235, 0.08); }
.tc-bunker form .bdr-b-light:focus { border-color: #e2e8eb; }
form .form-control { border-radius: 0; }
form .form-control:focus { box-shadow: none; }
form .bdr-b:focus { border-bottom-color: #131313; }
form .bdr-light:focus { border-bottom-color: #a2b3cd; }
form .error { border-color: #e6604a !important; }
form label.error { display: none !important; }

.form-select { position: relative; }
.form-select::after { position: absolute; right: 30px; top: 20px; content: '\e64b'; font-family: 'themify'; font-size: 14px; font-weight: 700; z-index: 9; }
.form-dark .input { background: transparent; border-color: rgba(226, 232, 235, 0.18); color: #a2a9bf; }
.form-dark .input:focus { border-color: rgba(226, 232, 235, 0.18); }
.form-dark .input::placeholder { color: #a2a9bf; }
.form-dark .input::-moz-placeholder { color: #a2a9bf; }
.form-dark .input-select { color: #a2a9bf; }
.genox-form-s2 .form-btn { margin-top: 20px; }
.form-ocean .input { border-color: #5647cb; }
.tc-bunker .form-ocean .input { color: #ffffff; }
.tc-bunker .form-ocean .input::placeholder { color: #92a6e4; opacity: 1; }
.form-ocean .input-select { color: #92a6e4; }
.tc-bunker .form-ocean .input-select { color: #92a6e4; padding-bottom: 24px; }

.form-field-s2 .input { font-size: 16px; font-weight: 400; color: #171717; border-bottom: 2px solid #dedede; }
.form-field-s2 .input::placeholder { color: #171717; opacity: 1; }
.form-control:focus { background: transparent; }

@media (min-width: 768px) { form .input { min-height: 60px; }
  form .input-msg { min-height: 120px; } }
@media (min-width: 992px) { form .input { margin-bottom: 30px; }
  form .input-msg { margin-bottom: 35px; }
  .genox-form-s2 form-btn { margin-top: 20px; }
  .form-v2 .input { margin-bottom: 30px; }
  .form-alt .input { margin-bottom: 15px; }
  .form-alt .input-msg { margin-bottom: 40px; } }
@media (min-width: 1200px) { .file-field .btn { font-size: 14px; } }
.header-search { display: block; text-align: center; background: rgba(0, 0, 0, 0.9); opacity: 0; visibility: hidden; position: fixed; top: 0; left: 0; width: 100%; height: 100%; -webkit-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; z-index: 999; cursor: pointer; }

.search-show .header-search { opacity: 1; visibility: visible; }

.search-mobile { font-size: 18px; margin-right: 10px; line-height: 1; position: relative; top: -7px; color: #4a4a4a; }
.is-transparent .search-mobile { color: #ffffff; }
.has-fixed .search-mobile { color: #131313; }
.bg-dark-alt .search-mobile, .bg-dark .search-mobile { color: #ffffff; }
.search-form { width: 100%; position: absolute; top: 50%; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%); }
.search-form label { color: #ffffff; }
.search-form .input-search { background-color: #ffffff; color: #4b4b4b; height: auto; width: 100%; font-size: 18px; line-height: 1; border: none; margin: 0 auto; padding: 20px 50px 20px 30px; width: 100%; }
.search-form .input-search:focus { outline-style: none; }
.search-group { position: relative; max-width: 500px; margin: 0 auto; width: 80%; }
.search-submit { position: absolute; right: 0; top: 3px; background: transparent; border: 0; font-size: 24px; bottom: 0; padding: 0; right: 15px; height: 100%; color: #ff4157; }
.search-submit:hover { color: #ff4157; }
.bg-dark .search-submit:hover, .bg-dark-alt .search-submit:hover { color: #ff4157; }
.search-close { padding: 30px 0; display: inline-block; color: #ffffff; font-size: 30px; }
.search-close:hover { color: rgba(255, 255, 255, 0.7); }

body.search-show { overflow: hidden; }

@media (min-width: 768px) { .search-form .input-search { padding: 30px; }
  .search-submit { right: 25px; }
  .search-group { max-width: 800px; width: 90%; } }
@media (min-width: 992px) { .search-mobile { display: none; } }
/**  05.13 price **/
.pricing-boxed { padding-bottom: 50px; position: relative; transition: all ease .4s; max-width: 320px; margin: auto; max-width: 100%; }
.pricing-boxed-sm-bg { background-color: #ffffff; background-image: url(../images/pricing-bg.png); background-repeat: no-repeat; background-size: contain; background-position: 0 0; background-attachment: scroll; }
.pricing-boxed-s2.active { box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.06); }
.pricing-boxed-s2.active::after { opacity: 1; }
/*.pricing-boxed-s2::after { position: absolute; right: 0; top: 0; content: url(../images/price-icon.png); opacity: 0; transition: all ease .4s; }*/
.pricing-boxed-s2:hover { box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.06); }
.pricing-boxed-s2:hover::after { opacity: 1; }
.pricing-boxed-s3 { background: #ffffff; border: 1px solid #e2e8eb; }
.pricing-boxed:hover { transform: translateY(-8px); }
.pricing-boxed-s4, .pricing-boxed-s5 { display: flex; flex-direction: column; height: 100%; }
.pricing-price { padding: 50px; border-bottom: 1px solid #e2e8eb; }
.bg-dark-alt .pricing-price { border-color: rgba(226, 232, 235, 0.18); }
.tc-bunker .pricing-price { border-color: rgba(226, 232, 235, 0.08); }
.pricing-boxed-s4 .pricing-price { border-color: #230985; }
.pricing-boxed-s5 .pricing-price { border-color: #121b22; }
.pricing-price h2 { font-weight: 400; margin-bottom: 20px; color: #4b4b4b; }
.pricing-price h3 { font-size: 2rem; font-weight: 900; line-height: .5; color: #4b4b4b; }
.pricing-boxed-s4 .pricing-price h3 { font-weight: 700; }
.pricing-price h5 { text-transform: uppercase; letter-spacing: 0.03rem; color: #4b4b4b; margin-bottom: 20px; }
.pricing-price .price-unit { font-size: 1.125rem; vertical-align: top; margin-right: 11px; display: inline-block; color: #4b4b4b; font-weight: 400; }
.pricing-boxed-s4 .pricing-price .price-unit, .pricing-boxed-s5 .pricing-price .price-unit { color: #ff4157; }
.pricing-price .price-unit-lg { font-size: 1.3rem; font-weight: 900; color: #ff4157; }
.pricing-boxed-s4 .pricing-price .price-unit-lg { font-weight: 700; }
.pricing-price .price-for { font-size: 1.2rem; display: block; font-weight: 400; color: #656565; margin-top: 25px; }
.tc-bunker .pricing-price .price-for { color: #a2a9bf; }
.pricing-price-s2 { border: none; padding-bottom: 0; }
.pricing-price-s2 h2 + p { margin-top: 22px; }
.pricing-price-s2 p { font-size: 14px; font-weight: 300; }
.pricing-price-s3 { background: #ecf6fa; border: 0; }
.pricing-feature { padding: 40px 50px; }
.pricing-feature ul li { font-size: 1.125rem; line-height: 2; font-weight: 300; }
.pricing-feature-s2 { padding-top: 20px; }
.pricing-feature-s2 ul li { border-bottom: 1px solid #e2e8eb; font-size: 14px; position: relative; padding: 12px 0 12px 40px; }
.pricing-feature-s2 ul li::before { position: absolute; content: '\f058'; left: 0; font-size: 24px; top: 2px; font-family: "Font Awesome 5 Free"; color: #20cb98; margin: auto 0; }
.pricing-cta { padding: 0 50px; }
.pricing-cta-lg { padding: 0 60px; }
.pricing-cta .btn { display: inline-block; }
.pricing-cta-s2 { display: flex; justify-content: space-between; }
.pricing-cta-s2 .price h2 { color: #ff4157; line-height: 0.867; }
.pricing-cta-s2 .price h2 + p { padding-top: 3px; }
.pricing-cta-s2 .price p { font-size: 12px; }
.pricing-cta-s2 .btn { display: inline-block; }

@media (min-width: 768px) { .pricing-price h3 { font-size: 3.75rem; line-height: .8; }
  .pricing-price .price-unit-lg { font-size: 1.7rem; }
  .pricing-price .price-for { font-size: 1.5rem; }
  .pricing-cta .btn { display: inline-block; } }
@media (min-width: 992px) { .pricing-boxed { max-width: 100%; }
  .pricing-cta .btn { display: block; }
  .pricing-boxed-sm-bg { background-image: none; } }
@media (min-width: 1600px) { .pricing-price h3 { font-size: 4.5rem; } }
.faq-item { padding: 30px; box-shadow: 0px 0px 12px 0px rgba(10, 16, 21, 0.03); }
.faq-item-s1 { padding: 0; margin-bottom: 10px; }
.faq-title { padding: 20px 30px; border-bottom: 1px solid #230985; margin-bottom: 0; position: relative; cursor: pointer; }
.accordion-bdr .faq-title { border-bottom: 1px solid #121b22; }
.faq-title.collapsed { border-bottom: 1px solid transparent; }
.faq-content { padding: 20px 30px; }
.faq-icon { position: absolute; right: 30px; height: 100%; top: 0; display: none; }
.faq-icon:after { line-height: 63px; color: #ffffff; font-weight: 700; font-size: 1rem; text-align: center; transition: all .4s; font-family: "themify"; content: '\e64b'; transform: rotate(-180deg); }

@media (min-width: 576px) { .faq-icon { display: block; } }
/**  05.14 prebuild **/
.pre-sec { padding-left: 48px; padding-right: 48px; background: transparent; position: relative; background: #f1f4f9 !important; }
.pre-sec-head { margin-bottom: 30px; }

.pre-bodoy { background: #f1f4f9 !important; }

.pre-sec-bg { background: #f1f4f9 !important; }

.prebuild-pages .banner { background-image: linear-gradient(to top, #f1f4f9 0%, rgba(241, 244, 249, 0) 100%); }

.btn-group { display: inline-flex; flex-wrap: wrap; align-items: center; }

/**  05.14 prebuild **/
.title-intro { font-size: 29px; font-weight: 400; line-height: 40px; }
.title-intro-sm { font-size: 16px; font-weight: 900; letter-spacing: 1px; color: #ff4157; margin-bottom: 30px; }

.section-intro { padding-bottom: 70px; }

@media (min-width: 992px) { .title-intro { font-size: 40px; line-height: 50px; } }
@media (min-width: 1200px) { .title-intro { font-size: 48px; line-height: 60px; } }
@media (min-width: 1200px) and (max-width: 1360px) { .title-intro { font-size: 40px; line-height: 55px; } }
/**  05.14 prebuild **/
.image-block { position: relative; width: 100%; }
.image-block-creative { padding: 0 100px 67px 24px; position: relative; margin-top: 110px; }
.image-block img { width: 60%; }
.image-block-s2, .image-block-s3 { position: relative; width: 80%; margin-left: auto; margin-right: auto; max-width: 300px; }
.image-block-s2.w-100, .image-block-s3.w-100 { max-width: 100%; }
.image-block-s2 img, .image-block-s3 img { width: 100%; }
.image-block-s4 { height: 100%; }
.image-block-s4 img { max-height: 100%; min-height: 100%; object-fit: cover; }
.image-ball { position: absolute; top: -80px; left: -15px; }
.image-block .image-ball { width: 100px; height: 100px; }
.image-main { margin-top: -110px; }

.quote-block { padding: 30px; }
.quote-icon { color: #ff4157; margin-bottom: 25px; }
.quote-icon i { font-size: 70px; }

.breadcumb-menu { position: relative; display: inline-flex; align-items: center; margin-bottom: 55px; padding-top: 70px; }
.breadcumb-menu:before { content: ""; width: 26px; height: 2px; background: #ff4157; }
.breadcumb-menu li { display: inline-block; font-weight: 700; font-size: 17px; color: #666; position: relative; text-transform: uppercase; font-family: 'rubik', sans-serif; }
.breadcumb-menu li:last-child { padding: 10px 15px; }
.breadcumb-menu li:not(:last-child):after { content: "/"; position: absolute; left: calc(100% - 3px); top: 50%; transform: translateY(-50%); }
.breadcumb-menu li a { color: #666; padding: 10px 15px; display: block; transition: all 0.3s; }
.breadcumb-menu li:hover a { color: #ff4157; }

@media (min-width: 576px) { .quote-block { padding: 30px; }
  .image-block img { width: 50%; }
  .image-block-s2 img, .image-block-s3 img { width: 100%; }
  .banner-helena .image-block-s2, .banner-helena .image-block-s3 { max-width: 100%; width: 100%; } }
@media (min-width: 768px) { .ml-reverse { margin-left: -120px; } }
@media (min-width: 992px) { .image-ball { position: absolute; top: -75px; left: -33px; }
  .image-block .image-ball { width: 120px; height: 120px; }
  .image-block img { width: 100%; }
  .image-block-s2, .image-block-s3 { width: 100%; }
  .banner-inner-lg .image-block-s2, .banner-inner-lg .image-block-s3 { margin-left: auto; margin-right: 0; max-width: 480px; }
  .banner-inner-sm .image-block-s2, .banner-inner-sm .image-block-s3 { margin-left: auto; margin-right: 0; max-width: 360px; }
  .banner-helena .image-block-s2, .banner-helena .image-block-s3 { margin-left: auto; margin-right: 0; }
  .breadcumb-menu { padding-top: 0; } }
@media (min-width: 1200px) { .image-ball { position: absolute; top: -80px; left: -75px; }
  .image-block .image-ball { width: 190px; height: 190px; }
  .image-block-s2, .image-block-s3 { max-width: 100%; margin-left: 0; }
  .image-block-s2.w-100, .image-block-s3.w-100 { max-width: 100%; }
  .quote-block { padding: 40px 95px 60px 60px; }
  .ml-reverse { margin-left: -200px; } }
@media (min-width: 320px) and (max-width: 400px) { .image-block img { width: 100%; } }
/**  05.14 prebuild **/
.error-text-large { font-size: 150px; line-height: 1; font-weight: 700; margin-bottom: 17px; color: #ff7484; }
.error-text-mid { font-size: 150px; font-weight: 700; color: #ff7484; line-height: normal; }
.error-content h5 { font-size: 1.33rem; color: #000000; font-weight: 600; margin-bottom: 18px; }
.error-content p { margin-bottom: 28px; color: #4a4a4a; }

.page-extra-pd { padding-top: 100px !important; }

@media (min-width: 768px) { .error-text-large { font-size: 180px; }
  .error-content { padding-bottom: 60px; }
  .page-extra-pd { padding-top: 150px !important; } }
@media (min-width: 992px) { .error-text-large { font-size: 250px; }
  .error-content { padding-bottom: 0px; }
  .page-extra-pd { padding-top: 150px !important; } }
@media (min-width: 1200px) { .page-extra-pd { padding-top: 220px !important; } }
@media (max-width: 991px) { .sign-up .bg-image  { display: none; }
  .sign-up-alt .bg-image  { display: block; } }
@media (min-width: 768px) { .align-middle-md { height: 100vh; display: flex; align-items: center; } }
/**  05.14 prebuild **/
.ath-body { padding-right: 0px; width: 100%; }
.ath-body.bg-secondary { padding: 50px; background: #233749 !important; }
.ath-body.bg-secondary-opacity { background: rgba(18, 27, 34, 0.8); padding: 50px; }
.ath-body.bg-light { padding: 50px; }
.ath-note { padding-top: 30px; }
.ath-note strong { color: #ff4157; }
.ath-heading { font-weight: 300; font-size: 2.25rem; text-transform: capitalize; }
.ath-heading small { display: block; padding-bottom: 10px; font-size: 50%; margin-top: .75rem; }

.sign-up .input-checkbox { position: absolute; height: 1px; width: 1px; opacity: 0; }
.sign-up .input-checkbox + label { margin-bottom: 30px; padding-left: 28px; position: relative; cursor: pointer; font-size: 14px; }
.sign-up .input-checkbox + label a { color: #ff4157; }
.sign-up .input-checkbox + label:before { position: absolute; left: 0; top: 0; height: 20px; width: 20px; line-height: 20px; text-align: center; content: ''; background-color: transparent; border: 2px solid #ff4157; }
.sign-up .input-checkbox + label:after { position: absolute; left: 0; top: 0; height: 20px; width: 20px; line-height: 20px; text-align: center; font-family: "Font Awesome 5 Free"; content: '\f00c'; font-weight: 700; font-size: 10px; background-color: #ff4157; color: #fff; opacity: 0; transition: all .4s; }

.form-field { position: relative; }

.input-checkbox:checked ~ label:not(.error):after { opacity: 1; }

.sign-up .input { margin-bottom: 30px; }

.ath-body-pdy { padding: 120px 0 60px; }

@media (min-width: 576px) { .ath-body-pdy { padding: 60px 0 60px; } }
@media (min-width: 992px) { .middle-align { display: flex; align-items: center; }
  .ath-body { padding-right: 60px; }
  .ath-body-pdy { padding: 0; } }
@media (max-width: 991px) { .sign-up .banner-block { min-height: auto; padding: 120px 0 70px; } }
/* END */
