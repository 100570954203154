/*------------------------------------------------------------------
Project:  ComingSoon
Version:
Last change:
Assigned to:  Bach Le
Primary use:
-------------------------------------------------------------------*/



/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
  font-family: Poppins-Regular;
  src: url('../fonts/Poppins/Poppins-Regular.ttf');
}

@font-face {
  font-family: Poppins-Bold;
  src: url('../fonts/Poppins/Poppins-Bold.ttf');
}

@font-face {
  font-family: Poppins-Thin;
  src: url('../fonts/Poppins/Poppins-Thin.ttf');
}

@font-face {
  font-family: Poppins-Black;
  src: url('../fonts/Poppins/Poppins-Black.ttf');
}


/*//////////////////////////////////////////////////////////////////
[ RS PLUGIN ]*/
/*---------------------------------------------*/
.container {max-width: 1200px;}


/*//////////////////////////////////////////////////////////////////
[ Simple slide100 ]*/
.simpleslide100 {
  display: block;
  position: fixed;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.simpleslide100-item {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}



/*==================================================================
    TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT
==================================================================*/

/*==================================================================
[ Color ]*/
.cl0 {color: #fff;}




/*//////////////////////////////////////////////////////////////////
[ S-Text 0 - 15 ]*/
.s1-txt1 {
  font-family: Poppins-Regular;
  font-size: 12px;
  color: #fff;
  line-height: 1;
  text-transform: uppercase;
}



/*//////////////////////////////////////////////////////////////////
[ M-Text 16 - 25 ]*/
.m1-txt1 {
  font-family: Poppins-Regular;
  font-size: 18px;
  color: #555;
  line-height: 1.2;
  text-transform: uppercase;
}



/*//////////////////////////////////////////////////////////////////
[ L-Text >= 26 ]*/
.l1-txt1 {
  font-family: Poppins-Thin;
  font-size: 40px;
  line-height: 1.1;
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
  letter-spacing: 15px;
}

.l1-txt2 {
  font-family: Poppins-Thin;
  font-size: 20px;
  line-height: 1;
  color: #fff;
  text-transform: uppercase;
}

.bottom-text {
  color: white
}

/*==================================================================
   SHAPE SHAPE SHAPE SHAPE SHAPE SHAPE SHAPE SHAPE SHAPE SHAPE SHAPE
==================================================================*/
/*//////////////////////////////////////////////////////////////////
[ Size ]*/
.size1 {
  width: 100%;
  min-height: 100vh;
}

.size2 {
  min-width: 140px;
  height: 50px;
}

.size3 {
  width: 36px;
  height: 36px;
}


/*//////////////////////////////////////////////////////////////////
[ Width ]*/
.wsize1 {
  width: 195px;
}

/*//////////////////////////////////////////////////////////////////
[ Height ]*/




/*//////////////////////////////////////////////////////////////////
[ Background ]*/
.bg0 {background-color: #fff;}

.bg-img1 {
  background-image: url('../../assets/images/landing-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}




/*//////////////////////////////////////////////////////////////////
[ Border ]*/





/*==================================================================
   WHERE WHERE WHERE WHERE WHERE WHERE WHERE WHERE WHERE WHERE WHERE
==================================================================*/
.where1 {
  align-self: flex-start;
}



/*==================================================================
 HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW
==================================================================*/
.placeholder0::-webkit-input-placeholder { color: #999999;}
.placeholder0:-moz-placeholder { color: #999999;}
.placeholder0::-moz-placeholder { color: #999999;}
.placeholder0:-ms-input-placeholder { color: #999999;}


/*---------------------------------------------*/
.overlay1 {
  position: relative;
}
.overlay1::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.overlay1::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.4);
}

/*---------------------------------------------*/
.wrappic1 {
  display: block;
  flex-grow: 1;
}

.wrappic1 img {
  max-width: 100%;
}

/*---------------------------------------------*/
.how-btn1 {
  padding: 0 15px;
  background-color: #fff;
  border-radius: 25px;
}

.how-btn1:hover {
  background-color: #555555;
  color: #fff;
}

/*---------------------------------------------*/
.how-social {
  color: #fff;
  font-size: 18px;
  border: 1px solid #fff;
  background-color: transparent;
  border-radius: 50%;
}

.how-social:hover {
  background-color: #C20C52;
  color: #fff;
}

.social-a {
  outline: none;
  text-decoration: none;
  color: #fff;
}
.social-a:active {
  color: #fff;
}


/*//////////////////////////////////////////////////////////////////
[ Pseudo ]*/

/*------------------------------------------------------------------
[ Focus ]*/
.focus-in0:focus::-webkit-input-placeholder { color:transparent; }
.focus-in0:focus:-moz-placeholder { color:transparent; }
.focus-in0:focus::-moz-placeholder { color:transparent; }
.focus-in0:focus:-ms-input-placeholder { color:transparent; }


/*------------------------------------------------------------------
[ Hover ]*/
.hov-cl0:hover {color: #fff;}
.hov-bg0:hover {background-color: #fff;}

/*---------------------------------------------*/







/*==================================================================
  RESPONSIVE RESPONSIVE RESPONSIVE RESPONSIVE RESPONSIVE RESPONSIVE
==================================================================*/


/*//////////////////////////////////////////////////////////////////
[ XL ]*/
@media (max-width: 1200px) {
  .m-0-xl {margin: 0;}
  .m-lr-0-xl {margin-left: 0; margin-right: 0;}
  .m-lr-15-xl {margin-left: 15px; margin-right: 15px;}
  .m-l-0-xl {margin-left: 0;}
  .m-r-0-xl {margin-right: 0;}
  .m-l-15-xl {margin-left: 15px;}
  .m-r-15-xl {margin-right: 15px;}

  .p-0-xl {padding: 0;}
  .p-lr-0-xl {padding-left: 0; padding-right: 0;}
  .p-lr-15-xl {padding-left: 15px; padding-right: 15px;}
  .p-l-0-xl {padding-left: 0;}
  .p-r-0-xl {padding-right: 0;}
  .p-l-15-xl {padding-left: 15px;}
  .p-r-15-xl {padding-right: 15px;}

  .w-full-xl {width: 100%;}

  /*---------------------------------------------*/

}


/*//////////////////////////////////////////////////////////////////
[ LG ]*/
@media (max-width: 992px) {
  .m-0-lg {margin: 0;}
  .m-lr-0-lg {margin-left: 0; margin-right: 0;}
  .m-lr-15-lg {margin-left: 15px; margin-right: 15px;}
  .m-l-0-lg {margin-left: 0;}
  .m-r-0-lg {margin-right: 0;}
  .m-l-15-lg {margin-left: 15px;}
  .m-r-15-lg {margin-right: 15px;}

  .p-0-lg {padding: 0;}
  .p-lr-0-lg {padding-left: 0; padding-right: 0;}
  .p-lr-15-lg {padding-left: 15px; padding-right: 15px;}
  .p-l-0-lg {padding-left: 0;}
  .p-r-0-lg{padding-right: 0;}
  .p-l-15-lg {padding-left: 15px;}
  .p-r-15-lg {padding-right: 15px;}

  .w-full-lg {width: 100%;}

  /*---------------------------------------------*/


}


/*//////////////////////////////////////////////////////////////////
[ MD ]*/
@media (max-width: 768px) {
  .m-0-md {margin: 0;}
  .m-lr-0-md {margin-left: 0; margin-right: 0;}
  .m-lr-15-md {margin-left: 15px; margin-right: 15px;}
  .m-l-0-md {margin-left: 0;}
  .m-r-0-md {margin-right: 0;}
  .m-l-15-md {margin-left: 15px;}
  .m-r-15-md {margin-right: 15px;}

  .p-0-md {padding: 0;}
  .p-lr-0-md {padding-left: 0; padding-right: 0;}
  .p-lr-15-md {padding-left: 15px; padding-right: 15px;}
  .p-l-0-md {padding-left: 0;}
  .p-r-0-md{padding-right: 0;}
  .p-l-15-md {padding-left: 15px;}
  .p-r-15-md {padding-right: 15px;}

  .w-full-md {width: 100%;}
  /*---------------------------------------------*/

}


/*//////////////////////////////////////////////////////////////////
[ SM ]*/
@media (max-width: 576px) {
  .m-0-sm {margin: 0;}
  .m-lr-0-sm {margin-left: 0; margin-right: 0;}
  .m-lr-15-sm {margin-left: 15px; margin-right: 15px;}
  .m-l-0-sm {margin-left: 0;}
  .m-r-0-sm {margin-right: 0;}
  .m-l-15-sm {margin-left: 15px;}
  .m-r-15-sm {margin-right: 15px;}

  .p-0-sm {padding: 0;}
  .p-lr-0-sm {padding-left: 0; padding-right: 0;}
  .p-lr-15-sm {padding-left: 15px; padding-right: 15px;}
  .p-l-0-sm {padding-left: 0;}
  .p-r-0-sm{padding-right: 0;}
  .p-l-15-sm {padding-left: 15px;}
  .p-r-15-sm {padding-right: 15px;}

  .w-full-sm {width: 100%;}

  /*---------------------------------------------*/
  .respon1 {
    font-size: 20px;
  }

}


/*//////////////////////////////////////////////////////////////////
[ SSM ]*/
@media (max-width: 480px) {
  .m-0-ssm {margin: 0;}
  .m-lr-0-ssm {margin-left: 0; margin-right: 0;}
  .m-lr-15-ssm {margin-left: 15px; margin-right: 15px;}
  .m-l-0-ssm {margin-left: 0;}
  .m-r-0-ssm {margin-right: 0;}
  .m-l-15-ssm {margin-left: 15px;}
  .m-r-15-ssm {margin-right: 15px;}

  .p-0-ssm {padding: 0;}
  .p-lr-0-ssm {padding-left: 0; padding-right: 0;}
  .p-lr-15-ssm {padding-left: 15px; padding-right: 15px;}
  .p-l-0-ssm {padding-left: 0;}
  .p-r-0-ssm{padding-right: 0;}
  .p-l-15-ssm {padding-left: 15px;}
  .p-r-15-ssm {padding-right: 15px;}

  .w-full-ssm {width: 100%;}
  /*---------------------------------------------*/

}
